import styled from "styled-components";
import { IStyleableProps } from "../commonTypes";
import Icon from "./Icon/Icon";

const Spinner = styled(Icon)`
  margin: 0 auto;
  animation: load3 1.4s infinite linear;
  max-width: 30px;

  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

interface IProps {
  size?: string;
}

const LoadingSpinner = (props: IProps & IStyleableProps) => {
  return <Spinner name="spinner" {...props} />;
};

export default LoadingSpinner;
