import { useTheme } from "styled-components";
import Modal from "../Modal";
import About from "./About";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const AboutModal = ({ isOpen, onClose }: Props) => {
  const theme = useTheme();
  return (
    <Modal
      maxWidth={"1000px"}
      padding={"48px"}
      backgroundColor={theme.secondaryColor}
      isOpen={isOpen}
      onClose={onClose}
    >
      <About />
    </Modal>
  );
};

export default AboutModal;
