import {
  localStorageHelper,
  LocalStorageKeys,
} from "../helpers/localStorageHelper";

export const walletService = {
  getLocalWallets: () => {
    return localStorageHelper.get(LocalStorageKeys.wallets) || [];
  },
  setLocalWallets: (wallets: string[]) => {
    return localStorageHelper.set(LocalStorageKeys.wallets, wallets);
  },
};
