import { useWeb3 } from "@chainsafe/web3-context";
import styled from "styled-components";
import { getParagraph16BoldTypography } from "../../Typography";
import Avatar from "../Avatar";
import AccountMenu from "../Menus/Account";
import { useAccountDropdown } from "../../../context/AccountDropdownContext";
import WalletMenu from "../Menus/Wallet/WalletMenu";
import DepositStakeMenu from "../Menus/Wallet/DepositStakeMenu";
import WithdrawStakeMenu from "../Menus/Wallet/WithdrawStakeMenu";
import { getHorizontalGap } from "../../styles";
import { useSession } from "../../../queries/authentication.query";
import { truncateAmount } from "../../../helpers/format";
import { useTokenQuery } from "../../../queries/token.query";
import useChain from "../../hooks/useChain";

const OnlineIcon = styled.div`
  background-color: rgba(81, 220, 142, 1);
  width: 12px;
  height: 12px;
  border-radius: 12px;
`;

const StyledAvatar = styled(Avatar)``;

const Profile = styled.div`
  display: flex;
  border: 2px solid ${({ theme }) => theme.borderColor};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  cursor: pointer;
  ${getHorizontalGap("5px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    border: none;
    position: relative;

    & > * {
      display: none;
    }

    ${StyledAvatar} {
      display: inline-block;
    }

    ${OnlineIcon} {
      display: block;
      position: absolute;
      top: -6px;
      right: 1px;
      border: 2px solid ${(props) => props.theme.backgroundColor};
    }
  }
`;

const Value = styled.p`
  ${getParagraph16BoldTypography};
  color: ${({ theme }) => theme.primaryColor};
`;

const Label = styled.p`
  ${getParagraph16BoldTypography};
  color: ${({ theme }) => theme.primaryColor};
  opacity: 0.7;
  white-space: nowrap;
`;

const AccountDropdown = () => {
  const { toggleDropdown } = useAccountDropdown();
  const { ethBalance, address } = useWeb3();
  const { chain } = useChain();
  const { data } = useTokenQuery(chain);

  const { user, isLoggedIn } = useSession();

  return (
    <>
      {isLoggedIn && (
        <Profile onClick={toggleDropdown}>
          <StyledAvatar user={user} size={"34px"} />
          {address && (
            <>
              <Value>{truncateAmount(ethBalance)}</Value>
              <Label> {data?.nativeToken?.symbol}</Label>
              <OnlineIcon className="online" />
            </>
          )}
        </Profile>
      )}
      <AccountMenu />
      <WalletMenu />
      <DepositStakeMenu />
      <WithdrawStakeMenu />
    </>
  );
};

export default AccountDropdown;
