import { IBid, IListing } from "../common/commonTypes";

export const getMaxBid = (bids: IBid[] | undefined): IBid | undefined => {
  return bids?.reduce(
    (bidA, bidB) => (bidA.amount > bidB.amount ? bidA : bidB),
    bids[0]
  );
};

export const listingToBid = (listing: IListing): IBid => {
  const bid = {
    amount: listing.amount,
    listing_id: listing.id,
    user: listing.buyer!,
    id: "-1",
    created: "",
    listing,
  };

  return bid;
};

export const getListingBid = (
  listing: IListing | undefined
): IBid | undefined => {
  if (!listing || !listing.buyer) {
    return undefined;
  }

  const bid = {
    amount: listing.amount,
    listing_id: listing.id,
    user: listing.buyer!,
    id: "-1",
    created: "",
    listing,
  };

  return bid;
};
