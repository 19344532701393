import { DefaultTheme } from "styled-components";
import defaultBackgroundImage from "../assets/background-default.svg";
import lightBackgroundImage from "../assets/background-light.svg";
import { ITypographySize } from "../common/commonTypes";

declare module "styled-components" {
  export interface DefaultTheme {
    primaryColor: string;
    secondaryColor: string;

    accent?: any;
    backgroundColor: string;
    darkBackgroundColor?: string;
    lightBackgroundColor?: string;
    lightBackgroundColorHover?: string;
    semiTransparent?: string;
    inputBackgroundColor: string;
    inputBorderColor: string;

    navbarBackgroundColor?: string;
    timerBackgroundColor?: string;
    cardBackgroundTransparent?: string;
    modalOverlayColor?: string;

    borderColor: string;
    lightBorderColor: string;
    darkBorderColor: string;
    badgeBorderColor: string;
    errorColor: string;
    red: string;
    greenColor: string;
    blueColor: string;
    greyColor: string;
    blackColor: string;
    remixColor: string;
    remixPackageColor: string;
    remixWhyChooseColor: string;

    contentWidth: string;
    breakpoints: any;

    heading: ITypographySize;
    paragraph: ITypographySize;

    backgroundImage?: any;

    borderRadius: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
    };

    filterBlur?: string;
  }
}

export const defaultTheme: DefaultTheme = {
  primaryColor: "#ffffff",
  secondaryColor: "#121212",

  accent: {
    dark: "#6350fa",
    normal: "#6F5EFF",
    light: "#9386ff",
  },
  backgroundColor: "#1e1e1e",
  lightBackgroundColor: "#2d2d2d",
  lightBackgroundColorHover: "#232323",
  darkBackgroundColor: "#000000",
  semiTransparent: "rgba(0, 0, 0, 0.1)",
  inputBackgroundColor: "rgba(255, 255, 255, 0.05)",
  inputBorderColor: "rgba(255, 255, 255, 0.3)",

  navbarBackgroundColor: "rgba(18, 18, 18, 0.7)",
  timerBackgroundColor: "rgba(18, 18, 18, 0.4)",
  cardBackgroundTransparent: "rgba(18, 18, 18, 0.6)",
  modalOverlayColor: "rgba(18, 18, 18, 0.7)",

  borderColor: "rgba(221, 221, 221, 0.1)",
  lightBorderColor: "rgba(255, 255, 255, 0.5)",
  darkBorderColor: "rgba(255, 255, 255, 0.1)",
  badgeBorderColor: "#B6B6B6",
  errorColor: "#FF3E6C",
  red: "#FF5064",
  blueColor: "#2a92e5",
  greyColor: "#a1a1a1",
  greenColor: "#51DC8E",
  blackColor: "#000000",
  remixColor: "#b4b4b4",
  remixPackageColor: "#1F1F1F",
  remixWhyChooseColor: "#FCFCFD",

  contentWidth: "1200px",
  backgroundImage: defaultBackgroundImage,

  heading: {
    xxl: "58px",
    xl: "48px",
    lg: "38px",
    md: "28px",
    sm: "24px",
    xs: "19px",
  },

  paragraph: {
    xxl: "22px",
    xl: "18px",
    lg: "16px",
    md: "14px",
    sm: "12px",
    xs: "10px",
  },

  borderRadius: {
    xs: "3px",
    sm: "6px",
    md: "10px",
    lg: "20px",
  },

  breakpoints: {
    mobileXS: "320px",
    mobileS: "326px",
    mobileM: "375px",
    mobileL: "425px",
    tablet: "768px",
    laptop: "1024px",
    laptopL: "1280px",
    laptopXL: "1440px",
    desktop: "1920px",
  },

  filterBlur: "blur(30px)",
};

export const lightTheme: DefaultTheme = {
  primaryColor: "#ffffff",
  secondaryColor: "#121212",
  backgroundColor: "#121212",
  backgroundImage: lightBackgroundImage,
  navbarBackgroundColor: "rgba(255, 255, 255, 0.5)",

  blueColor: "#2a92e5",
  greyColor: "#a1a1a1",
  contentWidth: "1200px",
  inputBackgroundColor: "rgba(255, 255, 255, 0.05)",
  inputBorderColor: "rgba(255, 255, 255, 0.3)",

  heading: {
    xxl: "57px",
    xl: "48px",
    lg: "40px",
    md: "28px",
    sm: "24px",
    xs: "19px",
  },

  paragraph: {
    xxl: "22px",
    xl: "18px",
    lg: "16px",
    md: "14px",
    sm: "12px",
    xs: "10px",
  },

  breakpoints: {
    mobileXS: "320px",
    mobileS: "320px",
    mobileM: "375px",
    tablet: "960px",
    laptop: "1024px",
    laptopL: "1280px",
    desktop: "1920px",
  },

  borderRadius: {
    xs: "3px",
    sm: "6px",
    md: "10px",
    lg: "20px",
  },

  filterBlur: "blur(30px)",
  greenColor: "#51DC8E",
  borderColor: "rgba(221, 221, 221, 0.1)",
  lightBorderColor: "rgba(255, 255, 255, 0.5)",
  darkBorderColor: "rgba(255, 255, 255, 0.1)",
  errorColor: "#FF3E6C",
  badgeBorderColor: "#B6B6B6",
  remixColor: "#b4b4b4",
  remixPackageColor: "#1F1F1F",
  remixWhyChooseColor: "#FCFCFD",
  red: "#FF5064",
  blackColor: "#000000",
};

export const device = {
  mobileS: `(min-width: ${defaultTheme.breakpoints.mobileS})`,
  mobileM: `(min-width: ${defaultTheme.breakpoints.mobileM})`,
  mobileL: `(min-width: ${defaultTheme.breakpoints.mobileL})`,
  tablet: `(min-width: ${defaultTheme.breakpoints.tablet})`,
  laptop: `(min-width: ${defaultTheme.breakpoints.laptop})`,
  laptopL: `(min-width: ${defaultTheme.breakpoints.laptopL})`,
  desktop: `(min-width: ${defaultTheme.breakpoints.desktop})`,
  desktopL: `(min-width: ${defaultTheme.breakpoints.desktop})`,
};
