import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import Typography from "../../Typography/Typography";
import { EthAddress } from "../../AppNavbar/Address";
import Button from "../../Buttons/Button";
import { useReceiveWalletSelect } from "../../../hooks/useReceiveWalletSelect";
import Warning from "../../Warning/Warning";
import { useTranslation } from "react-i18next";
import { useSession } from "../../../../queries/authentication.query";

const Root = styled.div`
  border-top: 1px solid ${(props) => props.theme.darkBorderColor};
  margin-top: 16px;
  padding-top: 16px;
`;

const WalletRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChangeWarning = styled.div`
  width: 100%;
  margin-top: 12px;
  background-color: ${({ theme }) => theme.lightBackgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: 8px;
`;

const ChangeWarningText = styled(Typography)`
  white-space: pre-wrap;
`;

const ErrorMessage = styled.div`
  margin-top: 12px;
`;

const ReceiveWallet = () => {
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { user: loggedUser } = useSession();
  const savedUserAddress = loggedUser?.address;
  const { selectWallet, isLoading, error } = useReceiveWalletSelect();

  const onChangePress = () => {
    if (!isWarningVisible) {
      setIsWarningVisible(true);
      return;
    }

    setIsWarningVisible(false);
    selectWallet();
  };

  if (!savedUserAddress) {
    return null;
  }

  return (
    <Root>
      <Typography secondary>Your receive wallet address</Typography>
      <WalletRow>
        <EthAddress address={savedUserAddress} visibleChars={10} />
        <Button
          text={t(isWarningVisible ? "confirm" : "receive-wallet-change")}
          onClick={onChangePress}
          height="34px"
          background={theme.darkBorderColor}
          isLoading={isLoading}
        />
      </WalletRow>
      {isWarningVisible && (
        <ChangeWarning>
          <ChangeWarningText
            align="center"
            secondary
            size="sm"
            id="receive-wallet-change-warning"
          />
        </ChangeWarning>
      )}
      {error && (
        <ErrorMessage>
          <Warning message={t("receive-wallet-update-error")} />
        </ErrorMessage>
      )}
    </Root>
  );
};

export default ReceiveWallet;
