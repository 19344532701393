import Button from "./Button";
import styled, { css } from "styled-components";

const ButtonOutlined = styled(Button)`
  & {
    border: 2px solid ${({ theme }) => theme.borderColor};
    background-color: ${({ theme }) => theme.semiTransparent};

    &:hover:not(:disabled) {
      background: ${({ theme }) => theme.lightBackgroundColor};
    }

    &:disabled {
      ${({ $isLoading, theme }) =>
        !$isLoading &&
        css`
          background-color: ${theme.semiTransparent};
          opacity: 0.3;
        `};
    }
  }
`;

export default ButtonOutlined;
