import moment, { Duration } from "moment";

export const formatDate = (isoDate: string, format: string = "D MMMM YYYY") =>
  moment(isoDate).format(format);

export const formatTime = (isoDate: string, format: string = "HH:mm") =>
  moment(isoDate).format(format);

export const getPriorDate = (days: number) => {
  // round to the end of the day
  return moment().subtract(days, "days").endOf("day").unix();
};

export interface IDurationLabels {
  day: string;
  hour: string;
  minute: string;
  second: string;
}

export const formatDuration = (
  duration: Duration,
  labels: IDurationLabels = {
    day: "d ",
    hour: "h ",
    minute: "m ",
    second: "s",
  }
): string => {
  const defaultResult = `0${labels.hour}0${labels.minute}0${labels.second}`;

  if (duration.asSeconds() <= 0) {
    return defaultResult;
  }

  let diffInSeconds = duration.asSeconds();
  let hours = diffInSeconds / 3600;
  diffInSeconds = diffInSeconds % 3600;
  let minutes = diffInSeconds / 60;
  diffInSeconds = diffInSeconds % 60;
  let seconds = diffInSeconds;

  if (hours > 24) {
    let days = hours / 24;
    hours = hours % 24;
    return (
      `${Math.floor(days).toString().padStart(2, "0")}${labels.day}` +
      `${Math.floor(hours).toString().padStart(2, "0")}${labels.hour}` +
      `${Math.floor(minutes).toString().padStart(2, "0")}${labels.minute}` +
      `${Math.floor(seconds).toString().padStart(2, "0")}${labels.second}`
    );
  }

  return (
    `${Math.floor(hours || 0)
      .toString()
      .padStart(2, "0")}${labels.hour}` +
    `${Math.floor(minutes || 0)
      .toString()
      .padStart(2, "0")}${labels.minute}` +
    `${Math.floor(seconds || 0)
      .toString()
      .padStart(2, "0")}${labels.second}`
  );
};

export const formatTrackDuration = (durationInSeconds?: number) => {
  if (!durationInSeconds || durationInSeconds === 0) {
    return "0:00";
  }

  return moment()
    .startOf("day")
    .add(durationInSeconds?.toFixed(2), "seconds")
    .format("m:ss");
};

export function getLocaleDateAndMonth(date: Date): string {
  return date
    .toLocaleString("default", {
      day: "numeric",
      month: "short",
    })
    .toLocaleUpperCase();
}

export function getLocaleTime(date: Date): string {
  return date.toLocaleString("default", {
    hour: "2-digit",
    minute: "2-digit",
  });
}
