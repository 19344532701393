import { safe } from "./safeWebApis";

export enum LocalStorageKeys {
  sessionId = "sessionId",
  wallets = "wallets",
  selectedWallet = "onboard.selectedWallet",
  modalWarningAccepted = "modalWarningAccepted",
  transactions = "transactions",
  playlist = "playlist",
}

export const localStorageHelper = {
  get: <ReturnType = any>(key: LocalStorageKeys): ReturnType | undefined => {
    let item = safe.localStorage?.getItem(key);
    if (item) {
      return JSON.parse(item);
    }

    return undefined;
  },

  set: <ValueType = unknown>(key: LocalStorageKeys, value: ValueType): void => {
    localStorage.setItem(key, JSON.stringify(value));
  },

  remove: (key: LocalStorageKeys): void => {
    localStorage.removeItem(key);
  },
};
