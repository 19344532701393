import styled, { css } from "styled-components";
import {
  getBasePoppinsTypography,
  getBaseTypography,
  getBoldTypography,
} from "./helpers";

export const getHeading2BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.xl};
  line-height: 56.25px;
`;

export const getHeading2PoppinsBoldTypography = css`
  ${getBasePoppinsTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.xl};
  line-height: 56.25px;
`;

export const Heading2Bold = styled.h2`
  ${getHeading2BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Heading2PoppinsBold = styled.h2`
  ${getHeading2PoppinsBoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;
