import { useRouter } from "next/router";
import { UrlObject } from "url";
import { routes } from "../../routes";

export const useLoginRoute = (): UrlObject => {
  const router = useRouter();

  return {
    pathname: routes.auth.login,
    query: {
      from: router.asPath,
    },
  };
};
