import { useInfiniteQuery } from "react-query";
import { QueryKeys } from "../common/queryKeys";
import { usersService } from "../api/usersService";
import { IApiPage, IListing, IUserProfile } from "../common/commonTypes";
import { nextPageParamGetter } from "../helpers/paginationHelper";
import { listingService } from "../api/listingService";

const LISTINGS_SEARCH_PAGE_SIZE = 6;
const ARTISTS_SEARCH_PAGE_SIZE = 18;

export const useSearchListingsQuery = (searchPhrase: string) => {
  return useInfiniteQuery<IApiPage<IListing>>(
    [QueryKeys.searchListings, searchPhrase],
    async ({ pageParam }) => {
      const response = await listingService.searchListings(searchPhrase, {
        offset: pageParam || 0,
        limit: LISTINGS_SEARCH_PAGE_SIZE,
      });
      return response.data;
    },
    {
      getNextPageParam: nextPageParamGetter(LISTINGS_SEARCH_PAGE_SIZE),
      enabled: !!searchPhrase,
      keepPreviousData: searchPhrase === "",
    }
  );
};

export const useSearchArtistsQuery = (searchPhrase: string) => {
  return useInfiniteQuery<IApiPage<IUserProfile>>(
    [QueryKeys.searchArtists, searchPhrase],
    async ({ pageParam }) => {
      const response = await usersService.searchArtists(searchPhrase, {
        offset: pageParam || 0,
        limit: ARTISTS_SEARCH_PAGE_SIZE,
      });
      return response.data;
    },
    {
      getNextPageParam: nextPageParamGetter(ARTISTS_SEARCH_PAGE_SIZE),
      enabled: !!searchPhrase,
      keepPreviousData: searchPhrase === "",
    }
  );
};
