import { useEffect, useState } from "react";
import { useDeploymentsForChain } from "../../../../context/Contracts";
import { IDeployment } from "../../../commonTypes";
import useChain from "../../../hooks/useChain";

export const useVersionSelector = () => {
  const [selectedDeployment, setSelectedDeployment] = useState<
    IDeployment | undefined
  >();
  const { chain } = useChain();
  const { deployments } = useDeploymentsForChain(chain?.id);

  const showVersionSelector = deployments && deployments?.length > 1;

  useEffect(() => {
    if (!showVersionSelector) {
      if (deployments?.length === 1) {
        setSelectedDeployment(deployments[0]);
      }
    }
  }, [deployments]);

  return {
    showVersionSelector,
    versions: deployments,
    selectedVersion: selectedDeployment,
    setSelectedVersion: setSelectedDeployment,
  };
};
