import { IApiResponse, IQueryParams } from "../common/commonTypes";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { createSequalizeUrl } from "../helpers/requestHelper";

export const assetService = {
  fetchAssets: async (params: IQueryParams) => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("asset", params)
    );
    return response;
  },
};
