import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import styled from "styled-components";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";
import { routes } from "../../../../routes";
import { IListing } from "../../../commonTypes";
import { Paragraph12RegularSecondary } from "../../../Typography/Paragraph12";
import { Paragraph14BoldSecondary } from "../../../Typography/Paragraph14";
import { Paragraph16BoldSecondary } from "../../../Typography/Paragraph16";
import Countdown from "../../Countdown";
import { truncateAmount } from "../../../../helpers/format";
import Typography from "../../Typography/Typography";
import { IMAGE_SIZES } from "../../../../constants/imageSizes";
import Cover from "../../Cover";
import { ethers } from "ethers";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.inputBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  padding: 12px;
  margin-top: 10px;
`;

const Thumbnail = styled(Cover)`
  width: 36px;
  height: 36px;
  max-height: 36px;
  margin-right: 12px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  margin-top: auto;
  margin-bottom: auto;
`;
const Rows = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
`;

const Username = styled(Typography)`
  opacity: 0.6;
  text-overflow: ellipsis;
  max-width: 150px;
  overflow: hidden;
  margin-right: 16px;

  &:before {
    content: "@";
  }
`;

const Title = styled(Paragraph16BoldSecondary)`
  margin-right: 12px;
`;

const LockedItem = ({ listing }: { listing: IListing }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { handleClose } = useAccountDropdown();

  const handleClick = () => {
    handleClose();
    router.push(routes.marketplace.id(listing.music_id, listing.id));
  };

  return (
    <Wrapper onClick={handleClick}>
      <Thumbnail
        url={listing?.music?.cover?.url}
        mime={listing?.music?.cover.mime}
        size={IMAGE_SIZES.trackCoverArt.xs}
        forceImage
      />
      <Rows>
        <Row>
          <Title>{listing?.music?.title}</Title>
          <Paragraph14BoldSecondary style={{ opacity: 0.6 }}>
            {truncateAmount(
              ethers.utils
                .formatUnits(listing.amount, listing.asset.decimals)
                .toString()
            )}{" "}
            {listing.asset.symbol}
          </Paragraph14BoldSecondary>
        </Row>
        <Row>
          <Username>{listing?.music?.artist?.username}</Username>
          <Paragraph12RegularSecondary
            style={{ opacity: 0.4, fontWeight: 500 }}
          >
            {t("stake-ending-in")}{" "}
            <Countdown
              showWhenEnded
              endTime={listing?.ending}
              labels={{ day: ":", hour: ":", minute: ":", second: "" }}
            />
          </Paragraph12RegularSecondary>
        </Row>
      </Rows>
    </Wrapper>
  );
};

export default LockedItem;
