import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useLoginRoute } from "../../../hooks/useLoginRoute";
import { INavLink } from "../../../commonTypes";
import { getHeading5BoldTypography } from "../../../Typography";
import { routes } from "../../../../routes";
import { getVerticalGap } from "../../../styles";
import Button from "../../Buttons/Button";
import ButtonOutlined from "../../Buttons/ButtonOutlined";
import Typography from "../../Typography/Typography";
import Icon from "../../Icon/Icon";
import { useSession } from "../../../../queries/authentication.query";
import { useRouter } from "next/router";
import StaticLink from "../../StaticLink/StaticLink";
import MenuWrapper from "./MenuWrapper";

const MenuButton = styled.button`
  width: 40px;
  height: 40px;
  border: 2px solid ${(props) => props.theme.borderColor};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const NavContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: auto;
`;

const NavItem = styled(StaticLink)<{ selected: boolean }>`
  ${getHeading5BoldTypography};
  color: ${({ theme }) => theme.primaryColor};
  opacity: ${({ selected }) => (selected ? 1 : 0.6)};
  text-decoration: none;
  margin-bottom: 32px;
`;

const BottomSectionWrapper = styled.div`
  margin: 0 -20px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 32px;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 16px 32px 60px 32px;
  }
`;

const LoginButton = styled(ButtonOutlined)`
  background: ${(props) => props.theme.borderColor};
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${getVerticalGap("15px")}
`;

type Props = {
  navLinks: INavLink[];
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
};

const BurgerMenu = ({ navLinks, isOpen, setIsOpen }: Props) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { isLoggedIn } = useSession();
  const loginPageRoute = useLoginRoute();

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleMenuIconClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <>
      <MenuButton>
        <Icon
          name={!isOpen ? "burgerMenu" : "close"}
          onClick={handleMenuIconClick}
          width={isOpen ? "18px" : "24px"}
        />
      </MenuButton>
      {isOpen && (
        <MenuWrapper onClose={closeMenu}>
          <NavContainer onClick={closeMenu}>
            {navLinks.map((link) => (
              <NavItem
                key={link.path}
                href={link.path}
                selected={router.pathname === link.path}
              >
                {link.name}
              </NavItem>
            ))}
          </NavContainer>

          {!isLoggedIn && (
            <BottomSectionWrapper>
              <ButtonsWrapper>
                <Button
                  onClick={closeMenu}
                  href={routes.auth.join.root}
                  label={<Typography weight="bold">{t("join")}</Typography>}
                  height="48px"
                  fullWidth
                />
                <LoginButton
                  onClick={closeMenu}
                  href={loginPageRoute}
                  label={
                    <Typography weight="bold">
                      {t("app-navbar-login-button")}
                    </Typography>
                  }
                  height="48px"
                  fullWidth
                />
              </ButtonsWrapper>
            </BottomSectionWrapper>
          )}
          {isLoggedIn && (
            <BottomSectionWrapper>
              <Button
                onClick={closeMenu}
                href={routes.uploadMusic}
                text={t("upload-track")}
                height="48px"
              />
            </BottomSectionWrapper>
          )}
        </MenuWrapper>
      )}
    </>
  );
};

export default BurgerMenu;
