import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getFormattedAmountWithLabel } from "../../../helpers/format";
import { BidTypeEnum } from "../../commonEnums";
import { IAsset, IListingIssueDetails } from "../../commonTypes";

const AuctionDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AuctionDetail = styled.div`
  font-size: ${({ theme }) => theme.paragraph.lg};
  line-height: 19px;
  color: ${({ theme }) => theme.primaryColor};
  opacity: 0.7;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    font-size: ${({ theme }) => theme.paragraph.md};
    line-height: 16px;
  }
`;

interface IBidDetailsProps {
  bidType?: BidTypeEnum;
  price?: string;
  asset?: IAsset;
  issueDetails?: IListingIssueDetails;
}

const BidDetails = ({
  price,
  asset,
  bidType,
  issueDetails,
}: IBidDetailsProps) => {
  const { t } = useTranslation();

  return (
    <AuctionDetails>
      <AuctionDetail>
        {bidType === "bid"
          ? t("new-drops-bid-auction-label")
          : t("new-drops-nft-price-label")}{" "}
        {getFormattedAmountWithLabel(price, asset!)}
      </AuctionDetail>
      <AuctionDetail>
        {issueDetails &&
          `${issueDetails.issueNumber} of ${issueDetails.totalIssues}`}
      </AuctionDetail>
    </AuctionDetails>
  );
};

export default BidDetails;
