import React from "react";
import styled from "styled-components";
import { routes } from "../../../routes";
import Icon from "../Icon/Icon";
import StaticLink from "../StaticLink/StaticLink";

const Container = styled(StaticLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  path:not([fill="currentColor"]) {
    fill: ${({ theme }) => theme.accent.normal};
  }
`;

const Logo = () => {
  return (
    <Container href={routes.home}>
      <StyledIcon name="noizdLogo" width="100px" height="30px" />
    </Container>
  );
};

export default Logo;
