import styled, { keyframes } from "styled-components";
import {
  getHeading6BoldTypography,
  getParagraph14RegularTypography,
  getParagraph16BoldTypography,
} from "../Typography";
import { getParagraph12RegularTypography } from "../Typography/Paragraph12";
import { truncate } from "../styles";

export const fadeIn = keyframes`
0% {
    opacity: 0;
}

100% {
    opacity: 1;
}
`;

export const FormLabel = styled.label`
  ${getParagraph12RegularTypography};
  opacity: 0.7;
  text-transform: uppercase;
  line-height: 18px;
  color: ${({ theme }) => theme.primaryColor};
`;

export const FormDescription = styled.p`
  ${getParagraph12RegularTypography};
  opacity: 0.6;
  line-height: 14.06px;
  color: ${({ theme }) => theme.primaryColor};
  white-space: pre-wrap;
  word-break: break-word;
`;

export const ErrorWrapper = styled.div`
  ${getParagraph14RegularTypography};
  color: ${({ theme }) => theme.errorColor};
`;

export const AccordionParagraph = styled.p`
  ${getParagraph14RegularTypography};
  color: ${({ theme }) => theme.primaryColor};
  opacity: 0.7;
  padding-bottom: 30px;
`;

interface IIndicatorProps {
  isActive: boolean;
}

export const CaruselIndicator = styled.span<IIndicatorProps>`
  width: 25px;
  height: 4px;
  background: ${(props) =>
    props.isActive ? props.theme.primaryColor : "rgba(255, 255, 255, 0.4)"};
  border-radius: 1px;
  cursor: pointer;
`;

export const LandingContentWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  justify-content: center;
  justify-content: space-between;
  grid-template-columns: auto auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-template-rows: auto auto;
    row-gap: 49px;
  }
`;

export const LandingSectionTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.heading.md};
  line-height: 33px;
  color: ${({ theme }) => theme.primaryColor};
  text-transform: uppercase;
  padding-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    font-size: ${({ theme }) => theme.heading.sm};
    line-height: 28px;
  }
`;

export const LandingSectionDescription = styled.h6`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.paragraph.lg};
  line-height: 26px;
  color: ${({ theme }) => theme.primaryColor};
  opacity: 0.7;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    font-size: ${({ theme }) => theme.paragraph.lg};
    line-height: 24px;
  }
`;

export const ButtonCTA = styled.p<{ style?: { [prop: string]: any } }>`
  ${getParagraph16BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  margin: auto;
  ${(props) => props.style}
`;

export const MobileOnly = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
  }
`;

export const DesktopOnly = styled.div`
  display: block;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

export const TabTitle = styled.p<any>`
  ${getHeading6BoldTypography};
  color: ${(props) =>
    props.active ? props.theme.primaryColor : props.theme.lightBorderColor};
  line-height: 19px;
  padding-bottom: ${(props) =>
    props.paddingBottom ? props.paddingBottom : "21px"};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.paragraph.lg};
    line-height: 16px;
  }
`;

export const Truncate = styled.div`
  ${truncate};
  display: inline-block;

  * {
    ${truncate};
  }
`;

export const Dot = styled.div<{ color: string }>`
  width: 8px;
  height: 8px;
  background: ${(props) => props.color};
  border-radius: 10px;
`;
