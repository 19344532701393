import { css, keyframes } from "styled-components";

export const navbarHeight = {
  desktop: 80,
  mobile: 112,
};

export const getAbsoluteFillTop = css`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`;

export const getAbsoluteFillBottom = css`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
`;

export const inset = (value: string | number) => css`
  top: ${value};
  right: ${value};
  left: ${value};
  bottom: ${value};
`;

export const getAbsoluteFill = css`
  position: absolute;
  ${inset(0)};
`;

export const getFlexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const getAbsoluteCenter = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const truncate = css`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const getHorizontalGap = (size: string) => css`
  & > *:not(:last-child) {
    margin-right: ${size};
  }
`;

export const getVerticalGap = (size: string) => css`
  & > *:not(:last-child) {
    margin-bottom: ${size};
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
