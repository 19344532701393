import { IExtendedFile } from "../common/commonTypes";
import {
  AudioFileFormats,
  ImageFileFormats,
  MimeEnum,
} from "../common/commonEnums";
import { captureException } from "@sentry/nextjs";

export function getSrcFromFile(file?: File): Promise<string> {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject(undefined);
    }
    const reader = new FileReader();
    reader.onload = () => {
      // convert image file to base64 string for preview
      if (typeof reader.result === "string") {
        return resolve(reader.result);
      }
      return reject(undefined);
    };
    reader.onerror = () => {
      const error = new Error("error reading file");
      captureException(error);
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

export const getSrcFromOptionalFile = async (file?: File) => {
  if (!file) {
    return undefined;
  }

  try {
    return getSrcFromFile(file);
  } catch (error) {
    return undefined;
  }
};

export function getImageDimensionsFromFile(
  file?: File
): Promise<{ width: number; height: number }> {
  return new Promise(async (resolve, reject) => {
    if (!file) {
      return reject({});
    }

    const image = document.createElement("img");

    image.onload = () => {
      return resolve({
        width: image.naturalWidth,
        height: image.naturalHeight,
      });
    };

    image.onerror = () => {
      const error = new Error("error processing image");
      captureException(error);
      reject(error);
    };

    image.src = await getSrcFromFile(file);
  });
}

export const getExtendedImageFile = async (
  file: File
): Promise<IExtendedFile> => {
  const { width, height } = await getImageDimensionsFromFile(file);

  return {
    file,
    meta: {
      width,
      height,
    },
  };
};

export function getHumanReadableFileSize(bytes: number): string {
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return ((bytes / Math.pow(1024, i)).toFixed(2) as any) * 1 + " " + sizes[i];
}

export const getAudioFileDuration = async (
  file?: File
): Promise<number | undefined> => {
  if (!file) {
    return undefined;
  }

  return new Promise(async (resolve, reject) => {
    const objectURL = URL.createObjectURL(file);
    const audio = document.createElement("audio");

    audio.src = objectURL;
    audio.onloadedmetadata = () => {
      resolve(Math.round(audio.duration || 0));
      URL.revokeObjectURL(objectURL);
    };
    audio.onerror = () => {
      const error = new Error("error processing audio");
      captureException(error);
      reject(error);
    };
  });
};

export const getExtendedAudioFile = async (
  file: File
): Promise<IExtendedFile> => {
  const duration = await getAudioFileDuration(file);

  return {
    file,
    meta: {
      duration,
    },
  };
};

export const downloadFileFromUrl = (url: string, fileName?: string) => {
  const a = document.createElement("a");

  let downloadUrl = url;
  if (new URL(url).host.includes("cloudinary")) {
    const delimiter = "/upload";
    const delimiterLength = delimiter.length;
    const uploadIndex = url.indexOf(delimiter);
    downloadUrl = `${url.substr(
      0,
      uploadIndex + delimiterLength
    )}/fl_attachment${url.substr(uploadIndex + delimiterLength, url.length)}`;
  }

  a.href = downloadUrl;
  a.download = fileName || "";
  a.click();
};

export const getDefaultExtendedFile = (file: File): IExtendedFile => ({
  file,
  meta: {},
});

export const extendFile = (file: File): Promise<IExtendedFile> => {
  const type = file.type as MimeEnum;

  if (AudioFileFormats.includes(type)) {
    return getExtendedAudioFile(file);
  }

  if (ImageFileFormats.includes(type)) {
    return getExtendedImageFile(file);
  }

  return Promise.resolve(getDefaultExtendedFile(file));
};

export const isGif = (mimeType?: MimeEnum) => {
  if (!mimeType) {
    return false;
  }

  return mimeType === MimeEnum.gif;
};

export const isVideo = (mimeType?: MimeEnum) => {
  if (!mimeType) {
    return false;
  }

  return mimeType === MimeEnum.mp4;
};
