import { useTheme } from "styled-components";
import { useWindowSize } from "../../context/WindowSizeContext";

export default function useMobileResponsive() {
  const size = useWindowSize();
  const theme = useTheme();

  const isMobile = size.width! <= parseInt(theme.breakpoints.mobileL);
  const isTablet = size.width! <= parseInt(theme.breakpoints.tablet);
  const isLaptop = size.width! <= parseInt(theme.breakpoints.laptop);

  const isDesktop = size.width! >= parseInt(theme.breakpoints.tablet);

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
  };
}
