export enum QueryKeys {
  homeData = "homeData",

  userProfile = "userProfile",
  userMusic = "userMusic",
  userCollection = "userCollection",
  userQuickView = "userQuickView",

  assetDetails = "assetDetails",
  assetRelatedTracks = "assetRelatedTracks",
  assetRelatedArtists = "assetRelatedArtists",

  coingecko = "coingecko",

  session = "session",

  artists = "artists",

  followersCount = "followersCount",
  followingCount = "followingCount",
  isFollowedByMe = "isFollowedByMe",
  followersList = "followersList",
  followingList = "followingList",

  eoaTracks = "eoaTracks",

  searchListings = "searchListings",
  searchArtists = "searchArtists",

  marketData = "marketData",
  marketFilters = "marketFilters",

  web3 = "web3",
  listingOwner = "listingOwner",
  listingIssues = "listingIssues",
}
