import { useTranslation } from "react-i18next";
import styled from "styled-components";
import FooterTop from "./FooterTop";
import Icon from "../../../common/components/Icon/Icon";
import { socialIcons } from "./socialIcons";
import { ISocialIcon } from "../../commonTypes";
import { Paragraph14Bold } from "../../Typography/Paragraph14";
import { getHorizontalGap } from "../../styles";

const Root = styled.div`
  background: ${({ theme }) => theme.backgroundColor};
  padding: 75px 69px 53px 69px;
  max-height: 986px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 64px 20px 27px 20px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Bottom = styled.div`
  border-top: 1px solid ${(props) => props.theme.borderColor};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding-top: 33px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
  }
`;

const NoizdLimitedText = styled(Paragraph14Bold)`
  line-height: 16px;
  text-transform: capitalize;
  opacity: 0.7;
`;

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${getHorizontalGap("50px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding-top: 20px;
    justify-content: center;
  }
`;
export const SocialIcon = styled(Icon)`
  cursor: pointer;
`;

const NoizdTextContainer = styled.div``;

type Props = {
  onAboutModalOpen: () => void;
};

const Footer = ({ onAboutModalOpen }: Props) => {
  const { t } = useTranslation();

  const handleOnClick = (link: string) => {
    window.open(link, "_blank");
  };

  return (
    <Root>
      <Wrapper>
        <FooterTop onAboutModalOpen={onAboutModalOpen} />
        <Bottom>
          <Container>
            <NoizdTextContainer>
              <NoizdLimitedText>
                {t("landing-footer-noizd-text")}
              </NoizdLimitedText>
            </NoizdTextContainer>
            <IconsContainer>
              {socialIcons.map((icon: ISocialIcon, index: number) => {
                return (
                  <SocialIcon
                    key={index}
                    name={icon.name}
                    onClick={() => handleOnClick(icon.url)}
                  />
                );
              })}
            </IconsContainer>
          </Container>
        </Bottom>
      </Wrapper>
    </Root>
  );
};

export default Footer;
