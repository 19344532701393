import { ImageProps } from "next/image";

const CDN_URL_PREFIX = "https://cf";

export const getResizedUrl = (
  src: string | ImageProps["src"],
  size?: number | string
): string => {
  if (!size || typeof src !== "string" || !src.startsWith(CDN_URL_PREFIX)) {
    return src;
  }

  const urlParts = src.split("/");
  const imageName = urlParts.pop();
  return [...urlParts, size, imageName].join("/");
};
