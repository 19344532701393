import styled, { css } from "styled-components";
import {
  getBasePoppinsTypography,
  getBaseTypography,
  getBoldTypography,
  getRegularTypography,
} from "./helpers";

export const getParagraph14BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.paragraph.md};
  line-height: 20px;
`;

export const getParagraph14RegularTypography = css`
  ${getBaseTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.paragraph.md};
  line-height: 20px;
`;

export const getParagraph14PoppinsRegularTypography = css`
  ${getBasePoppinsTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.paragraph.md};
  line-height: 20px;
`;

export const Paragraph14Bold = styled.p`
  ${getParagraph14BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph14Regular = styled.p`
  ${getParagraph14RegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph14PoppinsRegular = styled.p`
  ${getParagraph14PoppinsRegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph14RegularSecondary = styled.p`
  ${getParagraph14RegularTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.7;
`;

export const Paragraph14BoldError = styled.p`
  ${getParagraph14BoldTypography};
  color: ${(props) => props.theme.errorColor};
`;

export const Paragraph14BoldSecondary = styled.p`
  ${getParagraph14BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.7;
`;
