import { BidTypeEnum, ListingStatusEnum } from "../common/commonEnums";
import {
  IApiResponse,
  IAsset,
  IDeployment,
  IQueryParams,
} from "../common/commonTypes";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { fetchWrapperProtected } from "../helpers/fetchWrapperProtected";
import { createSequalizeUrl } from "../helpers/requestHelper";
import { getEnv } from "../helpers/env";

const NEXT_PUBLIC_API_URL = getEnv("NEXT_PUBLIC_API_URL");

export const listingService = {
  fetchListings: async (params: IQueryParams = {}) => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("listing", params)
    );
    return response;
  },
  fetchListing: async (id: string) => {
    const response: IApiResponse = await fetchWrapper.get(
      `${NEXT_PUBLIC_API_URL}/listing/${id}`
    );
    return response;
  },
  fetchLockedListingsForOwner: async (
    address: string,
    asset: IAsset,
    deployment: IDeployment
  ) => {
    return fetchWrapperProtected.get(
      createSequalizeUrl("listing", {
        where: {
          type: BidTypeEnum.bid,
          status: ListingStatusEnum.active,
          buyer_address: address,
          asset_id: asset.id,
          deployment_id: deployment.id,
        },
      })
    );
  },
  searchListings: async (searchPhrase: string, params: IQueryParams = {}) =>
    listingService.fetchListings({
      ...params,
      where: {
        ...params.where,
        "$music.title$": {
          $iLike: `%${searchPhrase}%`,
        },
      },
    }),
};
