import {
  useFollowersCountQuery,
  useFollowingCountQuery,
  useFollowMutation,
  useIsFollowedByMeQuery,
  useUnfollowMutation,
} from "../../queries/followers.query";
import { IUserProfile } from "../commonTypes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSession } from "../../queries/authentication.query";

interface IOptions {
  fetchFollowersCount: boolean;
  fetchFollowingCount: boolean;
}

export const useFollowersSummary = (user: IUserProfile, options: IOptions) => {
  const { t } = useTranslation();
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const { user: loggedUser, isLoggedIn } = useSession();

  const { data: followersCount = 0 } = useFollowersCountQuery(
    user.id,
    options.fetchFollowersCount
  );
  const { data: followingCount = 0 } = useFollowingCountQuery(
    user.id,
    options.fetchFollowingCount
  );
  const { isFollowedByMe, isLoading: isFollowedByMeLoading } =
    useIsFollowedByMeQuery(user.id);
  const { mutate: follow, isLoading: isFollowLoading } = useFollowMutation();
  const { mutate: unfollow, isLoading: isUnfollowLoading } =
    useUnfollowMutation();
  const isFollowingUpdating = isFollowLoading || isUnfollowLoading;

  const handleFollow = () => {
    if (isLoggedIn) {
      follow(user.id);
    } else {
      setIsLoginModalOpen(true);
    }
  };

  const handleUnfollow = () => unfollow(user.id);

  return {
    isOwnProfile: loggedUser && user.id == loggedUser.id,
    followersCount,
    followingCount,
    isFollowedByMe,
    isFollowedByMeLoading,
    isFollowingUpdating,
    handleFollow,
    handleUnfollow,
    toggleFollowing: isFollowedByMe ? handleUnfollow : handleFollow,
    followButtonText: t(isFollowedByMe ? "unfollow" : "follow"),
    isLoginModalOpen,
    closeLoginModal: () => setIsLoginModalOpen(false),
  };
};
