import { BigNumber, ethers } from "ethers";
import {
  IApiResponse,
  IAsset,
  IDeployment,
  IListing,
  ITransactionEvent,
  TransactionActionEnum,
  TransactionStatusEnum,
} from "../../common/commonTypes";
import { IWithdrawal, TransactionEvent } from "../../common/hooks/useStaking";
import NOIZDBase from "./NOIZDBase";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import { isEther } from "../format";
import { getTokenContract } from "../tokenHelper";
import { TypedDataField } from "@ethersproject/abstract-signer";
import { bidService } from "../../api/bidService";
import moment from "moment";

interface ISpendMessage {
  asset: string;
  amount: string;
  to: string;
  from: string;
  deadline: number;
  nonce: number;
  metadata: string;
}

export default class NOIZDv2 extends NOIZDBase {
  spendType: Array<TypedDataField>;
  InstantWithdrawContract: ethers.Contract;
  LazyAuctionCompleterContract: ethers.Contract;

  constructor(deployment: IDeployment, signer: ethers.providers.JsonRpcSigner) {
    super(deployment, signer);

    this.InstantWithdrawContract = new ethers.Contract(
      deployment.instant_withdraw_address,
      deployment.instant_withdraw_abi,
      this.signer
    );

    this.LazyAuctionCompleterContract = new ethers.Contract(
      deployment.lazy_auction_completer_address,
      deployment.lazy_auction_completer_abi,
      this.signer
    );

    this.stakingDomain = {
      name: "Staking",
      version: "1",
      chainId: deployment.chain.id,
      verifyingContract: this.stakingContract.address,
    };

    this.spendType = [
      {
        name: "asset",
        type: "address",
      },
      {
        name: "amount",
        type: "uint256",
      },
      {
        name: "to",
        type: "address",
      },
      {
        name: "from",
        type: "address",
      },
      {
        name: "deadline",
        type: "uint256",
      },
      {
        name: "nonce",
        type: "uint256",
      },
      {
        name: "metadata",
        type: "bytes",
      },
    ];
  }

  async getWithdrawalData(
    amount: string,
    token: IAsset,
    user: string
  ): Promise<IWithdrawal> {
    // withdrawals are only valid for 15 minutes
    const deadline = moment().add(15, "minutes").unix();

    const nonce = await this.getNonce(user);

    return {
      amount,
      nonce,
      deadline: deadline.toString(),
      token,
      user,
    };
  }

  signSpend(spend: ISpendMessage) {
    return this.signer._signTypedData(
      this.stakingDomain,
      { Spend: this.spendType },
      spend
    );
  }

  async signWithdrawal(
    withdrawal: IWithdrawal
  ): Promise<{ signature: string }> {
    const spend: ISpendMessage = {
      ...withdrawal,
      to: withdrawal.user,
      from: withdrawal.user,
      asset: withdrawal.token.address,
      deadline: Number(withdrawal.deadline),
      metadata: "0x00",
    };

    const signature = await this.signer._signTypedData(
      this.stakingDomain,
      { Spend: this.spendType },
      spend
    );

    return {
      signature,
    };
  }

  async placeBid(
    listing: IListing,
    amount: string,
    address: string
  ): Promise<IApiResponse> {
    // set deadline to 15 mins after the listing expires
    const deadline = moment(listing.ending).add(15, "minutes").unix();

    const metadata = ethers.utils.defaultAbiCoder.encode(
      ["uint256"],
      [listing.id]
    );

    const spend: ISpendMessage = {
      asset: listing.asset.address,
      amount,
      to: listing.seller_address,
      from: address,
      deadline,
      nonce: await this.getNonce(address),
      metadata,
    };

    const spendSignature = await this.signSpend(spend);

    const data = {
      spend,
      spend_signature: spendSignature,
    };

    return bidService.placeBid(listing, amount, address, data);
  }

  // This assumes the ERC20 allowance has already been granted
  async stake(amount: string, asset: IAsset): Promise<TransactionResponse> {
    if (!asset) {
      throw "Invalid asset";
    }

    // console.log("get gas price");
    // const feeData = await this.provider.getFeeData();
    // console.log("fee data", feeData);
    // console.log("maxFeePeGas", feeData.maxFeePerGas);

    const options = {
      value: isEther(asset) ? amount : 0,
    };

    return this.stakingContract
      .connect(this.signer)
      .deposit(asset.address, amount, options);
  }

  async getNonce(address: string): Promise<number> {
    const nonce = await this.stakingContract.nonces(address);
    return nonce.toNumber();
  }

  async getStake(user: string, asset: IAsset): Promise<BigNumber> {
    if (!asset) {
      return Promise.resolve(ethers.BigNumber.from("0"));
    }
    return this.stakingContract.stakes(user, asset?.address);
  }

  async getBalance(user: string, asset: IAsset): Promise<BigNumber> {
    if (!asset) {
      return Promise.resolve(ethers.BigNumber.from("0"));
    }
    if (isEther(asset)) {
      return this.provider.getBalance(user);
    }

    const token = getTokenContract(asset, this.provider);
    return token.balanceOf(user);
  }

  getTokenAllowance(user: string, asset: IAsset): Promise<BigNumber> {
    if (!asset) {
      return Promise.resolve(ethers.BigNumber.from("0"));
    }
    if (isEther(asset)) {
      return this.provider.getBalance(user);
    }

    const token = getTokenContract(asset, this.provider);
    return token.allowance(user, this.stakingContract.address);
  }

  async withdrawInstant(
    withdrawal: IWithdrawal,
    userSignature: string, //signed by the staker,
    ownerSignature: string // signed by withdrawal contract owner
  ): Promise<TransactionResponse> {
    const spend: ISpendMessage = {
      ...withdrawal,
      to: withdrawal.user,
      from: withdrawal.user,
      asset: withdrawal.token.address,
      deadline: Number(withdrawal.deadline),
      metadata: "0x00",
    };

    return this.InstantWithdrawContract.withdraw(
      [
        spend.asset,
        spend.amount,
        spend.to,
        spend.from,
        spend.deadline,
        spend.nonce,
        spend.metadata,
      ],
      userSignature,
      ownerSignature
    );
  }

  approveToken(asset: IAsset, amount: string): Promise<TransactionResponse> {
    const token = getTokenContract(asset, this.provider);
    return token
      .connect(this.signer)
      .approve(this.stakingContract.address, amount);
  }

  onStakeEvent(
    address: string,
    asset: string,
    callback: (event: TransactionEvent) => void
  ): void {
    const filter = this.stakingContract.filters.Deposit(address, asset);

    this.stakingContract.once(filter, (sender, tokenAddress, amount, event) => {
      callback(event);
    });
  }

  onWithdrawEvent(
    address: string,
    asset: string,
    nonce: number,
    callback: (event: TransactionEvent) => void
  ): void {
    const filter = this.InstantWithdrawContract.filters.Withdraw(
      address,
      asset,
      nonce
    );

    this.InstantWithdrawContract.once(
      filter,
      (staker, token, nonce, amount, event) => {
        callback(event);
      }
    );
  }

  // TODO: maybe this should return a new type IDepositEvent?
  // and the caller in useTransactionList can add all the metadata?
  async getStakeEvents(address: string): Promise<ITransactionEvent[]> {
    const filter = this.stakingContract.filters.Deposit(address);
    // TODO: work out the block ranges here!?
    // TODO: move to react query?
    const currentBlock = await this.provider.getBlockNumber();
    const toBlock = currentBlock;
    // const fromBlock = Math.max(0, currentBlock - 3499);
    const fromBlock = Math.max(0, currentBlock - 100000);

    const events = await this.stakingContract.queryFilter(
      filter,
      fromBlock,
      toBlock
    );

    const transactionEvents = await events.map(async (event) => {
      const decoded = (event as any).decode(event.data, event.topics);
      const block = await event.getBlock();

      return {
        action: TransactionActionEnum.deposit,
        amount: decoded.amount,
        contractAddress: this.stakingContract.address,
        hash: event.transactionHash,
        senderAddress: address,
        status: TransactionStatusEnum.complete,
        timestamp: new Date(block.timestamp * 1000),
        blockNumber: block.number,
        chain: this.deployment.chain,
        tokenAddress: decoded.asset,
      };
    });

    const results = await Promise.all(transactionEvents);

    return ([] as ITransactionEvent[]).concat(...results);
  }

  async getWithdrawEvents(address: string): Promise<ITransactionEvent[]> {
    const filter = this.InstantWithdrawContract.filters.Withdraw(address);
    // TODO: work out the block ranges here!?
    // TODO: move to react query?
    const currentBlock = await this.provider.getBlockNumber();
    const toBlock = currentBlock;
    // const fromBlock = Math.max(0, currentBlock - 3499);
    const fromBlock = Math.max(0, currentBlock - 100000);

    const events = await this.InstantWithdrawContract.queryFilter(
      filter,
      fromBlock,
      toBlock
    );

    const transactionEvents = await events.map(async (event) => {
      const decoded = (event as any).decode(event.data, event.topics);
      const block = await event.getBlock();

      return {
        action: TransactionActionEnum.withdraw,
        amount: `-${decoded.amount.toString()}`, //event emits positive amounts but we want negative: ;
        contractAddress: this.InstantWithdrawContract.address,
        hash: event.transactionHash,
        senderAddress: address,
        status: TransactionStatusEnum.complete,
        timestamp: new Date(block.timestamp * 1000),
        blockNumber: block.number,
        chain: this.deployment.chain,
        tokenAddress: decoded.token,
      };
    });

    const results = await Promise.all(transactionEvents);

    return ([] as ITransactionEvent[]).concat(...results);
  }

  async getPurchaseEvents(address: string): Promise<ITransactionEvent[]> {
    // we manually specify the abi here
    // and create a new contract
    // because the Complete is defined outside of LazyAuctionComplete
    // so it is not available in LazyAuctionCompleterContract.filters
    const abi = [
      "event Complete(address indexed seller, address indexed buyer, uint256 indexed id, address asset, uint256 amount, uint256 nftId)",
    ];
    const contract = new ethers.Contract(
      this.LazyAuctionCompleterContract.address,
      abi,
      this.signer
    );

    const filterComputed = contract.filters.Complete(null, address);

    // TODO: work out the block ranges here!?
    // TODO: move to react query?
    const currentBlock = await this.provider.getBlockNumber();
    const toBlock = currentBlock;
    const fromBlock = Math.max(0, currentBlock - 1000000);

    const events = await contract.queryFilter(
      filterComputed,
      // "latest",
      // -1000000
      fromBlock,
      toBlock
    );

    const transactionEvents = await events.map(
      async (event: any): Promise<ITransactionEvent> => {
        const decoded = await event.decode(event.data, event.topics);
        const block = await event.getBlock();

        return {
          timestamp: new Date(block.timestamp * 1000),
          action: TransactionActionEnum.buy,
          status: TransactionStatusEnum.complete,
          amount: decoded.amount.toString(),
          hash: event.transactionHash,
          contractAddress: this.LazyAuctionCompleterContract.address,
          blockNumber: block.number,
          senderAddress: address,
          tokenAddress: decoded.asset,
          chain: this.deployment.chain,
        };
      }
    );

    const results = await Promise.all(transactionEvents);

    return ([] as ITransactionEvent[]).concat(...results);
  }

  async getSaleEvents(address: string): Promise<ITransactionEvent[]> {
    // we manually specify the abi here
    // and create a new contract
    // because the Complete is defined outside of LazyAuctionComplete
    // so it is not available in LazyAuctionCompleterContract.filters
    const abi = [
      "event Complete(address indexed seller, address indexed buyer, uint256 indexed id, address asset, uint256 amount, uint256 nftId)",
    ];
    const contract = new ethers.Contract(
      this.LazyAuctionCompleterContract.address,
      abi,
      this.signer
    );

    const filterComputed = contract.filters.Complete(address);

    // TODO: work out the block ranges here!?
    // TODO: move to react query?
    const currentBlock = await this.provider.getBlockNumber();
    const toBlock = currentBlock;
    const fromBlock = Math.max(0, currentBlock - 1000000);

    const events = await contract.queryFilter(
      filterComputed,
      fromBlock,
      toBlock
    );

    const transactionEvents = await events.map(
      async (event: any): Promise<ITransactionEvent> => {
        const decoded = await event.decode(event.data, event.topics);
        const block = await event.getBlock();

        return {
          timestamp: new Date(block.timestamp * 1000),
          action: TransactionActionEnum.sell,
          status: TransactionStatusEnum.complete,
          amount: decoded.amount.toString(),
          hash: event.transactionHash,
          contractAddress: this.LazyAuctionCompleterContract.address,
          blockNumber: block.number,
          senderAddress: address,
          tokenAddress: decoded.asset,
          chain: this.deployment.chain,
        };
      }
    );

    const results = await Promise.all(transactionEvents);
    return ([] as ITransactionEvent[]).concat(...results);
  }
}
