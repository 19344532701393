import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { getParagraph16MediumTypography } from "../../Typography/Paragraph16";
import Modal, { IModalProps } from "./Modal";
import { routes } from "../../../routes";
import { useLoginRoute } from "../../hooks/useLoginRoute";
import Button from "../Buttons/Button";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { getVerticalGap } from "../../styles";
import Icon from "../Icon/Icon";

const Content = styled.div`
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LoginIcon = styled(Icon)`
  margin-bottom: 32px;
`;

const Instruction = styled.p`
  ${getParagraph16MediumTypography};
  word-break: break-word;
`;

const Buttons = styled.div`
  margin-top: 32px;
  width: 100%;
  ${getVerticalGap("8px")};
`;

const LoginModal = (props: IModalProps & { instruction: string }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const loginPageRoute = useLoginRoute();

  return (
    <Modal {...props}>
      <Content>
        <LoginIcon name="avatarIcon" size="80px" color={theme.accent.normal} />
        <Instruction>{props.instruction}</Instruction>

        <Buttons>
          <Button href={loginPageRoute} text={t("login-modal-login-cta")} />
          <ButtonOutlined
            href={routes.auth.join.root}
            text={t("login-modal-sign-up-cta")}
          />
        </Buttons>
      </Content>
    </Modal>
  );
};

export default LoginModal;
