import { IMusic } from "../common/commonTypes";
import { isVideo } from "./fileHelper";
import { getPosterUrl, getResizedVideoUrl } from "./videoHelper";
import { getResizedUrl } from "./imageHelper";
import { IMAGE_SIZES } from "../constants/imageSizes";
import { MimeEnum } from "../common/commonEnums";

type IPlayerHandler = ((details: MediaSessionActionDetails) => void) | null;

interface IPlayerHandlers {
  play: IPlayerHandler;
  pause: IPlayerHandler;
  stop: IPlayerHandler;
  seekTo: IPlayerHandler;
}

const getCoverArtwork = (music: IMusic) => {
  const { url, mime } = music.cover;
  const sizes = [
    {
      artworkSize: "96x96",
      imageSize: IMAGE_SIZES.trackCoverArt.xs,
    },
    {
      artworkSize: "128x128",
      imageSize: IMAGE_SIZES.trackCoverArt.small,
    },
    {
      artworkSize: "192x192",
      imageSize: IMAGE_SIZES.trackCoverArt.medium,
    },
    {
      artworkSize: "256x256",
      imageSize: IMAGE_SIZES.trackCoverArt.medium,
    },
    {
      artworkSize: "384x384",
      imageSize: IMAGE_SIZES.trackCoverArt.medium,
    },
    {
      artworkSize: "512x512",
      imageSize: IMAGE_SIZES.trackCoverArt.big,
    },
  ];

  if (isVideo(mime)) {
    return sizes.map(({ artworkSize, imageSize }) => ({
      src: getPosterUrl(getResizedVideoUrl(url, imageSize)),
      sizes: artworkSize,
      type: MimeEnum.jpeg,
    }));
  }

  return sizes.map(({ artworkSize, imageSize }) => ({
    src: getResizedUrl(url, imageSize),
    sizes: artworkSize,
    type: mime,
  }));
};

const setHandler = (event: string, handler: IPlayerHandler) => {
  if ("mediaSession" in navigator) {
    try {
      navigator.mediaSession.setActionHandler(event, handler);
    } catch (e) {}
  }
};

export const updateMediaSession = (
  music: IMusic,
  handlers: IPlayerHandlers
) => {
  if ("mediaSession" in navigator) {
    try {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: music.title,
        artist: music.artist.username,
        artwork: getCoverArtwork(music),
      });

      setHandler("play", handlers.play);
      setHandler("pause", handlers.pause);
      setHandler("stop", handlers.stop);
      setHandler("seekto", handlers.seekTo);
    } catch (e) {}
  }
};

export const setPreviousTrackHandler = (handler: IPlayerHandler) =>
  setHandler("previoustrack", handler);

export const setNextTrackHandler = (handler: IPlayerHandler) =>
  setHandler("nexttrack", handler);
