import { safe } from "./safeWebApis";

export const scrollToElement = (element: Element, offsetTop: number = 100) => {
  const bodyRect = safe.document?.body.getBoundingClientRect().top || 0;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offsetTop;

  safe.window?.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};

export const lockScroll = () => {
  const scrollY = window.scrollY;
  document.documentElement.style.height = "100vh";
  document.body.style.position = "fixed";
  document.body.style.top = `-${scrollY}px`;
  document.body.style.right = "0";
  document.body.style.left = "0";
  document.body.style.overscrollBehaviorY = "contain";
};

export const unlockScroll = () => {
  const scrollY = document.body.style.top;
  document.documentElement.style.removeProperty("height");
  document.body.style.removeProperty("position");
  document.body.style.removeProperty("top");
  document.body.style.removeProperty("right");
  document.body.style.removeProperty("left");
  document.body.style.removeProperty("overscroll-behavior-y");
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
};
