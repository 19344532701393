import React from "react";
import styled, { css, useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { IStyleableProps, ITypographySize } from "../../commonTypes";
import { truncate } from "../../styles";

type TWeight = "300" | "400" | "normal" | "500" | "bold" | "900";
type TAlign = "left" | "right" | "center" | "justify";
type TFontFamily = "Roboto" | "Poppins";

const Text = styled.p<{
  $weight: TWeight;
  $align: TAlign;
  $fontSize: string;
  $secondary: boolean;
  $truncate: boolean;
  $fontFamily: TFontFamily;
  $lineHeight?: string | number;
}>`
  font-family: ${({ $fontFamily }) => $fontFamily}, sans-serif;
  color: ${({ theme }) => theme.primaryColor};
  font-size: ${({ $fontSize }) => $fontSize};
  font-weight: ${({ $weight }) => $weight};
  text-align: ${({ $align }) => $align};
  opacity: ${({ $secondary }) => ($secondary ? 0.7 : 1)};
  line-height: ${({ $lineHeight }) => $lineHeight || 1.2};
  text-decoration: none;

  ${({ $truncate }) =>
    $truncate &&
    css`
      ${truncate};
    `}
`;

export type TypographySize = keyof ITypographySize;

export interface ITypographyProps {
  id?: string;
  children?: React.ReactNode;
  fontFamily?: TFontFamily;
  size?: TypographySize;
  weight?: TWeight;
  align?: TAlign;
  secondary?: boolean;
  heading?: boolean;
  element?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  truncate?: boolean;
  lineHeight?: string | number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  href?: string;
  target?: string;
  rel?: string;
}

const Typography = ({
  id,
  children,
  fontFamily = "Roboto",
  size = "md",
  weight = "300",
  align = "left",
  secondary = false,
  heading = false,
  element = "p",
  truncate = false,
  lineHeight,
  ...props
}: ITypographyProps & IStyleableProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const content = id ? t(id) : children;
  const sizingMap = heading ? theme.heading : theme.paragraph;
  const fontSize = sizingMap[size];

  return (
    <Text
      as={element}
      $fontSize={fontSize}
      $weight={weight}
      $align={align}
      $secondary={secondary}
      $fontFamily={fontFamily}
      $truncate={truncate}
      $lineHeight={lineHeight}
      {...props}
    >
      {content}
    </Text>
  );
};

export default Typography;

// Example usage:
// normal text bold: <Typography id="some-translation-id" size="lg" weight="bold" element="p" />
// secondary text: <Typography id="some-translation-id" size="lg" element="span" secondary/>
// heading: <Typography heading size="md" weight="bold" element="h3">Not translated text</Typography>
