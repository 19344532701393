import styled, { css } from "styled-components";
import {
  getBaseTypography,
  getBoldTypography,
  getRegularTypography,
} from "./helpers";

export const getParagraph10BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.paragraph.xs};
  line-height: 11.72px;
`;

export const getParagraph10RegularTypography = css`
  ${getBaseTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.paragraph.xs};
  line-height: 11.72px;
`;

export const Paragraph10Regular = styled.p`
  ${getParagraph10RegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph10Bold = styled.p`
  ${getParagraph10BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph10RegularSecondary = styled.p`
  ${getParagraph10RegularTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.7;
`;
