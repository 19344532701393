import { useWeb3 } from "@chainsafe/web3-context";
import { ethers } from "ethers";
import { useState, createContext, ReactNode, useContext } from "react";
import { parseUnits } from "@ethersproject/units/lib";
import { useTranslation } from "react-i18next";
import { stakingService } from "../../api/stakingService";
import { useContracts } from "../../context/Contracts";

import { addTransaction } from "../../helpers/transactions";
import {
  IAsset,
  IDeployment,
  IListing,
  ITransactionEvent,
  TransactionActionEnum,
  TransactionStatusEnum,
} from "../commonTypes";
import { captureException } from "@sentry/nextjs";
import { getTokenContract } from "../../helpers/tokenHelper";
import { StakingClient } from "../../helpers/stakingClient";
import useChain from "./useChain";
import Timeout = NodeJS.Timeout;

export interface IBidMessage {
  listing: IListing;
  staker: string;
  target: string;
  amount: string;
}

export interface IWithdrawal {
  amount: string;
  deadline: string;
  nonce: number;
  user: string;
  token: IAsset;
}

export interface TransactionEvent {
  address: string;
  args: any[];
  blockNumber?: number;
  decode: any;
  transactionHash: string;
}

interface StakingContextValues {
  stake: (amount: string, asset: IAsset, deployment: IDeployment) => void;
  isSubmitting: boolean;
  withdraw: (amount: string, token: IAsset, deployment: IDeployment) => void;
  error: string;
  lastTxHash?: string;
  clearTransaction: () => void;
  signBid: (bid: IBidMessage) => Promise<string>;
  isLoading: boolean;
  isApproving: boolean;
  success?: TransactionEvent;
  approveToken: (token: IAsset, amount: string) => Promise<void>;
}

const StakingContext = createContext<StakingContextValues>({
  error: "",
  isSubmitting: false,
  stake: (amount, asset, deployment) => {},
  withdraw: (amount, token, deployment) => {},
  clearTransaction: () => {},
  signBid: () => Promise.resolve(""),
  isLoading: false,
  success: undefined,
  approveToken: (token, amount) => Promise.resolve(),
  isApproving: false,
});

export const StakingProvider = ({ children }: { children: ReactNode }) => {
  const { provider, address, onboard } = useWeb3();
  const { localStakingContract: stakingContract, stakingContracts } =
    useContracts();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<TransactionEvent | undefined>();
  const [lastTxHash, setLastTxHash] = useState("");
  const { deployment } = useChain();

  const { t } = useTranslation();

  async function approveToken(asset: IAsset, _amount: string) {
    const amount = ethers.utils.parseUnits(_amount.toString(), asset.decimals);
    const client = new StakingClient(deployment!, provider!.getSigner());

    const allowance = await client.getTokenAllowance(address!, asset);

    if (allowance.lt(amount)) {
      await client.approveToken(asset, amount.toString());

      setIsApproving(true);

      watchApprovalEvents({
        asset,
        owner: address!,
        spender: stakingContract!.address,
        amount: amount.toString(),
      });
    }
  }

  async function stake(amount: string, asset: IAsset, deployment: IDeployment) {
    try {
      clearTransaction();
      await onboard?.walletCheck();
      setIsSubmitting(true);

      const unitValue = ethers.utils
        .parseUnits(amount, asset.decimals)
        .toString();

      const client = new StakingClient(deployment, provider!.getSigner());

      let tx = await client.stake(unitValue, asset);

      setLastTxHash(tx.hash);

      const transactionEvent: ITransactionEvent = {
        action: TransactionActionEnum.deposit,
        timestamp: new Date(),
        amount: parseUnits(amount, asset.decimals).toString(),
        status: TransactionStatusEnum.pending,
        hash: tx.hash,
        contractAddress: stakingContract!.address,
        senderAddress: address!,
        tokenAddress: asset.address,
        chain: deployment.chain,
      };

      addTransaction(transactionEvent);

      // watchStakeEvents(deployment);

      client.onStakeEvent(address!, asset.address, (event) => {
        setSuccess(event);
      });
    } catch (error) {
      if (error.code === "INSUFFICIENT_FUNDS") {
        setError(t("insufficient-funds"));
      } else {
        captureException(error);
        setError(error.message);
      }
    }
  }

  async function withdraw(
    amount: string,
    token: IAsset,
    deployment: IDeployment
  ) {
    clearTransaction();
    setIsSubmitting(true);
    try {
      const amountWei = ethers.utils
        .parseUnits(amount, token.decimals)
        .toString();

      const client = new StakingClient(deployment, provider!.getSigner());

      // get the withdrawal data from the client
      // this will return the correct data for deadline, nonce etc

      const withdrawal = await client.getWithdrawalData(
        amountWei,
        token,
        address!
      );

      // request a signature from the user
      const { signature: stakerSignature } = await client.signWithdrawal(
        withdrawal
      );

      // request a signature from the NOIZD BE
      const res = await stakingService.withdraw(
        token,
        deployment,
        amountWei.toString(),
        address!,
        stakerSignature,
        Number(withdrawal.deadline),
        withdrawal.nonce.toString()
      );
      const { signature: ownerSignature } = res.data;

      // submit the transaction with both signatures
      const tx = await client.withdrawInstant(
        withdrawal,
        stakerSignature,
        ownerSignature
      );

      setLastTxHash(tx.hash);

      const transactionEvent: ITransactionEvent = {
        action: TransactionActionEnum.withdraw,
        timestamp: new Date(),
        amount: parseUnits(amount, token.decimals).toString(),
        status: TransactionStatusEnum.pending,
        hash: tx.hash,
        contractAddress: stakingContract!.address,
        senderAddress: address!,
        tokenAddress: token.address,
      };

      addTransaction(transactionEvent);

      client.onWithdrawEvent(
        address!,
        token.address,
        withdrawal.nonce,
        (event) => {
          setSuccess(event);
        }
      );
    } catch (error) {
      captureException(error);
      setError(error.message);
    }
  }

  function clearTransaction() {
    setLastTxHash("");
    setIsSubmitting(false);
    setError("");
    setSuccess(undefined);
    setIsApproving(false);
  }

  // returns signature for Bid
  async function signBid(bid: IBidMessage): Promise<string> {
    const client = new StakingClient(
      bid.listing.deployment,
      provider!.getSigner()
    );
    return client.signBid(bid);
  }

  function watchApprovalEvents(approval: {
    asset: IAsset;
    owner: string;
    spender: string;
    amount: string;
  }) {
    const token = getTokenContract(approval.asset, stakingContract!.provider);
    const filter = token!.filters.Approval(approval.owner, approval.spender);

    token?.once(filter, (owner, spender, amount, event) => {
      setIsApproving(false);
    });
  }

  return (
    <StakingContext.Provider
      value={{
        stake,
        isSubmitting,
        withdraw,
        error,
        lastTxHash,
        clearTransaction,
        signBid,
        isLoading,
        success,
        approveToken,
        isApproving,
      }}
    >
      {children}
    </StakingContext.Provider>
  );
};

export const useStaking = () => {
  const context = useContext(StakingContext);
  return context;
};
