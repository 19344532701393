import {
  IApiPage,
  IApiResponse,
  ICustomTheme,
  IProfile,
  IQueryParams,
  ISession,
  ISiweMessage,
  IUser,
  IUserFollow,
  IUserProfile,
  IUserProfilePartial,
} from "../common/commonTypes";
import {
  ILoginRequestDto,
  IRegisterUserDto,
  IResetPasswordDto,
  IResponseUserDto,
} from "../common/dtos";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { fetchWrapperProtected } from "../helpers/fetchWrapperProtected";
import { createSequalizeUrl } from "../helpers/requestHelper";
import { getEnv } from "../helpers/env";

const NEXT_PUBLIC_API_URL = getEnv("NEXT_PUBLIC_API_URL");

export const accountService = {
  validateSession: async (): Promise<ISession> => {
    const response = await fetchWrapperProtected.get(
      `${NEXT_PUBLIC_API_URL}/session`
    );

    return response.data;
  },
  login: async (dto: ILoginRequestDto): Promise<ISession> => {
    const result: IApiResponse = await fetchWrapper.post(
      `${NEXT_PUBLIC_API_URL}/session`,
      dto
    );

    return result.data;
  },

  registerUser: async (dto: IRegisterUserDto): Promise<IResponseUserDto> => {
    const response: IApiResponse = await fetchWrapper.post(
      `${NEXT_PUBLIC_API_URL}/user`,
      dto
    );
    return response.data;
  },

  confirmEmail: async (userId: string, verificationToken: string) =>
    fetchWrapper.put(`${NEXT_PUBLIC_API_URL}/user/${userId}/verify`, {
      verification: verificationToken,
    }),

  fetchIsUsernameUnique: async (username: string): Promise<boolean> => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("user", {
        where: {
          username: {
            $iLike: username.trim(),
          },
        },
      })
    );
    return response.data.count === 0;
  },

  fetchIsUserUriUnique: async (uri: string): Promise<boolean> => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("user", {
        where: {
          uri: {
            $iLike: uri.trim(),
          },
        },
      })
    );
    return response.data.count === 0;
  },

  fetchUser: async (userId: string): Promise<IUserProfile> => {
    const response: IApiResponse = await fetchWrapper.get(
      `${NEXT_PUBLIC_API_URL}/user/${userId}`
    );
    return response.data;
  },

  fetchProfile: async (profileId: string): Promise<IProfile> => {
    const response: IApiResponse = await fetchWrapper.get(
      `${NEXT_PUBLIC_API_URL}/profile/${profileId}`
    );
    return response.data;
  },

  fetchAllUserData: async (userId: string): Promise<IUserProfile> => {
    const user: IUser = await accountService.fetchUser(userId);
    const profile = await accountService.fetchProfile(user.profile_id);

    return {
      ...user,
      profile,
    };
  },

  updateUser: async (
    userId: string,
    userData: Partial<IUser>
  ): Promise<IUser> => {
    const updatedUser = await fetchWrapperProtected.put(
      `${NEXT_PUBLIC_API_URL}/user/${userId}`,
      {
        ...userData,
      },
      "application/json"
    );
    return updatedUser.data;
  },

  updateProfile: async (
    profileId: string,
    profileData: Partial<IProfile>
  ): Promise<IProfile> => {
    const updatedProfile = await fetchWrapperProtected.put(
      `${NEXT_PUBLIC_API_URL}/profile/${profileId}`,
      {
        ...profileData,
      },
      "application/json"
    );
    return updatedProfile.data;
  },

  updateUserProfileBulk: async (
    userId: string,
    profileId: string,
    userProfile: IUserProfilePartial
  ): Promise<IUserProfile> => {
    const { profile: profilePayload, ...userPayload } = userProfile;
    const [updatedUser, updatedProfile] = await Promise.all([
      accountService.updateUser(userId, userPayload),
      accountService.updateProfile(profileId, profilePayload),
    ]);

    return {
      ...updatedUser,
      profile: {
        ...updatedProfile,
      },
    };
  },

  updateUserTheme: async (profileId: string, theme: ICustomTheme | null) =>
    accountService.updateProfile(profileId, { theme }),

  updateUserReceiveAddress: (userId: string, address: string) =>
    accountService.updateUser(userId, { address }),

  forgotPassword: async (email: string) => {
    try {
      const response = await fetchWrapper.delete(
        `${NEXT_PUBLIC_API_URL}/user/${email}/reset`
      );
      return response;
    } catch (error) {
      // we don't want to show user if passed email exist or not, so we treat 404 as successful operation
      if (error?.status !== 404) {
        return Promise.reject(error);
      }
    }
  },

  resetPassword: async (dto: IResetPasswordDto) => {
    try {
      const result: IApiResponse = await fetchWrapper.put(
        `${NEXT_PUBLIC_API_URL}/user/${dto.email}/reset`,
        dto
      );
      return result;
    } catch (error) {
      // we don't want to show user if passed email exist or not, so we treat 404 as successful operation
      if (error?.status !== 404) {
        return Promise.reject(error);
      }
    }
  },

  deleteSession: async () => {
    await fetchWrapperProtected.delete(`${NEXT_PUBLIC_API_URL}/session`);
  },

  getFollowerCount: async (id: string): Promise<number> => {
    const url = createSequalizeUrl(`user/${id}/follow`, { limit: 0 });
    const response = await fetchWrapper.get(url);
    return response.data.count;
  },

  getFollowingCount: async (id: string): Promise<number> => {
    const url = createSequalizeUrl(`user/${id}/follow`, {
      limit: 0,
      where: {
        follower_id: id,
      },
    });
    const response = await fetchWrapper.get(url);
    return response.data.count;
  },

  isFollowing: async (userId: string, artistId: string): Promise<boolean> => {
    const url = createSequalizeUrl(`user/${artistId}/follow`, {
      limit: 1,
      where: { follower_id: userId, followee_id: artistId },
    });
    const result = await fetchWrapper.get(url);
    return result.data.count > 0;
  },

  follow: async (artistId: string) => {
    const url = `${NEXT_PUBLIC_API_URL}/user/${artistId}/follow`;
    return fetchWrapperProtected.post(url, {}, "application/json");
  },

  unfollow: async (artistId: string) => {
    const url = `${NEXT_PUBLIC_API_URL}/user/${artistId}/follow`;
    return fetchWrapperProtected.delete(url);
  },

  signArtistTermsConditions: async (userId: string) => {
    const url = `${NEXT_PUBLIC_API_URL}/user/${userId}`;
    return fetchWrapperProtected.put(
      url,
      {
        signed_artist_tc: true,
      },
      "application/json"
    );
  },

  getFollowers: async (
    id: string,
    params: IQueryParams = {}
  ): Promise<IApiPage<IUserFollow>> => {
    const url = createSequalizeUrl(`user/${id}/follow`, params);
    const response = await fetchWrapper.get(url);
    return response.data;
  },

  getFollowing: async (
    id: string,
    params: IQueryParams = {}
  ): Promise<IApiPage<IUserFollow>> => {
    const url = createSequalizeUrl(`user/${id}/follow`, {
      ...params,
      where: {
        follower_id: id,
      },
    });
    const response = await fetchWrapper.get(url);
    return response.data;
  },
  resendVerificationEmail: async (userId: string) =>
    fetchWrapperProtected.get(`${NEXT_PUBLIC_API_URL}/user/${userId}/verify`),

  createSiweSession: async (message: ISiweMessage): Promise<IApiResponse> => {
    const url = `${NEXT_PUBLIC_API_URL}/session`;

    const response = await fetchWrapper.post(url, {
      message,
    });

    return response;
  },
  getEthSessionNonce: async (address: string): Promise<string> => {
    const result = await fetchWrapper.post(`${NEXT_PUBLIC_API_URL}/eth/nonce`, {
      address,
    });
    return result.data.nonce;
  },
};
