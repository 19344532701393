import { createGlobalStyle } from "styled-components";
import overlayStyles from "../common/components/Overlay/Overlay.styled";
import { zIndex } from "../constants/zIndex";

export const GlobalStyle = createGlobalStyle`
body, html {
  height: 100%;
}

body {
  background-color: ${({ theme }) => theme.secondaryColor};
  font-family: 'Roboto', sans-serif;
}

button, input[type="submit"] {
  border:none;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.web-transition {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.web-transition-mid {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-container {
  max-width: 1150px;
  padding: 0 20px 0 20px;
}

.bn-onboard-custom.bn-onboard-modal { z-index: ${zIndex.walletSelect}; }

.capitalize {
  text-transform: capitalize;
}

${overlayStyles};
`;
