import { settings } from "../settings";

export const IMAGE_SIZES = {
  trackCoverArt: {
    xs: 100,
    small: 150,
    mediumSmall: 200,
    medium: 450,
    big: 650,
  },
  trackPoster: {
    medium: settings.TRACK_POSTER.WIDTH,
  },
  userProfilePicture: {
    small: 100,
    medium: 150,
  },
  userAboutPhoto: {
    medium: settings.USER_ABOUT_PICTURE.WIDTH,
  },
  userCoverPhoto: {
    big: settings.USER_COVER_PICTURE.WIDTH,
  },
};

export const TRACK_COVER_ART_SIZES = Object.values(IMAGE_SIZES.trackCoverArt);
export const TRACK_POSTER_ART_SIZES = Object.values(IMAGE_SIZES.trackPoster);
export const USER_PROFILE_PICTURE_SIZES = Object.values(
  IMAGE_SIZES.userProfilePicture
);
export const USER_ABOUT_PICTURE_SIZES = Object.values(
  IMAGE_SIZES.userAboutPhoto
);
export const USER_COVER_PICTURE_SIZES = Object.values(
  IMAGE_SIZES.userCoverPhoto
);
