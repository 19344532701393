import React, { useMemo, CSSProperties } from "react";
import ReactSelect, { Props } from "react-select";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "../../commonTypes";

export interface SelectProps extends Omit<Props, "value"> {
  value: any;
  options: ISelectOption[];
  height?: string;
}

const Select = ({
  value,
  isMulti,
  options,
  height = "44px",
  ...props
}: SelectProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const customStyles = {
    control: (provided: CSSProperties, state: any) => ({
      ...provided,
      cursor: "pointer",
      height: height,
      minHeight: height,
      width: "100%",
      background: theme.inputBackgroundColor,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "6px",
      borderColor: state?.isFocused
        ? theme.primaryColor
        : theme.inputBorderColor,
      opacity: 0.8,
      paddingLeft: "15px",
      boxSizing: "border-box",
      boxShadow: "none",

      ":hover": {
        borderColor: theme.primaryColor,
      },
    }),
    valueContainer: (provided: CSSProperties) => ({
      ...provided,
      padding: 0,
    }),
    input: () => ({
      fontSize: theme.paragraph.lg,
      lineHeight: "18px",
      color: theme.primaryColor,
    }),
    singleValue: () => ({
      fontSize: theme.paragraph.lg,
      lineHeight: "18px",
      color: theme.primaryColor,
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    indicatorsContainer: () => ({
      height: "100%",
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
    }),
    dropdownIndicator: (provided: CSSProperties) => ({
      ...provided,
      color: theme.primaryColor,
    }),
    clearIndicator: (provided: CSSProperties) => ({
      ...provided,
      color: theme.primaryColor,
    }),
    menu: (provided: CSSProperties) => ({
      ...provided,
      color: theme.primaryColor,
      background: theme.lightBackgroundColor,
      borderRadius: theme.borderRadius.md,
    }),
    menuList: (provided: CSSProperties) => ({
      ...provided,
      padding: "16px 0",
    }),
    option: (provided: CSSProperties, state: any) => ({
      ...provided,
      cursor: "pointer",
      backgroundColor: state.isSelected
        ? theme.lightBackgroundColorHover
        : state.isFocused
        ? theme.backgroundColor
        : theme.lightBackgroundColor,
      fontSize: theme.paragraph.lg,
      lineHeight: "18px",
      color: theme.primaryColor,
      paddingTop: "10px",
      paddingBottom: "10px",
      opacity: 0.7,

      ":active": {
        borderColor: theme.backgroundColor,
      },
    }),
    placeholder: (provided: CSSProperties) => {
      return {
        ...provided,
        color: theme.primaryColor,
        fontSize: theme.paragraph.md,
        opacity: 0.5,
        lineHeight: "16px",
      };
    },
  };

  const selectValue = useMemo(() => {
    if (!value) {
      // pass null to always make Select component controlled
      return null;
    }

    if (isMulti && Array.isArray(value)) {
      return options.filter((option) => value.includes(option.value));
    }

    return options.find((option) => option.value === value) || null;
  }, [value, isMulti]);

  return (
    <ReactSelect
      // @ts-ignore
      styles={customStyles}
      noOptionsMessage={() => t("no-results")}
      isClearable
      blurInputOnSelect
      value={selectValue}
      isMulti={isMulti}
      options={options}
      {...props}
    />
  );
};

export default Select;
