import { useWeb3 } from "@chainsafe/web3-context";
import { Network } from "@ethersproject/networks";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useContracts } from "../../context/Contracts";
import { useWeb3Query } from "../../queries/web3.query";
import { IChain, IDeployment } from "../commonTypes";

// this returns {chain, network, deployment, stakingContract} based on the currently selected network on the provider
const useChain = () => {
  const [chain, setChain] = useState<IChain | undefined>();
  const [deployment, setDeployment] = useState<IDeployment | undefined>();
  const [network, setNetwork] = useState<Network | undefined>();
  const [stakingContract, setStakingContract] = useState<
    ethers.Contract | undefined
  >();

  const { data: deployments } = useWeb3Query();
  const { provider, network: localNetwork } = useWeb3();
  const { stakingContracts } = useContracts();

  useEffect(() => {
    if (localNetwork && deployments) {
      // order by version desc
      const deployment = deployments
        .sort((a, b) => b.version - a.version)
        .find((deployment) => deployment.chain.id === localNetwork);

      if (deployment) {
        setChain(deployment.chain);
        setDeployment(deployment);
        setStakingContract(stakingContracts[deployment.id]);
      }
    }
  }, [localNetwork, deployments]);

  useEffect(() => {
    async function getNetwork() {
      setNetwork(await provider?.getNetwork());
    }

    if (provider) {
      getNetwork();
    }
  }, [provider]);

  return {
    chain,
    network,
    deployment,
    stakingContract,
  };
};

export default useChain;
