import { useRouter } from "next/router";
import { useInfiniteQuery, useQuery } from "react-query";
import { QueryKeys } from "../common/queryKeys";
import { usersService } from "../api/usersService";
import { routes } from "../routes";
import {
  IApiPage,
  IApiResponse,
  IMusic,
  INft,
  IUserProfile,
} from "../common/commonTypes";
import { musicService } from "../api/musicService";
import { nftService } from "../api/nftService";
import { useContracts } from "../context/Contracts";
import { nextPageParamGetter } from "../helpers/paginationHelper";
import { useSession } from "./authentication.query";

const USER_MUSIC_PAGE_SIZE = 10;
const QUICK_VIEW_THUMBNAILS_COUNT = 3;

export const useUserProfileQuery = (userUrl: string) => {
  const router = useRouter();

  return useQuery<IUserProfile, IApiResponse>(
    [QueryKeys.userProfile, userUrl],
    () => usersService.fetchUserByUrl(userUrl),
    {
      onError: (error) => {
        if (error.status && [400, 404].includes(error.status)) {
          router.replace(routes.page404);
          return;
        }
      },
    }
  );
};

export const useUserMusicQuery = (userId: string, isOwnProfile: boolean) => {
  const { isFetched } = useSession();

  const musicFilter: { [prop: string]: any } = {
    artist_id: userId,
  };

  if (!isOwnProfile) {
    musicFilter.hidden = false;
  }

  return useInfiniteQuery(
    [QueryKeys.userMusic, musicFilter],
    ({ pageParam }) =>
      musicService.fetchMusicListingsForArtist({
        offset: pageParam || 0,
        limit: USER_MUSIC_PAGE_SIZE,
        where: musicFilter,
        order: [["created", "DESC"]],
      }),
    {
      getNextPageParam: nextPageParamGetter(USER_MUSIC_PAGE_SIZE),
      enabled: isFetched,
    }
  );
};

export const useUserCollectionQuery = (userId: string) => {
  const { nftContracts } = useContracts();
  const collectionFilter = {
    user_id: userId,
  };

  return useInfiniteQuery(
    [QueryKeys.userCollection, collectionFilter],
    async ({ pageParam }): Promise<IApiPage<INft>> => {
      // ask the API which listings this user has purchased
      const response = await nftService.fetchNfts({
        offset: pageParam || 0,
        limit: USER_MUSIC_PAGE_SIZE,
        where: collectionFilter,
      });
      const nftsPage = response.data;

      // query each nft contract to compare listing.buyer_address
      // with the on-chain owner.
      // this should remove nfts that have been transferred since purchase
      const onChainResults = await Promise.all(
        Object.values(nftContracts).map(async (nftContract) => {
          const network = await nftContract.provider.getNetwork();
          // only consider nfts that were minted on this contract
          const nftsForThisContract = nftsPage.items.filter(
            (nft) =>
              nft.listing.deployment.chain.id == network.chainId &&
              nft.metadata.contract.toLowerCase() ===
                nftContract.address.toLowerCase()
          );

          return nftService.getOnChainNfts(nftContract, nftsForThisContract);
        })
      );

      const onChainNfts = ([] as any).concat(...onChainResults);

      return {
        count: nftsPage.count,
        items: onChainNfts,
        // Save number of items returned from original request, before filtering it on contract
        pageCount: nftsPage.items.length,
      };
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pageCount === USER_MUSIC_PAGE_SIZE) {
          return pages.length * USER_MUSIC_PAGE_SIZE;
        }
      },
      enabled: nftContracts && Object.values(nftContracts).length > 0,
    }
  );
};

export const useUserQuickViewQuery = (user: IUserProfile) => {
  const { nftContracts } = useContracts();

  return useQuery<IMusic[]>(
    [QueryKeys.userQuickView, user.id, user.approved_artist],
    async () => {
      if (user.approved_artist) {
        const result = await musicService.fetchMusics({
          limit: QUICK_VIEW_THUMBNAILS_COUNT,
          where: { artist_id: user.id, hidden: false },
        });

        return result.data.items;
      }

      const nftResponse = await nftService.fetchNfts({
        limit: QUICK_VIEW_THUMBNAILS_COUNT,
        where: {
          user_id: user.id,
        },
      });

      const onChainResults = await Promise.all(
        Object.values(nftContracts).map((nftContract) => {
          return nftService.getOnChainNfts(nftContract, nftResponse.data.items);
        })
      );

      const onChainNfts = ([] as INft[]).concat(...onChainResults);

      return onChainNfts.map((nft) => nft.music);
    },
    { enabled: nftContracts && Object.values(nftContracts).length > 0 }
  );
};
