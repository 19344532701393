import { IAsset, ISelectOption } from "../../../commonTypes";
import Select from "../../Select/Select";

interface Props {
  onTokenSelected: (selectedOption: { label: string; value: IAsset }) => void;
  tokens: IAsset[];
  selectedToken?: IAsset;
}

const AssetSelector = ({
  onTokenSelected,
  tokens = [],
  selectedToken,
}: Props) => {
  const options: ISelectOption<IAsset>[] = tokens.map((token) => {
    return {
      label: token.name,
      value: token,
    };
  });

  return (
    <Select
      options={options}
      onChange={onTokenSelected}
      value={selectedToken}
      menuPosition="fixed"
    />
  );
};

export default AssetSelector;
