import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ErrorBoundaryProps } from "@sentry/react/dist/errorboundary";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import styled from "styled-components";
import ErrorPage from "./Error";

const Root = styled.div`
  padding: 32px 0;
`;

const ErrorBoundary = ({
  children,
  ...props
}: { children: ReactNode } & ErrorBoundaryProps) => {
  const { t } = useTranslation();
  return (
    <SentryErrorBoundary
      fallback={
        <Root>
          <ErrorPage />
        </Root>
      }
      {...props}
    >
      {children}
    </SentryErrorBoundary>
  );
};

export default ErrorBoundary;
