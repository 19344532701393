import React from "react";
import styled from "styled-components";
import FadeImage, { IFadeImageProps } from "../FadeImage/FadeImage";
import { getAbsoluteFill } from "../../styles";

const Root = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: inherit;
`;

const Wrapper = styled.div<{ ratio: number }>`
  position: relative;
  width: 100%;
  padding-top: ${({ ratio }) => Math.round(100 / ratio)}%;
  border-radius: inherit;
`;

const Image = styled(FadeImage)`
  ${getAbsoluteFill};
  width: 100%;
  height: 100%;
  border-radius: inherit;
`;

interface IProps extends IFadeImageProps {
  ratio?: number;
  className?: string;
}

const RatioImage = ({ ratio = 1, className, ...imageProps }: IProps) => {
  return (
    <Root className={className}>
      <Wrapper ratio={ratio}>
        <Image {...imageProps} />
      </Wrapper>
    </Root>
  );
};

export default RatioImage;
