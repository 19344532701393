import React, { SyntheticEvent } from "react";
import { useRouter } from "next/router";
import Avatar, { IAvatarProps } from "../Avatar";
import Tooltip from "../Tooltip/Tooltip";
import { routes } from "../../../routes";

interface IProps extends IAvatarProps {
  address: string;
}

const AddressAvatar = ({ address, ...avatarProps }: IProps) => {
  const router = useRouter();

  const onAvatarClick = (event: SyntheticEvent<HTMLDivElement>) => {
    event.stopPropagation();
    router.push(routes.address(address));
  };

  return (
    <Tooltip text={address}>
      <Avatar onClick={onAvatarClick} {...avatarProps} />
    </Tooltip>
  );
};

export default AddressAvatar;
