import styled, { css } from "styled-components";
import {
  getBasePoppinsTypography,
  getBaseTypography,
  getBoldTypography,
} from "./helpers";

export const getHeading4BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.md};
  line-height: 33px;
`;

export const getHeading4PoppinsBoldTypography = css`
  ${getBasePoppinsTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.md};
  line-height: 33px;
`;

export const Heading4Bold = styled.h4`
  ${getHeading4BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Heading4PoppinsBold = styled.h4`
  ${getHeading4PoppinsBoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;
