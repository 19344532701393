export enum MimeEnum {
  mp3 = "audio/mpeg",
  mp4 = "video/mp4",
  wav = "audio/wav",
  xWav = "audio/x-wav",
  gif = "image/gif",
  jpeg = "image/jpeg",
  png = "image/png",
  pdf = "application/pdf",
}

export const WavFileFormats = [MimeEnum.wav, MimeEnum.xWav];
export const AudioFileFormats = [...WavFileFormats, MimeEnum.mp3, MimeEnum.mp4];
export const StaticImageFileFormats = [MimeEnum.jpeg, MimeEnum.png];
export const ImageFileFormats = [...StaticImageFileFormats, MimeEnum.gif];
export const VideoFileFormats = [MimeEnum.mp4];

export enum BidTypeEnum {
  bid = "bid",
  fixed = "fixed",
  other = "other",
}

export enum ListingStatusEnum {
  active = "active",
  sold = "sold",
  minted = "minted",
  ended = "ended",
  cancelled = "cancelled",
  failed = "failed",
  minting = "minting",
}

export enum UserTypeEnum {
  ADMIN = "admin",
  USER = "user",
}

export enum MusicEnum {
  SINGLE = "single",
  ALBUM = "album",
}

export enum MusicFileTypeEnum {
  master = "master",
  full = "full",
  vocal = "vocal",
  backing = "backing",
  video = "video",
}

export enum TwitterCardType {
  player = "player",
  summary = "summary",
  summaryLarge = "summary_large_image",
}

export enum WebsocketMessageType {
  listingStatusChanged = "LISTING_STATUS_CHANGED",
}

export enum CustomThemeColor {
  background = "background",
  font = "font",
  action = "action",
}
