import { IMusic } from "../common/commonTypes";

export const getPreviewSrc = (music?: IMusic): string | undefined =>
  // @ts-ignore - use snippet for backward compatibility for users which have cached playlist with tracks without "full"
  music?.full?.url || music?.snippet?.url || undefined;

export const preloadTrack = (track: IMusic): void => {
  const src = getPreviewSrc(track);

  if (src) {
    const audio = new Audio();
    audio.preload = "auto";
    audio.src = src;
  }
};
