import React, { ReactNode } from "react";
import styled from "styled-components";
import { IMusic } from "../../commonTypes";
import { getAbsoluteFill, getFlexCenter } from "../../styles";
import Icon from "../Icon/Icon";
import Cover from "../Cover";

const Root = styled.div`
  position: relative;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  cursor: pointer;
  overflow: hidden;
  background-color: ${({ theme }) => theme.lightBackgroundColor};
`;

const BigPlayButton = styled.button`
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  margin: 0 auto;
  cursor: pointer;
  height: 54px;
  width: 54px;
  filter: drop-shadow(0px 10px 30px rgba(0, 0, 0, 0.5));
  ${getFlexCenter};
`;

const ControlIcon = styled(Icon)`
  color: ${({ theme }) => theme.lightBackgroundColor};
  transform: translateY(1px);
`;

const Content = styled.div<{ $isPlaying: boolean }>`
  ${getAbsoluteFill};
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px 0 30px;
  // fixes iOS issue with absolute content flickering above the video while scrolling
  // https://muffinman.io/blog/ios-safari-scroll-position-fixed/
  -webkit-transform: translate3d(0, 0, 0);

  @media (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    ${BigPlayButton} {
      display: ${({ $isPlaying }) => ($isPlaying ? "block" : "none")};
    }

    &:hover {
      ${BigPlayButton} {
        display: block;
      }
    }
  }
`;

interface IProps {
  isPlaying: boolean;
  togglePlay: () => void;
  music?: IMusic;
  coverResizeWidth?: number;
  className?: string;
  children?: ReactNode;
}

const PlayerCard = ({
  music,
  isPlaying,
  togglePlay,
  coverResizeWidth,
  className,
  children,
}: IProps) => {
  const coverUrl = music?.cover?.url;

  return (
    <Root onClick={togglePlay} className={className}>
      {coverUrl && (
        <Cover
          size={coverResizeWidth}
          mime={music?.cover?.mime}
          url={music?.cover?.url}
        />
      )}
      <Content $isPlaying={isPlaying}>
        <BigPlayButton onClick={togglePlay} type="button">
          <ControlIcon name={isPlaying ? "pause" : "play"} />
        </BigPlayButton>
        {children}
      </Content>
    </Root>
  );
};

export default PlayerCard;
