import styled, { useTheme } from "styled-components";
import { Paragraph14Regular } from "../../../Typography/Paragraph14";
import { Paragraph16Bold } from "../../../Typography/Paragraph16";
import { Dot } from "../../styled";
import MenuNav from "../MenuNav";
import {
  Accounts,
  AccountBadge,
  AccountBadgeLeft,
  BalanceLabel,
  StyledAccount as Account,
} from "../Account/Account";
import { useEffect, useState } from "react";
import { useStaking } from "../../../hooks/useStaking";
import Button from "../../Buttons/Button";
import { IAsset, IDeployment, IStyleableProps } from "../../../commonTypes";
import InProgress from "./InProgress";
import { useTranslation } from "react-i18next";
import AmountInput from "../../AmountInput";

import { useWeb3 } from "@chainsafe/web3-context";
import { getBlockExplorerTransactionUrl } from "../../../../helpers/transactions";
import Typography from "../../Typography/Typography";
import useChain from "../../../hooks/useChain";
import {
  getFormattedAmount,
  getFormattedAmountWithLabel,
  isERC20,
  isEther,
} from "../../../../helpers/format";
import { useStakedQuery } from "../../../../queries/staking.query";
import AssetSelector from "./AssetSelector";
import { ethers } from "ethers";
import { useAssetSelector } from "./useAssetSelector";

const Wrapper = styled.div`
  height: fit-content;
  margin-bottom: 12px;
`;

const Amount = styled.div`
  margin-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid ${(props) => props.theme.inputBorderColor};
  margin-bottom: 24px;

  input {
    background: none;
  }
`;

export const AmountLabel = styled(Paragraph14Regular)`
  margin-bottom: 6px;
`;

export const AccountLabel = styled(Paragraph14Regular)`
  text-transform: capitalize;
  margin-top: 24px;
  margin-bottom: 6px;
`;

interface Props {
  handleDepositStakeMenuClose: () => void;
  onContinueClicked: () => void;
  hideButtons?: boolean;
  asset?: IAsset;
  deployment?: IDeployment;
}

const DepositStake = ({
  handleDepositStakeMenuClose,
  onContinueClicked,
  hideButtons,
  asset: _asset,
  deployment,
  ...props
}: Props & IStyleableProps) => {
  const { showAssetSelector, selectedAsset, setSelectedAsset, assets } =
    useAssetSelector(_asset);
  const theme = useTheme();
  const {
    stake,
    isSubmitting,
    error,
    lastTxHash,
    success,
    approveToken,
    isApproving,
  } = useStaking();
  const { address } = useWeb3();
  const [depositAmount, setDepositAmount] = useState("1");
  const { t } = useTranslation();
  const { chain, stakingContract: contract } = useChain();
  const { data: stakedResult } = useStakedQuery(
    contract,
    address!,
    selectedAsset,
    deployment
  );
  const [isAmountApproved, setIsAmountApproved] = useState(false);

  const handleDepositClicked = async () => {
    stake(depositAmount, selectedAsset!, deployment!);
  };

  const handleApproveClicked = async () => {
    approveToken(selectedAsset!, depositAmount);
  };

  const handleTokenSelected = (selection: { label: string; value: IAsset }) => {
    setSelectedAsset(selection.value);
  };

  const amount = Number(depositAmount) ?? "0";
  const depositAmountWei = ethers.utils.parseUnits(
    amount.toString(),
    selectedAsset?.decimals
  );

  useEffect(() => {
    const hasApproval = Boolean(stakedResult?.allowance.gte(depositAmountWei));
    setIsAmountApproved(hasApproval);
  }, [stakedResult?.allowance, depositAmount]);

  const isDisabled = stakedResult?.balance?.lt(depositAmountWei);

  return (
    <Wrapper {...props}>
      <MenuNav
        title={t("deposit")}
        onBackClicked={handleDepositStakeMenuClose}
      />

      {!isSubmitting && (
        <div>
          {chain && (
            <Typography size="lg" weight="bold">
              {`${t("deposit-to")} ${chain.name}`}
            </Typography>
          )}
          {/* show asset selector when no asset is specified in the props */}
          {!_asset && showAssetSelector && (
            <div>
              <Typography>Select token to deposit</Typography>
              <AssetSelector
                onTokenSelected={handleTokenSelected}
                tokens={assets!}
                selectedToken={selectedAsset}
              />
            </div>
          )}
          {selectedAsset && (
            <div>
              <Accounts>
                <Account>
                  <AccountLabel>{t("from")}</AccountLabel>
                  <AccountBadge className="account-badge">
                    <AccountBadgeLeft>
                      <Dot color={theme.greenColor} />
                      <Paragraph16Bold>{t("stake-available")}</Paragraph16Bold>
                    </AccountBadgeLeft>
                    <BalanceLabel
                      onClick={() => {
                        const formatted = ethers.utils.formatUnits(
                          stakedResult?.balance.toString() ?? "0",
                          selectedAsset.decimals
                        );

                        setDepositAmount(formatted.toString());
                      }}
                    >
                      {getFormattedAmountWithLabel(
                        stakedResult?.balance?.toString(),
                        selectedAsset
                      )}
                    </BalanceLabel>
                  </AccountBadge>
                </Account>
                <Account>
                  <AccountLabel> {t("to")}</AccountLabel>
                  <AccountBadge className="account-badge">
                    <AccountBadgeLeft>
                      <Dot color={theme.accent.normal} />
                      <Paragraph16Bold>{t("stake")}</Paragraph16Bold>
                    </AccountBadgeLeft>
                    <BalanceLabel
                      onClick={() => {
                        setDepositAmount(
                          getFormattedAmount(
                            stakedResult?.stake?.toString() ?? "0",
                            selectedAsset
                          )
                        );
                      }}
                    >
                      {getFormattedAmountWithLabel(
                        stakedResult?.stake?.toString(),
                        selectedAsset
                      )}
                    </BalanceLabel>
                  </AccountBadge>
                </Account>
              </Accounts>
              <Amount className="amount">
                <AmountLabel>{t("type-amount")}</AmountLabel>
                <AmountInput
                  amount={depositAmount}
                  label={selectedAsset?.symbol}
                  onChange={(e) => setDepositAmount(e.target.value)}
                />
              </Amount>
              {isERC20(selectedAsset) && (
                <div>
                  {isAmountApproved ? (
                    <div>
                      <Button
                        text={t("Confirm")}
                        fullWidth
                        onClick={handleDepositClicked}
                        disabled={isDisabled}
                      />
                    </div>
                  ) : (
                    <div>
                      <Button
                        text={t("approve-token", {
                          symbol: selectedAsset.symbol,
                        })}
                        fullWidth
                        onClick={handleApproveClicked}
                        isLoading={isApproving}
                      />
                    </div>
                  )}
                </div>
              )}
              {isEther(selectedAsset) && (
                <Button
                  text={t("Confirm")}
                  fullWidth
                  onClick={handleDepositClicked}
                />
              )}
            </div>
          )}
        </div>
      )}
      {isSubmitting && (
        <InProgress
          onContinueClick={onContinueClicked}
          error={error}
          hash={lastTxHash}
          blockNumber={success?.blockNumber}
          redirectUrl={getBlockExplorerTransactionUrl(chain!, lastTxHash!)}
          hideButtons={hideButtons}
        />
      )}
    </Wrapper>
  );
};

export default DepositStake;
