import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";
import { useSearch } from "../../../context/SearchContext";
import { getFlexCenter } from "../../styles";
import { getParagraph16RegularTypography } from "../../Typography/Paragraph16";

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Wrapper = styled.div<{ isActive: boolean }>`
  position: relative;
  height: 40px;
  width: ${({ isActive }) => (isActive ? "100%" : "230px")};
  padding: 0 10px;
  margin-right: 30px;
  transition: width ease-in 150ms;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.darkBorderColor};
  border: 1px solid transparent;
  border-radius: 6px;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptopL}) {
    margin-right: 0;
    display: ${({ isActive }) => (isActive ? "flex" : "none")};
  }
`;

const SearchInput = styled.input`
  flex: 1;
  height: 100%;
  background: transparent;
  border: none;
  ${getParagraph16RegularTypography};
  line-height: 18px;
  color: ${({ theme }) => theme.primaryColor};
  opacity: 0.8;
  box-sizing: border-box;
  outline: none;

  && {
    ::placeholder {
      color: ${({ theme }) => theme.primaryColor};
      opacity: 0.5;
      font-size: ${({ theme }) => theme.paragraph.lg};
    }
  }
`;

const IconWrapper = styled.div`
  height: 100%;
  ${getFlexCenter};
  margin-right: 12px;
`;

const SearchIcon = styled(Icon)`
  opacity: 0.5;
`;

const CancelIcon = styled(Icon)`
  opacity: 0.5;
  cursor: pointer;
`;

const MainSearch = () => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const {
    searchPhrase,
    setSearchPhrase,
    submitSearchValue,
    isSearchFocused,
    setIsSearchFocused,
    closeSearch,
    isSearchExpanded,
  } = useSearch();

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchPhrase(event.currentTarget.value);
  };

  const onFocus = () => {
    setIsSearchFocused(true);
  };

  const onBlur = () => {
    setIsSearchFocused(false);
  };

  const cancel = () => {
    closeSearch();
  };

  const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitSearchValue();
  };

  useEffect(() => {
    if (isSearchFocused) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isSearchFocused]);

  return (
    <StyledForm onSubmit={onSubmit}>
      <Wrapper isActive={isSearchExpanded}>
        <IconWrapper>
          {isSearchExpanded ? (
            <CancelIcon name="arrowLeft" onClick={cancel} />
          ) : (
            <SearchIcon name="search" size="20px" />
          )}
        </IconWrapper>
        <SearchInput
          ref={inputRef}
          value={searchPhrase}
          onChange={onInputChange}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={t("search")}
        />
      </Wrapper>
    </StyledForm>
  );
};

export default MainSearch;
