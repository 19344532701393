import { useWeb3 } from "@chainsafe/web3-context";
import { providers } from "ethers";
import { accountService } from "../../api/accountService";
import { ISiweMessageParams } from "../commonTypes";

interface IReturnValue {
  connectWallet: (forceSelection?: boolean) => Promise<string>;
  connectWalletSafe: (forceSelection?: boolean) => Promise<string | void>;
  getSimpleSignature: (address: string) => Promise<string>;
  getSiweMessageSignature: (message: ISiweMessageParams) => Promise<string>;
  getSiweMessageParams: () => Promise<ISiweMessageParams>;
}

export const useWalletHelpers = (): IReturnValue => {
  const { onboard, address: currentAddress, signMessage } = useWeb3();

  const connectWallet = async (
    forceSelection: boolean = false
  ): Promise<string> => {
    const isConnected = !!currentAddress;
    const isSelected =
      (!forceSelection && isConnected) || (await onboard?.walletSelect());

    if (isSelected) {
      const isReady = await onboard?.walletCheck();
      const address = onboard?.getState()?.address;

      if (isReady && address) {
        return address;
      } else {
        return Promise.reject("Wallet not ready");
      }
    } else {
      return Promise.reject("Wallet not selected");
    }
  };

  const connectWalletSafe = async (
    forceSelection: boolean = false
  ): Promise<string | void> => {
    try {
      return await connectWallet(forceSelection);
    } catch (error) {}
  };

  const getSiweMessageParams = async (): Promise<ISiweMessageParams> => {
    const currentAddress = onboard!.getState().address;
    const nonce = await accountService.getEthSessionNonce(currentAddress);
    const time = new Date().toISOString();
    const domain = "noizd.com";
    const uri = "https://noizd.com/login";
    const version = "1";
    const chainId = onboard!.getState().network.toString();

    return {
      nonce,
      address: currentAddress,
      issuedAt: time,
      domain,
      uri,
      version,
      chainId,
    };
  };

  const getSiweMessageSignature = async (
    message: ISiweMessageParams
  ): Promise<string> => {
    const {
      address,
      nonce,
      issuedAt: time,
      domain,
      uri,
      version,
      chainId: network,
    } = message;
    // Note: message is whitespace sensitive
    const payload = `${domain} wants you to sign in with your Ethereum account:
${address}

URI: ${uri}
Version: ${version}
Chain ID: ${network}
Nonce: ${nonce}
Issued At: ${time}`;

    const provider = new providers.Web3Provider(
      onboard!.getState().wallet.provider
    );
    const signer = provider.getSigner();
    return signer.signMessage(payload);
  };

  const getSimpleSignature = async (address: string): Promise<string> => {
    const signature = await signMessage(`I own this address: ${address}`);
    return signature;
  };

  return {
    connectWallet,
    connectWalletSafe,
    getSiweMessageSignature,
    getSiweMessageParams,
    getSimpleSignature,
  };
};
