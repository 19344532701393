import { SyntheticEvent, useRef, useState, RefObject } from "react";
import { useDebouncedCallback } from "use-debounce";

export const useAudioPlayer = (
  audioPlayer: RefObject<HTMLAudioElement | null>
) => {
  const [volume, setVolume] = useState(1);
  const lastSetVolume = useRef(volume);
  const [duration, setDuration] = useState(0);
  const [playProgress, setPlayProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isBuffering, setIsBuffering] = useState(false);

  const play = () => {
    audioPlayer.current?.play();
    setIsPlaying(true);
  };

  const pause = () => {
    audioPlayer.current?.pause();
    setIsPlaying(false);
  };

  const togglePlay = () => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  };

  const onTimeUpdate = (event: SyntheticEvent<HTMLMediaElement>) => {
    setPlayProgress(event.currentTarget.currentTime);
  };

  const onProgressChange = (value: number) => {
    if (audioPlayer.current) {
      audioPlayer.current.currentTime = value;
    }
  };

  const onVolumeChange = (value: number) => {
    if (audioPlayer.current) {
      setVolume(value);
      audioPlayer.current.volume = value;
    }
  };

  const toggleMute = () => {
    if (volume > 0) {
      lastSetVolume.current = volume;
      onVolumeChange(0);
    } else {
      onVolumeChange(lastSetVolume.current || 0.5);
    }
  };

  const onEnded = () => {
    setPlayProgress(0);
    setIsPlaying(false);
  };

  const onDurationChange = (event: SyntheticEvent<HTMLAudioElement>) => {
    setDuration(event.currentTarget.duration);
  };

  const onWaiting = useDebouncedCallback(() => setIsBuffering(true), 300);

  const onPlaying = () => {
    setIsBuffering(false);
    onWaiting.cancel();
  };

  const stopPlayer = () => {
    if (audioPlayer.current && !audioPlayer.current.ended) {
      pause();
      audioPlayer.current.currentTime = 0;
    }
  };

  return {
    volume,
    setVolume,
    duration,
    setDuration,
    playProgress,
    setPlayProgress,
    isPlaying,
    setIsPlaying,
    play,
    pause,
    togglePlay,
    onTimeUpdate,
    onProgressChange,
    onVolumeChange,
    toggleMute,
    onEnded,
    onDurationChange,
    onWaiting,
    onPlaying,
    stopPlayer,
    isBuffering,
  };
};
