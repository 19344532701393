import { captureException } from "@sentry/nextjs";
import { getAddress } from "@ethersproject/address/lib/index.js";
import { IListing, INft } from "../common/commonTypes";
import { getCurrentOwner } from "../helpers/listingHelper";
import { useContractsForDeployment } from "../context/Contracts";
import { ListingStatusEnum } from "../common/commonEnums";
import { nftService } from "../api/nftService";
import { useQuery } from "react-query";
import { QueryKeys } from "../common/queryKeys";

interface IOwnershipInfo {
  isConfirmedOwner: boolean;
  ownerAddress: string;
}

export const useListingOwnerQuery = (listing?: IListing, nft?: INft) => {
  const { nftContract } = useContractsForDeployment(listing?.deployment);

  const dbOwner = listing && getCurrentOwner(listing.music, listing);

  const { data: ownershipInfo, isFetched } = useQuery(
    [QueryKeys.listingOwner, listing?.id],
    async (): Promise<IOwnershipInfo | undefined> => {
      try {
        const listingNft =
          nft || (await nftService.fetchNftForListing(listing!.id));

        if (listingNft?.metadata?.id && dbOwner) {
          const onchainOwnerAddress = await nftContract?.ownerOf(
            listingNft.metadata.id
          );

          if (onchainOwnerAddress) {
            return {
              ownerAddress: onchainOwnerAddress,
              isConfirmedOwner:
                getAddress(onchainOwnerAddress) ===
                getAddress(dbOwner!.address!),
            };
          }
        }
      } catch (error) {
        captureException(error);
      }
    },
    {
      enabled: listing?.status === ListingStatusEnum.minted && !!nftContract,
      staleTime: 10 * 60 * 1000,
    }
  );

  const isLoaded = listing?.status !== ListingStatusEnum.minted || isFetched;
  const ownerAddress = ownershipInfo?.ownerAddress || "";
  // assuming that owner from db is the correct one until we verify it on contract
  const isConfirmedOwner = ownershipInfo
    ? ownershipInfo.isConfirmedOwner
    : true;

  return {
    owner: dbOwner,
    isLoaded,
    ownerAddress,
    isConfirmedOwner,
  };
};
