import { IDeployment, ISelectOption } from "../../../commonTypes";
import Select from "../../Select/Select";

interface Props {
  versions: IDeployment[];
  selectedVersion?: IDeployment;
  onVersionSelected: (selection: { label: string; value: IDeployment }) => void;
}

const VersionSelector = ({
  onVersionSelected,
  versions = [],
  selectedVersion,
}: Props) => {
  const options: ISelectOption<IDeployment>[] = versions.map((deployment) => {
    return {
      label: deployment.name,
      value: deployment,
    };
  });

  return (
    <Select
      options={options}
      onChange={onVersionSelected}
      value={selectedVersion}
    />
  );
};

export default VersionSelector;
