import { IApiResponse } from "../common/commonTypes";
import { parseJson } from "./requestHelper";
import { captureException } from "@sentry/nextjs";
import { sessionService } from "../services/sessionService";

const { NEXT_PUBLIC_API_URL } = process.env;

export const fetchWrapperProtected = {
  get,
  post,
  put,
  delete: _delete,
};

function get(url: string) {
  const requestOptions: any = {
    method: "GET",
    headers: authHeader(url),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function post(
  url: string,
  body: any,
  contentType: string = "application/json"
) {
  const requestOptions: any = {
    method: "POST",
    headers: { "Content-Type": contentType, ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function put(url: string, body: any, contentType: string = "application/json") {
  const requestOptions: any = {
    method: "PUT",
    headers: { "Content-Type": contentType, ...authHeader(url) },
    body: JSON.stringify(body),
  };
  return fetch(url, requestOptions).then(handleResponse);
}

function _delete(url: string) {
  const requestOptions: any = {
    method: "DELETE",
    headers: authHeader(url),
  };

  return fetch(url, requestOptions).then(handleResponse);
}

function authHeader(url: string) {
  const isApiUrl = url.startsWith(NEXT_PUBLIC_API_URL!);
  const sessionId = sessionService.getSessionId();
  if (sessionId && isApiUrl) {
    return { session: sessionId };
  } else {
    return {};
  }
}

function handleResponse(response: any) {
  return response.text().then((text: string) => {
    const data = text && parseJson(text);
    let resp: IApiResponse = { data: data };

    if (!response.ok) {
      captureException(data);
      switch (response.status) {
        case 400:
          resp = {
            errorMsg: "Bad request",
            status: 400,
            message: data.message,
          };

          break;

        case 401:
          sessionService.handleSessionExpired();
          resp = { errorMsg: "Unauthorized", status: 401 };
          break;

        case 403:
          sessionService.handleSessionExpired();
          resp = { errorMsg: "Forbidden", status: 403 };
          break;

        case 404:
          resp = { errorMsg: "Not found", status: 404 };
          break;

        case 409:
          resp = { errorMsg: "Conflict in current state", status: 409 };
          break;

        case 500:
          resp = {
            errorMsg: "Internal error",
            status: 500,
            message: data.message,
          };
          break;

        default:
          const error = (data && data.message) || response.statusText;
          resp = { errorMsg: error, status: response.status };
          break;
      }

      return Promise.reject(resp);
    }

    return resp;
  });
}
