import { IApiResponse, IQueryParams } from "../common/commonTypes";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { createSequalizeUrl } from "../helpers/requestHelper";

export const deploymentService = {
  fetchDeployments: async (params: IQueryParams) => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("deployment", params)
    );
    return response;
  },
};
