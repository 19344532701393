import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import Icon from "../../Icon/Icon";
import {
  LinkBody,
  LinksContent,
  LinkSectionTitle,
  LinkSectionTitleContainer,
  LinksSection,
  StyledLink,
  LinksContainer,
  Divider,
} from "./shared";
import { useSession } from "../../../../queries/authentication.query";
import StaticLink from "../../StaticLink/StaticLink";

type Props = {
  isTablet: boolean;
};

const HelpLinks = ({ isTablet }: Props) => {
  const { t } = useTranslation();

  const [bodyHeight, setBodyHeight] = useState<number | null>(null);
  const isActive = bodyHeight !== null;
  const helpRef: any = useRef(null);

  const handleContactClick = () => {
    window.open("mailto:info@noizd.com");
  };

  const { isLoggedIn } = useSession();

  const handleOnAccordionClick = () => {
    if (isActive) {
      setBodyHeight(null);
    } else {
      setBodyHeight(helpRef.current.clientHeight);
    }
  };

  const Links = () => (
    <LinksContainer isTablet={isTablet}>
      <StyledLink onClick={handleContactClick}>
        {t("footer-section-link-contact")}
      </StyledLink>
      <StaticLink href={routes.faq} target="_blank" rel="noreferrer">
        <StyledLink>{t("footer-section-link-faq")}</StyledLink>
      </StaticLink>
      {!isLoggedIn && (
        <StaticLink href={routes.auth.join.root}>
          <StyledLink>{t("footer-section-link-sign-up")}</StyledLink>
        </StaticLink>
      )}
    </LinksContainer>
  );

  return (
    <>
      <Divider />
      <LinksSection>
        <LinkSectionTitleContainer
          isTablet={isTablet}
          isActive={isActive}
          onClick={handleOnAccordionClick}
        >
          <LinkSectionTitle>
            {t("footer-section-link-title-help")}
          </LinkSectionTitle>
          {isTablet && <Icon name="chevronDown" />}
        </LinkSectionTitleContainer>

        {isTablet ? (
          <LinkBody active={isActive} bodyHeight={bodyHeight}>
            <LinksContent ref={helpRef}>
              <Links />
            </LinksContent>
          </LinkBody>
        ) : (
          <Links />
        )}
      </LinksSection>
    </>
  );
};

export default HelpLinks;
