import React from "react";
import styled from "styled-components";
import Overlay, { IOverlayProps } from "../Overlay/Overlay";
import Typography from "../Typography/Typography";
import useMobileResponsive from "../../hooks/useMobileResponsive";

const TooltipPopover = styled.div`
  background: ${({ theme }) => theme.backgroundColor};
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: 6px 8px;
  max-width: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    margin: 0 5px;
  }
`;

const TooltipText = styled(Typography)`
  white-space: pre-wrap;
`;

export interface ITooltipProps extends Partial<IOverlayProps> {
  text: React.ReactNode;
  mobilePlacement?: string;
  className?: string;
}

const Tooltip = ({
  text,
  placement = "top",
  mobilePlacement,
  children,
  className,
  ...props
}: ITooltipProps) => {
  const { isTablet } = useMobileResponsive();
  const finalPlacement =
    isTablet && mobilePlacement ? mobilePlacement : placement;

  return (
    <Overlay
      overlay={
        <TooltipPopover className={className}>
          <TooltipText size="sm" weight="400">
            {text}
          </TooltipText>
        </TooltipPopover>
      }
      placement={finalPlacement}
      {...props}
    >
      {/* child must be wrapped in native dom element (like div) or it should forwardRef to native element (see Icon.tsx) */}
      {/* https://github.com/react-component/tooltip#important-note */}
      {children}
    </Overlay>
  );
};

export default Tooltip;
