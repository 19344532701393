import styled from "styled-components";
import { BidTypeEnum, ListingStatusEnum } from "../../commonEnums";
import { useTranslation } from "react-i18next";
import Avatar from "../Avatar";
import { Paragraph14Bold } from "../../Typography/Paragraph14";
import { IBid, IListing } from "../../commonTypes";
import Icon from "../Icon/Icon";
import Typography from "../Typography/Typography";
import LoadingSpinner from "../LoadingSpinner";
import StaticLink from "../StaticLink/StaticLink";
import { ethers } from "ethers";

// TODO: use Button component
const StyledButton = styled.button`
  width: 100%;
  height: 50px;
  background: ${({ theme }) => theme.lightBackgroundColor};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-image: none;
  cursor: pointer;
  border: none;

  .arrow {
    display: none;
  }

  &:hover {
    background: ${({ theme }) => theme.secondaryColor};
    .arrow {
      display: inline-block;
    }
  }
`;

const InactiveButton = styled(StyledButton)`
  background: ${(props) => props.theme.secondaryColor};

  &:hover {
    background: ${({ theme }) => theme.lightBackgroundColor};
  }
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BidButtonLabel = styled(Paragraph14Bold)`
  line-height: 16px;
  padding-left: 16px;
`;

const StyledAvatar = styled(Avatar)`
  width: 26px;
  height: 26px;
  border-radius: ${(props) => props.theme.borderRadius.xs};
`;

interface IBidButtonProps {
  redirectUrl: string;
  bid?: IBid;
  onClick?: () => void;
  listing?: IListing;
}

const SecondaryLabel = styled(Typography)`
  margin: 0 auto;
`;

const MintingLabel = styled(Typography)`
  display: flex;
  align-items: center;
`;

const MintingLoader = styled(LoadingSpinner)`
  & {
    margin-right: 16px;
    color: ${(props) => props.theme.primaryColor};
  }
`;

const FailedLoader = styled(MintingLoader)`
  animation: none;
`;

const SoldLabel = styled(Typography)`
  color: ${(props) => props.theme.greenColor};
  display: flex;
  align-items: center;
`;

const SoldIcon = styled(Icon)`
  margin-right: 16px;
`;

const BidButton = ({ redirectUrl, bid, onClick, listing }: IBidButtonProps) => {
  const { t } = useTranslation();

  let price = "0";

  const bidListing = bid?.listing ?? listing;

  if (!bidListing?.type) {
    return (
      <StaticLink href={redirectUrl}>
        <InactiveButton className="arrow" onClick={onClick}>
          <SecondaryLabel weight="bold" secondary>
            {t("not-for-sale")}
          </SecondaryLabel>
        </InactiveButton>
      </StaticLink>
    );
  }

  if (bid) {
    price = ethers.utils
      .formatUnits(bid.amount, bid.listing.asset.decimals)
      .toString();
  }

  if (bidListing?.status === ListingStatusEnum.ended) {
    return (
      <StaticLink href={redirectUrl}>
        <InactiveButton className="arrow" onClick={onClick}>
          <SecondaryLabel weight="bold" secondary>
            {t("listing-ended")}
          </SecondaryLabel>
        </InactiveButton>
      </StaticLink>
    );
  }

  if (bidListing?.status === ListingStatusEnum.cancelled) {
    return (
      <StaticLink href={redirectUrl}>
        <InactiveButton className="arrow" onClick={onClick}>
          <SecondaryLabel weight="bold" secondary>
            {t("listing-cancelled")}
          </SecondaryLabel>
        </InactiveButton>
      </StaticLink>
    );
  }

  if (
    bidListing?.status === ListingStatusEnum.sold ||
    bidListing?.status === ListingStatusEnum.minting
  ) {
    return (
      <StaticLink href={redirectUrl}>
        <InactiveButton className="arrow" onClick={onClick}>
          <MintingLabel weight="bold" secondary>
            <MintingLoader /> {t("listing-minting")}
          </MintingLabel>
        </InactiveButton>
      </StaticLink>
    );
  }

  if (bidListing?.status === ListingStatusEnum.failed) {
    return (
      <StaticLink href={redirectUrl}>
        <InactiveButton className="arrow" onClick={onClick}>
          <MintingLabel weight="bold" secondary>
            <FailedLoader /> {t("listing-minting-suspended")}
          </MintingLabel>
        </InactiveButton>
      </StaticLink>
    );
  }

  if (bidListing?.status === ListingStatusEnum.minted) {
    return (
      <StaticLink href={redirectUrl}>
        <InactiveButton className="arrow" onClick={onClick}>
          <SoldLabel weight="bold" secondary>
            <SoldIcon name="music" />
            {t("listing-sold")}
          </SoldLabel>
        </InactiveButton>
      </StaticLink>
    );
  }

  if (bidListing?.type === BidTypeEnum.bid) {
    return (
      <StaticLink href={redirectUrl}>
        <StyledButton className="arrow" onClick={onClick}>
          <LabelContainer>
            {bid ? <StyledAvatar user={bid.user} /> : <StyledAvatar />}

            <BidButtonLabel>
              {bid
                ? t("bid-button-current-bid-label", {
                    price: Number(price).toLocaleString(),
                    currency: bid.listing.asset.symbol,
                  })
                : t("bid-button-no-offers")}
            </BidButtonLabel>
          </LabelContainer>
          <Icon name="arrowRight" height="9px" width="14px" className="arrow" />
        </StyledButton>
      </StaticLink>
    );
  }

  return (
    <StaticLink href={redirectUrl}>
      <StyledButton className="arrow" onClick={onClick}>
        <LabelContainer>
          <Icon name="zap" />
          <BidButtonLabel>{t("new-drops-nft-button-label")}</BidButtonLabel>
        </LabelContainer>
        <Icon name="arrowRight" height="9px" width="14px" className="arrow" />
      </StyledButton>
    </StaticLink>
  );
};

export default BidButton;
