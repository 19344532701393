import styled from "styled-components";
import { IconKeys } from "../../../commonTypes";
import React from "react";
import Icon from "../../Icon/Icon";

const WalletIconWrapper = styled.div<{ $background: string; $size: string }>`
  background: ${(props) => props.$background};
  width: ${(props) => props.$size};
  height: ${(props) => props.$size};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  margin-right: 16px;
`;

const mapWalletToIcon = (
  name: string
): {
  background: string;
  name: IconKeys;
} => {
  switch (name) {
    case "metamask":
      return { name: "metamask", background: "#e4761b33" };
    case "Torus":
      return { name: "torus", background: "rgba(97, 123, 255, 0.2)" };
    case "WalletConnect":
      return { name: "walletconnect", background: "rgba(97, 123, 255, 0.2)" };
    case "Coinbase Wallet":
      return { name: "coinbaseWallet", background: "rgba(97, 123, 255, 0.2)" };
    default:
      return { name: "metamask", background: "#e4761b33" };
  }
};

interface Props {
  name?: string | null;
  size?: string;
  iconSize?: string;
}

const WalletIcon = ({ name, size = "40px", iconSize }: Props) => {
  const iconConfig = name && mapWalletToIcon(name);

  if (!iconConfig) {
    return null;
  }

  return (
    <WalletIconWrapper $background={iconConfig.background} $size={size}>
      <Icon name={iconConfig.name} size={iconSize} />
    </WalletIconWrapper>
  );
};

export default WalletIcon;
