import styled, { css } from "styled-components";
import { getBaseTypography, getBoldTypography } from "./helpers";

export const getHeading1BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.xxl};
  line-height: 67px;
`;

export const Heading1Bold = styled.h1`
  ${getHeading1BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;
