import React, { ReactNode, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import ReactModal from "react-modal";
import Dropdown from "../../Dropdown";
import useMobileResponsive from "../../../hooks/useMobileResponsive";
import { useGlobalPlayer } from "../../../../context/GlobalPlayerProvider";
import { lockScroll, unlockScroll } from "../../../../helpers/scroll";

const MenuModal = styled(ReactModal)`
  background: ${(props) => props.theme.secondaryColor};
  height: calc(100% - 80px);
  margin-top: 80px;
`;

const MenuContent = styled.div`
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const FullScreenMenuContent = styled(MenuContent)<{ $isPlayerOpen: boolean }>`
  padding-bottom: ${({ $isPlayerOpen }) => ($isPlayerOpen ? "20px" : 0)};
`;

interface IProps {
  children: ReactNode;
  onClose: () => void;
}

const MenuWrapper = ({ children, onClose }: IProps) => {
  const theme = useTheme();
  const { isTablet } = useMobileResponsive();
  const { isOpen: isPlayerOpen } = useGlobalPlayer();

  useEffect(() => {
    if (isTablet) {
      lockScroll();

      return () => unlockScroll();
    }
  }, [isTablet]);

  if (!isTablet) {
    return (
      <Dropdown isOpen onClose={onClose}>
        <MenuContent>{children}</MenuContent>
      </Dropdown>
    );
  }

  return (
    <MenuModal
      isOpen
      onRequestClose={onClose}
      style={{
        overlay: {
          background: theme.secondaryColor,
          zIndex: 1,
        },
      }}
    >
      <FullScreenMenuContent $isPlayerOpen={isPlayerOpen}>
        {children}
      </FullScreenMenuContent>
    </MenuModal>
  );
};

export default MenuWrapper;
