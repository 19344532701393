import { useState } from "react";
import { accountService } from "../../api/accountService";
import { useWalletHelpers } from "./useWalletHelpers";
import { useSession } from "../../queries/authentication.query";

interface IReturnValue {
  selectWallet: () => Promise<void>;
  isLoading: boolean;
  error?: string;
  clearError: () => void;
}

export const useReceiveWalletSelect = (): IReturnValue => {
  const { connectWallet } = useWalletHelpers();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const { user: loggedUser, refetch: refreshUserData } = useSession();

  const clearError = () => setError(undefined);

  const selectWallet = async () => {
    try {
      setIsLoading(true);
      const updatedAddress = await connectWallet(true);

      if (updatedAddress && loggedUser?.id) {
        await saveWallet(loggedUser.id, updatedAddress);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const saveWallet = async (userId: string, walletAddress: string) => {
    try {
      await accountService.updateUserReceiveAddress(userId, walletAddress);
      await refreshUserData({ throwOnError: true });
      clearError();
    } catch (error) {
      setError(error.message);
    }
  };

  return {
    selectWallet,
    isLoading,
    error,
    clearError,
  };
};
