import React, { useState } from "react";
import styled from "styled-components";
import { useGlobalPlayer } from "../../../context/GlobalPlayerProvider";
import Cover from "../Cover";
import { IMAGE_SIZES } from "../../../constants/imageSizes";
import Typography from "../Typography/Typography";
import { getAbsoluteCenter, getFlexCenter } from "../../styles";
import {
  ControlButton,
  ControlIcon,
  Root,
  TrackCoverWrapper,
  TrackInfo,
} from "./sharedStyles";
import ModalPlayer from "./ModalPlayer";
import LoadingSpinner from "../LoadingSpinner";

interface IProgressProps {
  $progress: number;
}

const StyledRoot = styled(Root)`
  height: calc(56px + env(safe-area-inset-bottom));
  padding: 8px;
  align-items: flex-start;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`;

const Progress = styled.div.attrs<IProgressProps>(({ $progress }) => ({
  style: {
    width: `${$progress}%`,
  },
}))<IProgressProps>`
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.accent.normal};
  opacity: 0.8;
`;

const TrackInfoArea = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const CoverWrapper = styled(TrackCoverWrapper)`
  position: relative;
  width: 40px;
  height: 40px;
`;

const SpinnerWrapper = styled.div`
  ${getAbsoluteCenter};
  ${getFlexCenter};
`;

const ActionsArea = styled.div`
  display: flex;
  align-items: center;
  padding-left: 12px;
  padding-right: 4px;
`;

const ActionButton = styled(ControlButton)`
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
`;

const MobileGlobalPlayer = () => {
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const {
    isOpen,
    closePlayer,
    track,
    duration,
    playProgress,
    isPlaying,
    togglePlay,
    isBuffering,
  } = useGlobalPlayer();

  if (!track) {
    return null;
  }

  return (
    <>
      <StyledRoot
        $isOpen={isOpen && !fullScreenMode}
        onClick={() => setFullScreenMode(true)}
      >
        <Content>
          <Progress
            $progress={duration === 0 ? 0 : (playProgress * 100) / duration}
          />
          <TrackInfoArea>
            <CoverWrapper>
              <Cover
                mime={track?.cover?.mime}
                url={track?.cover?.url}
                size={IMAGE_SIZES.trackCoverArt.xs}
              />
              {isBuffering && (
                <SpinnerWrapper>
                  <LoadingSpinner size="18px" />
                </SpinnerWrapper>
              )}
            </CoverWrapper>
            <TrackInfo>
              <Typography truncate weight="400">
                {track.title}
              </Typography>
              <Typography truncate size="sm" secondary>
                {track.artist.username}
              </Typography>
            </TrackInfo>
          </TrackInfoArea>
          <ActionsArea>
            <ActionButton
              onClick={(event) => {
                event.stopPropagation();
                togglePlay();
              }}
            >
              <ControlIcon name={isPlaying ? "pause" : "play"} size="18px" />
            </ActionButton>
            <ActionButton
              onClick={(event) => {
                event.stopPropagation();
                closePlayer();
              }}
            >
              <ControlIcon name="close" size="16px" />
            </ActionButton>
          </ActionsArea>
        </Content>
      </StyledRoot>
      {isOpen && fullScreenMode && track && (
        <ModalPlayer isOpen onClose={() => setFullScreenMode(false)} />
      )}
    </>
  );
};

export default MobileGlobalPlayer;
