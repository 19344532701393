import React, { ReactNode } from "react";
import styled from "styled-components";

const Button = styled.button`
  width: 40px;
  height: 40px;
  border: 2px solid ${(props) => props.theme.borderColor};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

interface Props {
  onClick: () => void;
  children: ReactNode;
}

const IconButton = ({
  children,
  onClick,
  ...props
}: Props & React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button onClick={onClick} {...props}>
    {children}
  </Button>
);

export default IconButton;
