import { NextPageContext } from "next";
import { ParsedUrlQuery } from "querystring";

export const isClientEnv = () => typeof window !== "undefined";

// We want to prefetch query only if it's initial server load.
// When changing pages on client we want to return empty object instantly to make use of react query cache
// https://github.com/vercel/next.js/discussions/19611
export const getPrefetchQueryWrapper =
  (action: (ctx: NextPageContext) => Promise<unknown>) =>
  async (ctx: NextPageContext) => {
    if (isClientEnv()) {
      return {};
    }

    return action(ctx);
  };

export const getQueryParam = (
  query: ParsedUrlQuery,
  key: string
): string | undefined => {
  const param = query[key];

  if (!param || Array.isArray(param)) {
    return;
  }

  return param;
};

// remove undefined values from object
export const parseQueryParams = (params: {
  [key: string]: string | undefined;
}) =>
  Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != undefined)
  );
