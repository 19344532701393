import { Web3Provider } from "@chainsafe/web3-context";
import React, { ReactNode, useState } from "react";
import { walletService } from "../../api/walletService";
import ContractsProvider from "../../context/Contracts";
import { getEnv } from "../../helpers/env";
import { useWeb3Query } from "../../queries/web3.query";
import WrongNetworkModal from "./WrongNetworkModal/WrongNetworkModal";

const infuraKey = getEnv("NEXT_PUBLIC_INFURA_KEY");

const wallets = [
  { walletName: "metamask", preferred: true },
  {
    walletName: "walletConnect",
    infuraKey,
  },
  { walletName: "torus" },
  {
    walletName: "walletLink",
    rpcUrl: `https://ropsten.infura.io/v3/${infuraKey}`,
    appName: "NOIZD",
  },
];

interface Web3WrapperProps {
  children: ReactNode;
}

const Web3Wrapper = ({ children }: Web3WrapperProps) => {
  const [isWrongNetworkModalOpen, setIsWrongNetworkModalOpen] = useState(false);
  const openWrongNetworkModal = () => setIsWrongNetworkModalOpen(true);
  const closeWrongNetworkModal = () => setIsWrongNetworkModalOpen(false);
  const { data: deployments } = useWeb3Query();

  const validChainIds = deployments?.map(({ chain }) => chain.id);

  return (
    <Web3Provider
      networkIds={validChainIds}
      onboardConfig={{
        darkMode: true,

        walletSelect: {
          wallets,
        },
        subscriptions: {
          wallet: (wallet) => {
            if (wallet) {
              // append wallet to wallet list
              const walletList = walletService.getLocalWallets();
              if (wallet.name && !walletList.includes(wallet.name)) {
                walletList.push(wallet.name!);
              }
              walletService.setLocalWallets(walletList);
            }
          },
        },
      }}
    >
      <ContractsProvider
        openWrongNetworkModal={openWrongNetworkModal}
        closeWrongNetworkModal={closeWrongNetworkModal}
      >
        {children}
      </ContractsProvider>
      <WrongNetworkModal
        isOpen={isWrongNetworkModalOpen}
        onClose={() => setIsWrongNetworkModalOpen(false)}
      />
    </Web3Provider>
  );
};

export default Web3Wrapper;
