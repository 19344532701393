import { IVideoSource } from "../common/commonTypes";

export const getResizedVideoUrl = (
  url?: string,
  size?: number | string
): string => {
  if (!url) {
    return "";
  }

  if (!size) {
    return url;
  }

  const urlParts = url.split("/");
  const imageName = urlParts.pop();
  const path = urlParts.pop();

  return [
    ...urlParts,
    `c_fill,h_${size},w_${size},q_auto`,
    path,
    imageName,
  ].join("/");
};

export const getPosterUrl = (url?: string) => {
  if (!url) {
    return "";
  }

  return url.substr(0, url.lastIndexOf(".")) + ".jpg";
};

export const getResizedPosterUrl = (url: string, size: number) => {
  const resizedVideoUrl = getResizedVideoUrl(url, size);
  return getPosterUrl(resizedVideoUrl);
};

export const getSources = (url: string, formats: string[]): IVideoSource[] => {
  if (!url) {
    return [];
  }

  const filePathWithoutExtension = url.substr(0, url.lastIndexOf("."));

  return formats.map((format) => ({
    source: `${filePathWithoutExtension}.${format}`,
    type: `video/${format}`,
  }));
};
