import React from "react";
import MobileGlobalPlayer from "./MobileGlobalPlayer";
import DesktopGlobalPlayer from "./DesktopGlobalPlayer";
import useMobileResponsive from "../../hooks/useMobileResponsive";

const GlobalPlayer = () => {
  const { isTablet } = useMobileResponsive();

  if (isTablet) {
    return <MobileGlobalPlayer />;
  }

  return <DesktopGlobalPlayer />;
};

export default GlobalPlayer;
