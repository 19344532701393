import { IDistinctParams, IQueryParams } from "../common/commonTypes";
import { getEnv } from "./env";

export const createSequalizeUrl = (url: string, params: IQueryParams) => {
  let apiUrl = `${getEnv("NEXT_PUBLIC_API_URL")}/${url}`;
  const query = new URLSearchParams();

  if (params.where) {
    query.append(
      "$where",
      encodeURIComponent(JSON.stringify(params.where).toString())
    );
  }

  if (params.attributes) {
    query.append(
      "$attributes",
      encodeURIComponent(JSON.stringify(params.attributes))
    );
  }

  if (params.order) {
    query.append("$order", encodeURIComponent(JSON.stringify(params.order)));
  }

  if (params.limit) {
    query.append("$limit", params.limit.toString());
  }

  if (params.offset) {
    query.append("$offset", params.offset.toString());
  }

  let queryString = "";
  query.forEach((value, key) => {
    queryString += `${key}=${value}&`;
  });

  return `${apiUrl}?${queryString}`;
};

export const createDistinctSequalizeUrl = (
  url: string,
  params: IDistinctParams
) => {
  let apiUrl = `${getEnv("NEXT_PUBLIC_API_URL")}/${url}`;
  const query = new URLSearchParams();

  if (params.table) {
    query.append("$table", params.table);
  }

  if (params.where) {
    query.append("$where", encodeURIComponent(JSON.stringify(params.where)));
  }

  if (params.attributes) {
    query.append(
      "$attributes",
      encodeURIComponent(JSON.stringify(params.attributes))
    );
  }

  let queryString = "";
  query.forEach((value, key) => {
    queryString += `${key}=${value}&`;
  });

  return `${apiUrl}?${queryString}`;
};

export const parseJson = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return jsonString;
  }
};
