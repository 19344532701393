import styled from "styled-components";
import Locked from "./Locked";
import Staked from "./Staked";
import Wallet from "../Wallet/Wallet";
import ReceiveWallet from "../ReceiveWallet/ReceiveWallet";
import Typography from "../../Typography/Typography";
import useChain from "../../../hooks/useChain";

const Staking = styled.div`
  border-top: 1px solid ${(props) => props.theme.darkBorderColor};
  margin-top: 16px;
  padding-top: 16px;
`;

const Stake = () => {
  const { chain } = useChain();

  return (
    <div>
      <Typography size="lg" weight="bold">
        Wallet overview for {chain?.name}
      </Typography>
      <Wallet />
      <Staking>
        <Staked showControls />
        <Locked />
      </Staking>
      <ReceiveWallet />
    </div>
  );
};

export default Stake;
