import { ISocialIcon } from "../../commonTypes";

export const socialIcons: ISocialIcon[] = [
  {
    name: "linkedin",
    url: "https://www.linkedin.com/company/noizd-inc",
  },
  {
    name: "telegram",
    url: "https://t.me/NOIZD_NFT",
  },
  {
    name: "email",
    url: "mailto:info@noizd.com",
  },
  {
    name: "twitter",
    url: "https://twitter.com/NOIZD_Market",
  },

  {
    name: "discord",
    url: "https://discord.gg/R3tzg4X4YZ",
  },
];
