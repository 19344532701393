import { useEffect, useState } from "react";
import { useTokenQuery } from "../../../../queries/token.query";
import { IAsset } from "../../../commonTypes";
import useChain from "../../../hooks/useChain";

export const useAssetSelector = (defaultSelection?: IAsset) => {
  const { chain, stakingContract: contract } = useChain();
  const { data: tokenData } = useTokenQuery(chain);

  const [token, setToken] = useState<IAsset | undefined>(defaultSelection);

  const tokens = tokenData?.tokens;
  const showAssetSelector = tokens && tokens?.length > 1;

  useEffect(() => {
    if (!showAssetSelector) {
      if (tokens?.length === 1) {
        setToken(tokens[0]);
      }
    }
  }, [tokens]);

  return {
    showAssetSelector,
    assets: tokens,
    selectedAsset: token,
    setSelectedAsset: setToken,
  };
};
