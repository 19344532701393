import { ReactComponent as edit } from "../../../assets/icons/edit-icon.svg";
import { ReactComponent as approvedArtist } from "../../../assets/icons/approved-artist.svg";
import { ReactComponent as arrowDown } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as arrowLeft } from "../../../assets/icons/arrow-left-icon.svg";
import { ReactComponent as backArrow } from "../../../assets/icons/back-arrow.svg";
import { ReactComponent as arrowRight } from "../../../assets/icons/arrow-right.svg";
import { ReactComponent as out } from "../../../assets/icons/out.svg";
import { ReactComponent as emptyState } from "../../../assets/icons/empty-state.svg";
import { ReactComponent as close } from "../../../assets/icons/close-icon.svg";
import { ReactComponent as cancel } from "../../../assets/icons/cancel-icon.svg";
import { ReactComponent as closeError } from "../../../assets/icons/close-error-icon.svg";
import { ReactComponent as greenTick } from "../../../assets/icons/green-tick-icon.svg";
import { ReactComponent as search } from "../../../assets/icons/search.svg";
import { ReactComponent as chevronUp } from "../../../assets/icons/chevron-up.svg";
import { ReactComponent as chevronDown } from "../../../assets/icons/chevron-down.svg";
import { ReactComponent as chevronRight } from "../../../assets/icons/chevron-right.svg";
import { ReactComponent as chevronLeft } from "../../../assets/icons/chevron-left.svg";
import { ReactComponent as sad } from "../../../assets/icons/sad.svg";
import { ReactComponent as user } from "../../../assets/icons/user-icon.svg";
import { ReactComponent as logout } from "../../../assets/icons/log-out-icon.svg";
import { ReactComponent as repeat } from "../../../assets/icons/repeat-icon.svg";
import { ReactComponent as walletLine } from "../../../assets/icons/wallet-line-icon.svg";
import { ReactComponent as metamask } from "../../../assets/icons/metamask-icon.svg";
import { ReactComponent as torus } from "../../../assets/icons/torus-icon.svg";
import { ReactComponent as walletconnect } from "../../../assets/icons/walletconnect-logo.svg";
import { ReactComponent as coinbaseWallet } from "../../../assets/icons/coinbase-icon.svg";
import { ReactComponent as tiktok } from "../../../assets/icons/tiktok.svg";
import { ReactComponent as email } from "../../../assets/icons/email.svg";
import { ReactComponent as twitter } from "../../../assets/icons/twitter.svg";
import { ReactComponent as twitterShare } from "../../../assets/icons/twitter-icon-share.svg";
import { ReactComponent as linkedin } from "../../../assets/icons/linkedin.svg";
import { ReactComponent as telegram } from "../../../assets/icons/telegram.svg";
import { ReactComponent as discord } from "../../../assets/icons/discord.svg";
import { ReactComponent as instagram } from "../../../assets/icons/instagram.svg";
import { ReactComponent as facebook } from "../../../assets/icons/facebook.svg";
import { ReactComponent as facebookShare } from "../../../assets/icons/facebook-icon-share.svg";
import { ReactComponent as avatar } from "../../../assets/icons/avatar.svg";
import { ReactComponent as avatarIcon } from "../../../assets/icons/avatar-icon.svg";
import { ReactComponent as check } from "../../../assets/icons/check.svg";
import { ReactComponent as checkMark } from "../../../assets/icons/check-mark.svg";
import { ReactComponent as downloadMusic } from "../../../assets/icons/download-music.svg";
import { ReactComponent as transaction } from "../../../assets/icons/transaction-icon.svg";
import { ReactComponent as zap } from "../../../assets/icons/zap.svg";
import { ReactComponent as spinner } from "../../../assets/icons/spinner-icon.svg";
import { ReactComponent as music } from "../../../assets/icons/music-icon.svg";
import { ReactComponent as circleLoader } from "../../../assets/icons/circle-loader.svg";
import { ReactComponent as cover } from "../../../assets/icons/cover.svg";
import { ReactComponent as instrumental } from "../../../assets/icons/instrumental.svg";
import { ReactComponent as masterTrack } from "../../../assets/icons/master-track.svg";
import { ReactComponent as previewTrack } from "../../../assets/icons/preview-track.svg";
import { ReactComponent as vocal } from "../../../assets/icons/vocal.svg";
import { ReactComponent as share } from "../../../assets/icons/share-icon.svg";
import { ReactComponent as copy } from "../../../assets/icons/copy-icon.svg";
import { ReactComponent as list } from "../../../assets/icons/list.svg";
import { ReactComponent as ownershipRights } from "../../../assets/icons/ownership-rights.svg";
import { ReactComponent as linktree } from "../../../assets/icons/linktree.svg";
import { ReactComponent as soundcloud } from "../../../assets/icons/soundcloud.svg";
import { ReactComponent as flag } from "../../../assets/icons/flag.svg";
import { ReactComponent as globe } from "../../../assets/icons/globe.svg";
import { ReactComponent as length } from "../../../assets/icons/length.svg";
import { ReactComponent as layers } from "../../../assets/icons/layers.svg";
import { ReactComponent as info } from "../../../assets/icons/info-icon.svg";
import { ReactComponent as eth } from "../../../assets/icons/eth.svg";
import { ReactComponent as wallet } from "../../../assets/icons/wallet.svg";
import { ReactComponent as layout } from "../../../assets/icons/layout.svg";
import { ReactComponent as dotLine } from "../../../assets/icons/dot-line.svg";
import { ReactComponent as burgerMenu } from "../../../assets/icons/burger-menu-icon.svg";
import { ReactComponent as noizdLogo } from "../../../assets/icons/noizd-logo.svg";
import { ReactComponent as nftPick } from "../../../assets/icons/nft-remix-package-pick-icon.svg";
import { ReactComponent as nftStake } from "../../../assets/icons/nft-remix-package-stake-with-us-icon.svg";
import { ReactComponent as nftGetPaid } from "../../../assets/icons/nft-remix-package-get-paid-icon.svg";
import { ReactComponent as ethLogoRound } from "../../../assets/icons/ethereum-logo-round.svg";
import { ReactComponent as filterIcon } from "../../../assets/icons/filter-icon.svg";
import { ReactComponent as filterCloseIcon } from "../../../assets/icons/filter-close-icon.svg";
import { ReactComponent as lightning } from "../../../assets/icons/lightning.svg";
import { ReactComponent as eyeOn } from "../../../assets/icons/eye-on.svg";
import { ReactComponent as eyeOff } from "../../../assets/icons/eye-off.svg";
import { ReactComponent as signupArtist } from "../../../assets/icons/signup-artist-icon.svg";
import { ReactComponent as signupCollector } from "../../../assets/icons/signup-collector-icon.svg";
import { ReactComponent as play } from "../../../assets/icons/play.svg";
import { ReactComponent as pause } from "../../../assets/icons/pause.svg";
import { ReactComponent as volume } from "../../../assets/icons/volume.svg";
import { ReactComponent as volumeMedium } from "../../../assets/icons/volume-medium.svg";
import { ReactComponent as volumeMute } from "../../../assets/icons/volume-mute.svg";
import { ReactComponent as previousTrack } from "../../../assets/icons/previous-track.svg";
import { ReactComponent as nextTrack } from "../../../assets/icons/next-track.svg";
import { ReactComponent as spotify } from "../../../assets/icons/spotify.svg";
import { ReactComponent as video } from "../../../assets/icons/video.svg";

export const icons = {
  edit,
  approvedArtist,
  arrowDown,
  arrowLeft,
  arrowRight,
  backArrow,
  out,
  close,
  cancel,
  closeError,
  greenTick,
  emptyState,
  tiktok,
  chevronDown,
  chevronUp,
  metamask,
  chevronRight,
  chevronLeft,
  checkMark,
  logout,
  user,
  torus,
  walletconnect,
  walletLine,
  sad,
  repeat,
  search,
  coinbaseWallet,
  email,
  twitter,
  twitterShare,
  linkedin,
  telegram,
  discord,
  instagram,
  facebook,
  facebookShare,
  avatar,
  avatarIcon,
  check,
  downloadMusic,
  transaction,
  zap,
  spinner,
  music,
  circleLoader,
  cover,
  instrumental,
  masterTrack,
  previewTrack,
  vocal,
  share,
  copy,
  list,
  ownershipRights,
  linktree,
  soundcloud,
  flag,
  globe,
  length,
  layers,
  info,
  eth,
  wallet,
  layout,
  dotLine,
  burgerMenu,
  noizdLogo,
  nftPick,
  nftGetPaid,
  nftStake,
  ethLogoRound,
  filterIcon,
  filterCloseIcon,
  lightning,
  eyeOn,
  eyeOff,
  signupArtist,
  signupCollector,
  play,
  pause,
  volume,
  volumeMedium,
  volumeMute,
  previousTrack,
  nextTrack,
  spotify,
  video,
};

export default icons;
