import { useEffect, useMemo } from "react";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../queryKeys";
import { ListingStatusEnum, WebsocketMessageType } from "../commonEnums";
import { IListing, IWebsocketData } from "../commonTypes";
import { getEnv } from "../../helpers/env";

export const useWebsockets = () => {
  const queryClient = useQueryClient();

  const handlers = useMemo(
    () => ({
      onListingUpdate: ({
        id: listingId,
        music_id: musicId,
        status,
      }: IListing) => {
        queryClient.invalidateQueries([QueryKeys.assetDetails, { listingId }]);
        queryClient.invalidateQueries(
          [QueryKeys.assetDetails, { musicId, listingId: undefined }],
          { exact: true }
        );

        queryClient.invalidateQueries(QueryKeys.homeData);
        queryClient.invalidateQueries(QueryKeys.marketData);
        queryClient.invalidateQueries(QueryKeys.searchListings);
        queryClient.invalidateQueries(QueryKeys.userMusic);

        if (status === ListingStatusEnum.minted) {
          queryClient.invalidateQueries(QueryKeys.userCollection);
        }
      },
    }),
    [queryClient]
  );

  useEffect(() => {
    const socket = new WebSocket(`${getEnv("NEXT_PUBLIC_WS_URL")}/listings`);

    socket.onmessage = (event) => {
      const data: IWebsocketData = JSON.parse(event.data);

      if (data.type === WebsocketMessageType.listingStatusChanged) {
        handlers.onListingUpdate(data.payload.listing as IListing);
      }
    };

    return () => {
      socket.close();
    };
  }, [queryClient, handlers]);
};
