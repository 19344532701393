import Menu from "../Menu";
import MenuNav from "../MenuNav";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";
import WalletItem from "./WalletItem";
import styled, { useTheme } from "styled-components";
import Button from "../../Buttons/Button";
import { useWeb3 } from "@chainsafe/web3-context";
import { useEffect } from "react";
import { useState } from "react";
import { Wallet } from "bnc-onboard/dist/src/interfaces";
import { useTranslation } from "react-i18next";
import { Paragraph14RegularSecondary } from "../../../Typography/Paragraph14";
import { Dot } from "../../styled";
import { Heading5Bold } from "../../../Typography/Heading5";
import { walletService } from "../../../../api/walletService";
import { truncateAmount } from "../../../../helpers/format";
import useChain from "../../../hooks/useChain";
import { useTokenQuery } from "../../../../queries/token.query";

const Wallets = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid ${(props) => props.theme.darkBorderColor};
  margin-bottom: 16px;

  div.wallet-item {
    margin-bottom: 10px;
  }

  div.wallet-item:last-of-type {
    margin-bottom: 0px;
  }
`;

const Balance = styled.div`
  margin-bottom: 16px;
  div {
    display: flex;
    align-items: center;

    h5 {
      margin-left: 10px;
    }
  }
`;

const WalletMenu = () => {
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();
  // eslint-disable-next-line
  const [wallets, setWallets] = useState<string[]>([]);
  const { isWalletMenuOpen, handleWalletMenuClose } = useAccountDropdown();
  const theme = useTheme();
  const { onboard, provider, resetOnboard, ethBalance, address } = useWeb3();
  const { t } = useTranslation();
  const defaultTitle = t("available-in-wallet");
  const [title, setTitle] = useState<string>(defaultTitle);
  const { chain } = useChain();
  const { data: tokenData } = useTokenQuery(chain);

  const handleAddWallet = async () => {
    const isSelected = await onboard?.walletSelect();

    if (isSelected) {
      await onboard?.walletCheck();
    }
  };

  useEffect(() => {
    setWallets(walletService.getLocalWallets());
  }, []);

  useEffect(() => {
    setSelectedWallet(onboard?.getState().wallet);
  }, [onboard, provider]);

  const handleDelete = (walletName: string) => {
    const newWallets: string[] = walletService
      .getLocalWallets()
      .filter((wallet: string) => wallet !== walletName);

    walletService.setLocalWallets(newWallets);

    // reset onboard
    if (walletName === selectedWallet?.name) {
      resetOnboard();
      setSelectedWallet(undefined);
    }

    setWallets(newWallets);
  };

  const otherWallets = walletService.getLocalWallets();

  return (
    <Menu isOpen={isWalletMenuOpen} onClose={handleWalletMenuClose}>
      <MenuNav title={title} onBackClicked={handleWalletMenuClose} />
      {selectedWallet && address && (
        <Balance>
          <Paragraph14RegularSecondary>
            {t("from-active-wallet")}
          </Paragraph14RegularSecondary>
          <div>
            <Dot color={theme.greenColor} />
            <Heading5Bold>
              {truncateAmount(ethBalance)} {tokenData?.nativeToken.symbol}
            </Heading5Bold>
          </div>
        </Balance>
      )}
      <Wallets onClick={() => setTitle(t("select-wallet"))}>
        {selectedWallet?.name && (
          <WalletItem
            name={selectedWallet.name ?? ""}
            address={address}
            balance={ethBalance?.toString()}
            onDelete={() => handleDelete(selectedWallet.name!)}
          />
        )}
        {otherWallets
          .filter((wallet: string) => wallet !== selectedWallet?.name)
          .map((wallet: string) => {
            return (
              <WalletItem
                key={wallet}
                name={wallet ?? ""}
                onActivate={() => onboard?.walletSelect(wallet)}
                onDelete={() => handleDelete(wallet!)}
              />
            );
          })}
      </Wallets>
      <Button
        background={theme.darkBorderColor}
        fullWidth
        height="34px"
        text={t("add-wallet")}
        onClick={handleAddWallet}
      />
    </Menu>
  );
};

export default WalletMenu;
