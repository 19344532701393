import { ISession } from "../common/commonTypes";
import {
  localStorageHelper,
  LocalStorageKeys,
} from "../helpers/localStorageHelper";
import router from "next/router";
import { routes } from "../routes";
import { QueryKeys } from "../common/queryKeys";
import { reactQueryService } from "./reactQueryService";

interface SessionService {
  getSessionId: () => string | undefined;
  saveSession: (session: ISession) => void;
  removeSession: () => void;
  handleSessionExpired: () => void;
}

export const sessionService: SessionService = {
  getSessionId: (): string | undefined =>
    localStorageHelper.get(LocalStorageKeys.sessionId),

  saveSession: (session: ISession) => {
    localStorageHelper.set(LocalStorageKeys.sessionId, session.id);
  },

  removeSession: () => {
    localStorageHelper.remove(LocalStorageKeys.sessionId);
  },

  handleSessionExpired: () => {
    const currentPath = router.pathname;

    // prevent multiple redirections if multiple requests got 401 at once
    if (currentPath !== routes.auth.login) {
      router.push({
        pathname: routes.auth.login,
        query: {
          from: router.asPath,
          isSessionExpired: 1,
        },
      });
    }

    reactQueryService.queryClient?.setQueryData(QueryKeys.session, undefined);
    sessionService.removeSession();
  },
};
