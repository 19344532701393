import styled, { css } from "styled-components";
import {
  getBaseTypography,
  getBoldTypography,
  getMediumTypography,
  getRegularTypography,
} from "./helpers";

export const getParagraph18BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.paragraph.xl};
  line-height: 24px;
`;

export const getParagraph18MediumTypography = css`
  ${getBaseTypography};
  ${getMediumTypography};
  font-size: ${(props) => props.theme.paragraph.xl};
  line-height: 24px;
`;

export const getParagraph18RegularTypography = css`
  ${getBaseTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.paragraph.xl};
  line-height: 24px;
`;

export const Paragraph18Bold = styled.p`
  ${getParagraph18BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph18Regular = styled.p`
  ${getParagraph18RegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;
