import React, { ReactNode, RefObject, useState } from "react";
import styled from "styled-components";
import { IMusic } from "../../commonTypes";
import Card from "../Card/Card";
import { IMAGE_SIZES } from "../../../constants/imageSizes";
import Cover from "../Cover";
import Typography from "../Typography/Typography";
import StaticLink from "../StaticLink/StaticLink";
import { routes } from "../../../routes";
import Overlay, { IOverlayProps } from "../Overlay/Overlay";

const Root = styled(Card)`
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${(props) => props.theme.darkBorderColor};
  max-width: 300px;
`;

export const TrackCoverWrapper = styled.div`
  width: 58px;
  height: 58px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  flex-shrink: 0;
`;

export const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
`;

const Link = styled(Typography)`
  &:hover {
    text-decoration: underline;
  }
`;

interface IProps {
  children: ReactNode;
  attachContainer: RefObject<HTMLElement | null>;
  track?: IMusic;
}

const TrackPreviewOverlay = ({
  track,
  attachContainer,
  children,
  ...props
}: IProps & Partial<IOverlayProps>) => {
  const [isVisible, setIsVisible] = useState(false);
  const closeOverlay = () => setIsVisible(false);

  if (!track) {
    return <>{children}</>;
  }

  const tooltipContainerGetter = () => {
    const container = attachContainer.current;
    if (container) {
      return () => container;
    }
  };

  return (
    <Overlay
      visible={isVisible}
      onVisibleChange={setIsVisible}
      mouseEnterDelay={0.1}
      getTooltipContainer={tooltipContainerGetter()}
      overlay={
        isVisible && (
          <Root onClick={closeOverlay}>
            <TrackCoverWrapper>
              <Cover
                mime={track.cover.mime}
                url={track.cover.url}
                size={IMAGE_SIZES.trackCoverArt.xs}
              />
            </TrackCoverWrapper>
            <TrackInfo>
              <Link
                truncate
                weight="400"
                element={StaticLink}
                href={routes.marketplace.id(track.id)}
              >
                {track.title}
              </Link>
              <Link
                truncate
                size="sm"
                secondary
                element={StaticLink}
                href={routes.user.url(track.artist.uri)}
              >
                {track.artist.username}
              </Link>
            </TrackInfo>
          </Root>
        )
      }
      {...props}
    >
      <div onClick={closeOverlay}>{children}</div>
    </Overlay>
  );
};

export default TrackPreviewOverlay;
