import React from "react";
import { IUserProfile } from "../../commonTypes";
import styled from "styled-components";
import Card from "../Card/Card";
import Avatar from "../Avatar";
import {
  getParagraph18BoldTypography,
  getParagraph14RegularTypography,
  getParagraph16BoldTypography,
} from "../../Typography";
import { getCountryNameByCode } from "../../../helpers/countryHelper";
import { routes } from "../../../routes";
import Button from "../Buttons/Button";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { useTranslation } from "react-i18next";
import { useFollowersSummary } from "../../hooks/useFollowersSummary";
import LoginModal from "../Modal/LoginModal";
import StaticLink from "../StaticLink/StaticLink";

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const StyledAvatar = styled(Avatar)`
  width: 90px;
  height: 90px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    width: 60px;
    height: 60px;
  }
`;

const Info = styled.div`
  flex: 1;
  padding: 0 22px;
  overflow: hidden;
`;

const FollowingWrapper = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 8px;
    position: relative;
    right: 0;
    bottom: 0;
    align-self: flex-end;
  }
`;

const FollowingLabel = styled.p`
  color: ${(props) => props.theme.primaryColor};
  font-size: 11px;
  font-weight: 300;
  text-transform: uppercase;
`;

const Username = styled.p`
  color: ${(props) => props.theme.primaryColor};
  ${getParagraph18BoldTypography};
  margin-bottom: 6px;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${getParagraph16BoldTypography};
  }
`;

const CountryInfo = styled.p`
  color: ${(props) => props.theme.primaryColor};
  ${getParagraph14RegularTypography};
  opacity: 0.7;
`;

interface Props {
  artist: IUserProfile;
}

const ArtistCard = ({ artist }: Props) => {
  const { t } = useTranslation();
  const {
    isFollowedByMe,
    isFollowedByMeLoading,
    isFollowingUpdating,
    isOwnProfile,
    followButtonText,
    toggleFollowing,
    isLoginModalOpen,
    closeLoginModal,
  } = useFollowersSummary(artist, {
    fetchFollowersCount: false,
    fetchFollowingCount: false,
  });

  const handleFollowClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    toggleFollowing();
  };

  const FollowingButton = isFollowedByMe ? Button : ButtonOutlined;

  return (
    <>
      <StaticLink href={routes.user.url(artist.uri)}>
        <StyledCard>
          <Content>
            <StyledAvatar user={artist} />
            <Info>
              <Username>{artist.username}</Username>
              {artist?.profile.country && (
                <CountryInfo>
                  {getCountryNameByCode(artist?.profile?.country)}
                </CountryInfo>
              )}
            </Info>
          </Content>
          {!isOwnProfile && !isFollowedByMeLoading && (
            <FollowingWrapper>
              <FollowingButton
                isLoading={isFollowingUpdating}
                onClick={handleFollowClick}
                height="20px"
                label={<FollowingLabel>{followButtonText}</FollowingLabel>}
              />
            </FollowingWrapper>
          )}
        </StyledCard>
      </StaticLink>
      {isLoginModalOpen && (
        <LoginModal
          isOpen
          onClose={closeLoginModal}
          instruction={t("follow-login-instruction", {
            username: artist.username,
          })}
        />
      )}
    </>
  );
};

export default ArtistCard;
