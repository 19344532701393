import styled from "styled-components";
import NoizdLinks from "./Sections/NoizdLinks";
import BrowserLinks from "./Sections/BrowserLinks";
import HelpLinks from "./Sections/HelpLinks";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import { getHorizontalGap } from "../../styles";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  ${getHorizontalGap("55px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    ${getHorizontalGap("0px")}
  }
`;

type Props = {
  onAboutModalOpen: () => void;
};

const FooterLinks = ({ onAboutModalOpen }: Props) => {
  const { isTablet } = useMobileResponsive();

  return (
    <Root>
      <NoizdLinks isTablet={isTablet} onAboutModalOpen={onAboutModalOpen} />
      <BrowserLinks isTablet={isTablet} />
      <HelpLinks isTablet={isTablet} />
    </Root>
  );
};

export default FooterLinks;
