import { ReactNode, useState } from "react";
import styled from "styled-components";
import { IStyleableProps } from "../../commonTypes";
import Icon from "../Icon/Icon";

const StyledExpand = styled.div`
  background: ${(props) => props.theme.lightBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  padding: 16px;
  cursor: pointer;
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ExpandProps {
  closedContent: ReactNode;
  children: ReactNode;
  icon?: ReactNode;
  isOpen?: boolean;
}

const Expand = ({
  children,
  closedContent,
  icon,
  isOpen: defaultIsOpen,
  ...props
}: ExpandProps & IStyleableProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <StyledExpand
      onClick={() => setIsOpen(!isOpen)}
      className="expand"
      {...props}
    >
      <Top>
        {closedContent}
        {icon || (
          <Icon name={!isOpen ? "chevronDown" : "chevronUp"} width="24px" />
        )}
      </Top>
      <div>{isOpen && children}</div>
    </StyledExpand>
  );
};

export default Expand;
