import styled, { css } from "styled-components";
import {
  getBasePoppinsTypography,
  getBaseTypography,
  getBoldTypography,
} from "./helpers";

export const getHeading3BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.lg};
  line-height: 40px;
`;

export const getHeading3PoppinsBoldTypography = css`
  ${getBasePoppinsTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.lg};
  line-height: 40px;
`;

export const Heading3Bold = styled.h2`
  ${getHeading3BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;
