import Menu from "../Menu";
import MenuNav from "../MenuNav";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";
import styled, { useTheme } from "styled-components";
import { Dot } from "../../styled";
import { Paragraph16Regular } from "../../../Typography/Paragraph16";
import Button from "../../Buttons/Button";
import {
  Accounts,
  AccountBadge,
  AccountBadgeLeft,
  BalanceLabel,
  StyledAccount as Account,
} from "../Account/Account";
import { useStaking } from "../../../hooks/useStaking";
import InProgress from "./InProgress";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AmountInput from "../../AmountInput";
import { AccountLabel, AmountLabel } from "./DepositStake";
import { getFormattedAmountWithLabel } from "../../../../helpers/format";
import { useWeb3 } from "@chainsafe/web3-context";
import { getBlockExplorerTransactionUrl } from "../../../../helpers/transactions";
import useChain from "../../../hooks/useChain";
import Typography from "../../Typography/Typography";
import {
  ICurrentStake,
  useStakedQueryForChain,
} from "../../../../queries/staking.query";
import { IAsset, IDeployment } from "../../../commonTypes";
import AssetSelector from "./AssetSelector";
import { ethers } from "ethers";
import VersionSelector from "./VersionSelector";
import { useAssetSelector } from "./useAssetSelector";
import { useVersionSelector } from "./useVersionSelector";

const Amount = styled.div`
  margin-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  text-align: left;

  input {
    background: none;
  }
`;

const WithdrawStakeMenu = () => {
  const {
    isWithdrawStakeMenuOpen,
    handleWithdrawStakeMenuClose,
    openTransactionListMenu,
  } = useAccountDropdown();
  const theme = useTheme();
  const [amount, setAmount] = useState(0);
  const { t } = useTranslation();
  const {
    isSubmitting,
    withdraw,
    error,
    clearTransaction,
    lastTxHash,
    success,
  } = useStaking();
  const { address } = useWeb3();
  const { chain, stakingContract: contract } = useChain();
  const { showAssetSelector, selectedAsset, setSelectedAsset, assets } =
    useAssetSelector();
  const { selectedVersion, setSelectedVersion, showVersionSelector, versions } =
    useVersionSelector();
  const { data } = useStakedQueryForChain(chain?.id, address);

  const stakedResult =
    data && selectedAsset ? data[selectedAsset.address] : ({} as ICurrentStake);

  const handleWithdrawClicked = () => {
    withdraw(amount.toString(), selectedAsset!, selectedVersion!);
  };

  const handleClose = () => {
    clearTransaction();
    handleWithdrawStakeMenuClose();
    openTransactionListMenu();
  };

  const handleTokenSelected = (selection: { label: string; value: IAsset }) => {
    setSelectedAsset(selection.value);
  };

  const handleVersionSelected = (selection: {
    label: string;
    value: IDeployment;
  }) => {
    setSelectedVersion(selection.value);
  };

  return (
    <Menu isOpen={isWithdrawStakeMenuOpen} onClose={handleClose}>
      <MenuNav title={t("withdraw")} onBackClicked={handleClose} />
      {isSubmitting ? (
        <InProgress
          onContinueClick={handleClose}
          error={error}
          hash={lastTxHash}
          blockNumber={success?.blockNumber}
          redirectUrl={getBlockExplorerTransactionUrl(chain!, lastTxHash!)}
        />
      ) : (
        <div>
          {chain && (
            <Typography size="lg" weight="bold">
              {`${t("withdraw-from")} ${chain.name}`}
            </Typography>
          )}
          {showAssetSelector && (
            <div>
              <Typography>Select token to withdraw</Typography>
              <AssetSelector
                onTokenSelected={handleTokenSelected}
                tokens={assets!}
                selectedToken={selectedAsset}
              />
            </div>
          )}
          {versions && versions?.length > 1 && (
            <div>
              <Typography>Select version to withdraw from</Typography>
              <VersionSelector
                onVersionSelected={handleVersionSelected}
                versions={versions!}
                selectedVersion={selectedVersion}
              />
            </div>
          )}
          <div>
            {selectedAsset && (
              <div>
                <Accounts>
                  <Account>
                    <AccountLabel>{t("from")}</AccountLabel>
                    <AccountBadge>
                      <AccountBadgeLeft>
                        <Dot color={theme.accent.normal} />
                        <Paragraph16Regular>{t("stake")}</Paragraph16Regular>
                      </AccountBadgeLeft>

                      <BalanceLabel
                        onClick={() => {
                          const formatted = ethers.utils.formatUnits(
                            stakedResult?.available?.toString() ?? "0",
                            selectedAsset.decimals
                          );

                          setAmount(Number(formatted));
                        }}
                      >
                        {getFormattedAmountWithLabel(
                          stakedResult?.available?.toString(),
                          selectedAsset
                        )}
                      </BalanceLabel>
                    </AccountBadge>
                  </Account>
                  <Account>
                    <AccountLabel>{t("to")}</AccountLabel>
                    <AccountBadge>
                      <AccountBadgeLeft>
                        <Dot color={theme.greenColor} />
                        <Paragraph16Regular>
                          {t("stake-available")}
                        </Paragraph16Regular>
                      </AccountBadgeLeft>
                      <BalanceLabel
                        onClick={() => {
                          const formatted = ethers.utils.formatUnits(
                            stakedResult?.balance?.toString() ?? "0",
                            selectedAsset.decimals
                          );

                          setAmount(Number(formatted));
                        }}
                      >
                        {getFormattedAmountWithLabel(
                          stakedResult?.balance?.toString(),
                          selectedAsset
                        )}
                      </BalanceLabel>
                    </AccountBadge>
                  </Account>
                </Accounts>
                <Amount>
                  <AmountLabel>{t("type-amount")}</AmountLabel>
                  <AmountInput
                    onChange={(e) => setAmount(e.target.value as any)}
                    amount={amount.toString()}
                    label={selectedAsset.symbol}
                  />
                </Amount>

                <Button
                  text="Confirm"
                  fullWidth
                  onClick={handleWithdrawClicked}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Menu>
  );
};

export default WithdrawStakeMenu;
