import { ReactNode } from "react";
import styled from "styled-components";
import { IStyleableProps } from "../../commonTypes";

const Root = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 17px;
  width: 100%;
  background: ${(props) => props.theme.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};
  font-size: ${(props) => props.theme.paragraph.lg};
  margin-bottom: 0;
  justify-content: space-between;
`;

type Props = {
  children?: ReactNode;
  [prop: string]: any;
};

const Card = ({ children, ...props }: Props & IStyleableProps) => {
  return <Root {...props}>{children}</Root>;
};

export default Card;
