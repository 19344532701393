const envs = {
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_API_URL: process.env.NEXT_PUBLIC_API_URL,
  NEXT_PUBLIC_WS_URL: process.env.NEXT_PUBLIC_WS_URL,
  NEXT_PUBLIC_NETWORK_ID: process.env.NEXT_PUBLIC_NETWORK_ID,
  NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS:
    process.env.NEXT_PUBLIC_STAKING_CONTRACT_ADDRESS,
  NEXT_PUBLIC_INFURA_KEY: process.env.NEXT_PUBLIC_INFURA_KEY,
  NEXT_PUBLIC_BLOCK_EXPLORER_URL: process.env.NEXT_PUBLIC_BLOCK_EXPLORER_URL,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_SHARE_LOGO_URL: process.env.NEXT_PUBLIC_SHARE_LOGO_URL,
  NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME:
    process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
};

type envKey = keyof typeof envs;

export const getEnv = (name: envKey): string => {
  const env = envs[name];

  if (!env) {
    throw new Error(`${name} environment variable not configured`);
  }

  return env;
};
