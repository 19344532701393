import styled from "styled-components";
import Avatar from "../Avatar";
import { getParagraph14BoldTypography } from "../../Typography";
import { Paragraph14RegularSecondary } from "../../Typography/Paragraph14";
import { ButtonCTA, Truncate } from "../styled";
import { useEffect } from "react";
import LoadingSpinner from "../LoadingSpinner";
import { useTranslation } from "react-i18next";
import LoginModal from "../Modal/LoginModal";
import { routes } from "../../../routes";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { IUserProfile } from "../../commonTypes";
import StaticLink from "../StaticLink/StaticLink";
import { getFlexCenter } from "../../styles";
import { IMAGE_SIZES } from "../../../constants/imageSizes";
import { useFollowersSummary } from "../../hooks/useFollowersSummary";
import { useUserQuickViewQuery } from "../../../queries/user.query";
import Cover from "../Cover";

const THUMBNAIL_SIZE = 80;

const Root = styled.div``;

const QuickViewWrapper = styled.div`
  width: fit-content;
  background: ${(props) => props.theme.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};
  color: ${(props) => props.theme.primaryColor};
  padding: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
  display: block;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 40px;
`;
const DetailsWrapper = styled.div`
  display: flex;
`;
const Details = styled.div`
  margin-left: 10px;
  margin-right: 13px;
`;
const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;
const Username = styled(StaticLink)`
  max-width: 120px;
  ${getParagraph14BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  align-items: center;
  text-decoration: none;
  line-height: 16px;
  padding-bottom: 5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 90px;
  }

  svg {
    margin-left: 8px;
  }

  &:before {
    content: "@";
  }
`;
const Followers = styled(Paragraph14RegularSecondary)`
  white-space: nowrap;
`;
const FollowButton = styled(ButtonOutlined)`
  padding: 5px 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  max-height: 26px;
`;

const ThumbnailsContainer = styled.div`
  min-height: ${THUMBNAIL_SIZE}px;
  min-width: ${THUMBNAIL_SIZE * 3 + 20}px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  & > *:not(:first-child) {
    margin-left: 10px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    min-width: ${THUMBNAIL_SIZE * 2 + 10}px;

    /* display a max of 2 images on tablets and smaller */
    & > a:nth-child(n + 3) {
      display: none;
    }
  }
`;

const ThumbnailWrapper = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.lightBackgroundColor};
  position: relative;
  cursor: pointer;
`;

const Thumbnail = styled(Cover)`
  width: ${THUMBNAIL_SIZE}px;
  height: ${THUMBNAIL_SIZE}px;
  border-radius: inherit;
  cursor: pointer;
`;

const ThumbnailSpinner = styled.div`
  flex: 1;
  ${getFlexCenter};
`;

const ThumbnailsEmpty = styled.p`
  width: 100%;
  ${getParagraph14BoldTypography};
  color: ${(props) => props.theme.greyColor};
  text-align: center;
`;

const StyledButtonCTA = styled(ButtonCTA)`
  font-size: 10px;
  text-transform: uppercase;
`;

interface IProps {
  user: IUserProfile;
  closeQuickView: () => void;
}

const ProfileQuickView = ({ user, closeQuickView }: IProps) => {
  const { data: music = [], isLoading: areThumbnailsLoading } =
    useUserQuickViewQuery(user);

  const {
    followersCount,
    isFollowingUpdating,
    toggleFollowing,
    followButtonText,
    isLoginModalOpen,
    closeLoginModal,
    isOwnProfile,
  } = useFollowersSummary(user, {
    fetchFollowersCount: true,
    fetchFollowingCount: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (isLoginModalOpen) {
      closeQuickView();
    }
  }, [isLoginModalOpen]);

  const renderThumbnails = () => {
    if (areThumbnailsLoading) {
      return (
        <ThumbnailSpinner>
          <LoadingSpinner />
        </ThumbnailSpinner>
      );
    }

    if (!music || music.length === 0) {
      return <ThumbnailsEmpty>{t("quick-view-no-music")}</ThumbnailsEmpty>;
    }

    return music.map((music) => (
      <StaticLink key={music.id} href={routes.marketplace.id(music.id)}>
        <ThumbnailWrapper>
          <Thumbnail
            url={music.cover?.url}
            mime={music.cover?.mime}
            size={IMAGE_SIZES.trackCoverArt.xs}
          />
        </ThumbnailWrapper>
      </StaticLink>
    ));
  };

  return (
    <>
      <Root onClick={(event) => event.stopPropagation()}>
        <QuickViewWrapper>
          <Top>
            <DetailsWrapper>
              <StaticLink href={routes.user.url(user.uri)}>
                <StyledAvatar user={user} />
              </StaticLink>
              <Details>
                <Username href={routes.user.url(user.uri)}>
                  <Truncate>{user.username}</Truncate>
                </Username>
                <Followers>
                  {followersCount}
                  {` `}
                  {followersCount === 1 ? t("follower") : t("followers")}
                </Followers>
              </Details>
            </DetailsWrapper>
            {!isOwnProfile && (
              <FollowButton
                isLoading={isFollowingUpdating}
                label={<StyledButtonCTA>{followButtonText}</StyledButtonCTA>}
                maxWidth="fit-content"
                height="fit-content"
                onClick={(e: any) => {
                  e.preventDefault();
                  toggleFollowing();
                }}
              />
            )}
          </Top>
          <ThumbnailsContainer>{renderThumbnails()}</ThumbnailsContainer>
        </QuickViewWrapper>
      </Root>
      {isLoginModalOpen && (
        <LoginModal
          isOpen
          onClose={closeLoginModal}
          instruction={t("follow-login-instruction", {
            username: user.username,
          })}
        />
      )}
    </>
  );
};

export default ProfileQuickView;
