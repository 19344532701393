import styled from "styled-components";
import { Paragraph12Bold } from "../../Typography/Paragraph12";
import React from "react";

const StyledWarning = styled.div`
  background: ${(props) => props.theme.red}19;
  border: 1px solid ${(props) => props.theme.red}33;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  margin-bottom: 12px;
  text-align: center;
  padding: 8px;

  p {
    opacity: 1;
    color: ${(props) => props.theme.red};
  }
`;

interface Props {
  message: string;
}

const Warning = ({ message }: Props) => (
  <StyledWarning>
    <Paragraph12Bold>{message}</Paragraph12Bold>
  </StyledWarning>
);

export default Warning;
