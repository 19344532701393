import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";
import { useStaking } from "../common/hooks/useStaking";
import { useSession } from "../queries/authentication.query";

interface AccountDropdownValues {
  isOpen: boolean;
  isWalletMenuOpen: boolean;
  isDepositStakeMenuOpen: boolean;
  isWithdrawStakeMenuOpen: boolean;
  activeTab: number;
  isStakingOpen: boolean;
  isTransactionListMenuOpen: boolean;
  openStakingSection: () => void;
  setActiveTab: Dispatch<SetStateAction<number>>;
  toggleDropdown: () => void;
  handleClose: () => void;
  openWalletMenu: () => void;
  handleWalletMenuClose: () => void;
  openDepositStakeMenu: () => void;
  handleDepositStakeMenuClose: () => void;
  openWithdrawStakeMenu: () => void;
  handleWithdrawStakeMenuClose: () => void;
  openTransactionListMenu: () => void;
  handleTransactionListMenuClose: () => void;
}

export const AccountDrodownContext = createContext<AccountDropdownValues>({
  isOpen: false,
  isWalletMenuOpen: false,
  isDepositStakeMenuOpen: false,
  isWithdrawStakeMenuOpen: false,
  activeTab: 0,
  isStakingOpen: false,
  isTransactionListMenuOpen: false,
  openStakingSection: () => {},
  setActiveTab: () => {},
  toggleDropdown: () => {},
  handleClose: () => {},
  openWalletMenu: () => {},
  handleWalletMenuClose: () => {},
  openDepositStakeMenu: () => {},
  handleDepositStakeMenuClose: () => {},
  handleWithdrawStakeMenuClose: () => {},
  openWithdrawStakeMenu: () => {},
  openTransactionListMenu: () => {},
  handleTransactionListMenuClose: () => {},
});

export const AccountDropDownProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isWalletMenuOpen, setIsWalletMenuOpen] = useState(false);
  const [isDepositStakeMenuOpen, setIsDepositStakeMenuOpen] = useState(false);
  const [isWithdrawStakeMenuOpen, setIsWithdrawStakeMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [isStakingOpen, setIsStakingOpen] = useState(false);
  const [isTransactionListMenuOpen, setIsTransactionListMenuOpen] =
    useState(false);

  const { isLoggedIn } = useSession();
  const { clearTransaction } = useStaking();

  useEffect(() => {
    // close drop down when not logged in
    if (!isLoggedIn) {
      setIsOpen(false);
    }
  }, [isLoggedIn]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const openWalletMenu = () => {
    setIsOpen(false);
    setIsWalletMenuOpen(true);
  };

  const handleWalletMenuClose = () => {
    // close wallet drop down
    setIsWalletMenuOpen(false);
    // open main drop down
    setIsOpen(true);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const openDepositStakeMenu = () => {
    clearTransaction();
    setIsOpen(false);
    setIsDepositStakeMenuOpen(true);
  };

  const handleDepositStakeMenuClose = () => {
    setIsOpen(true);
    setIsDepositStakeMenuOpen(false);
  };

  const openWithdrawStakeMenu = () => {
    clearTransaction();
    setIsOpen(false);
    setIsWithdrawStakeMenuOpen(true);
  };

  const handleWithdrawStakeMenuClose = () => {
    setIsOpen(true);
    setIsWithdrawStakeMenuOpen(false);
  };

  const openStakingSection = () => {
    setActiveTab(1);
    setIsStakingOpen(true);
  };

  const openTransactionListMenu = () => {
    setIsOpen(false);
    setIsTransactionListMenuOpen(true);
  };

  const handleTransactionListMenuClose = () => {
    setIsOpen(false);
    setIsTransactionListMenuOpen(false);
  };

  return (
    <AccountDrodownContext.Provider
      value={{
        isOpen,
        isWalletMenuOpen,
        isDepositStakeMenuOpen,
        isWithdrawStakeMenuOpen,
        activeTab,
        isStakingOpen,
        isTransactionListMenuOpen,
        openStakingSection,
        setActiveTab,
        toggleDropdown,
        handleClose,
        openWalletMenu,
        handleWalletMenuClose,
        openDepositStakeMenu,
        handleDepositStakeMenuClose,
        openWithdrawStakeMenu,
        handleWithdrawStakeMenuClose,
        openTransactionListMenu,
        handleTransactionListMenuClose,
      }}
    >
      {children}
    </AccountDrodownContext.Provider>
  );
};

export const useAccountDropdown = () => {
  return useContext(AccountDrodownContext);
};
