import { css } from "styled-components";

const styles = css`
  .rc-tooltip {
    position: absolute;
  }
  .rc-tooltip-hidden {
    display: none;
  }
`;

export default styles;
