import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import Icon from "../../Icon/Icon";
import {
  Divider,
  LinkBody,
  LinksContainer,
  LinksContent,
  LinkSectionTitle,
  LinkSectionTitleContainer,
  LinksSection,
  StyledLink,
} from "./shared";
import StaticLink from "../../StaticLink/StaticLink";

interface IProps {
  isTablet: boolean;
}

const BrowserLinks = ({ isTablet }: IProps) => {
  const { t } = useTranslation();

  const [bodyHeight, setBodyHeight] = useState<number | null>(null);
  const isActive = bodyHeight !== null;
  const browseRef: any = useRef(null);

  const handleOnAccordionClick = () => {
    if (isActive) {
      setBodyHeight(null);
    } else {
      setBodyHeight(browseRef.current.clientHeight);
    }
  };

  const Links = () => (
    <LinksContainer isTablet={isTablet}>
      <StaticLink href={routes.marketplace.root}>
        <StyledLink>{t("footer-section-link-market")}</StyledLink>
      </StaticLink>
    </LinksContainer>
  );

  return (
    <>
      <Divider />

      <LinksSection>
        <LinkSectionTitleContainer
          isTablet={isTablet}
          isActive={isActive}
          onClick={handleOnAccordionClick}
        >
          <LinkSectionTitle>
            {t("footer-section-link-title-browse")}
          </LinkSectionTitle>
          {isTablet && <Icon name="chevronDown" />}
        </LinkSectionTitleContainer>
        {isTablet ? (
          <LinkBody active={isActive} bodyHeight={bodyHeight}>
            <LinksContent ref={browseRef}>
              <Links />
            </LinksContent>
          </LinkBody>
        ) : (
          <Links />
        )}
      </LinksSection>
    </>
  );
};

export default BrowserLinks;
