import styled, { useTheme } from "styled-components";
import Typography from "../../Typography/Typography";
import { ListingStatusEnum } from "../../../commonEnums";
import { Dot } from "../../styled";
import { useTranslation } from "react-i18next";
import {
  IListing,
  ITransactionEvent,
  TransactionActionEnum,
  TransactionStatusEnum,
} from "../../../commonTypes";
import { getBlockExplorerTransactionUrl } from "../../../../helpers/transactions";
import React, { useEffect, useState } from "react";
import { nftService } from "../../../../api/nftService";
import { routes } from "../../../../routes";
import { listingService } from "../../../../api/listingService";
import { useWeb3 } from "@chainsafe/web3-context";
import {
  getLocaleDateAndMonth,
  getLocaleTime,
} from "../../../../helpers/dateTime";
import { useTokenQuery } from "../../../../queries/token.query";
import { ethers } from "ethers";

interface Props {
  event: ITransactionEvent;
  onClick: (event: ITransactionEvent, redirectUrl: string) => void;
}

const Wrapper = styled.a`
  border-radius: ${(props) => props.theme.borderRadius.md};
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 16px;
  display: flex;
  margin-bottom: 10px;
  text-decoration: none;
`;

const Time = styled.div`
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 12px;
  width: fit-content;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  text-align: center;
  margin-right: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Description = styled.div`
  margin-right: 67px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
`;

const StatusDot = styled(Dot)`
  margin-left: 6px;
`;

const StyledTypography = styled(Typography)<{ secondary?: boolean }>`
  ${(props) => (props.secondary ? `opacity: 0.5` : "")}
`;

const Amount = styled(StyledTypography)`
  font-size: 20px;
  line-height: 23.44px;
`;

const DescriptionText = styled(StyledTypography)`
  ${(props) => (props.secondary ? `opacity: 0.6` : "")}
  line-height: 14px;
`;

const Date = styled(StyledTypography)`
  ${(props) => (props.secondary ? `opacity: 0.5` : "opacity: 0.9")};
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 0.02em;
`;

const TimeText = styled(Date)``;

const StatusText = styled(DescriptionText)``;
const StatusWrapper = styled.div`
  display: flex;
`;

const TransactionItem = ({
  event: {
    timestamp,
    amount,
    status,
    action,
    hash,
    listingId,
    blockNumber,
    contractAddress,
    chain,
    tokenAddress,
  },
  onClick,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { address } = useWeb3();
  const { data: tokens } = useTokenQuery(chain);

  const [redirectUrl, setRedirectUrl] = useState(
    getBlockExplorerTransactionUrl(chain!, hash)
  );
  const [txStatus, setTxStatus] = useState<TransactionStatusEnum>(status);

  const isStakeAction =
    action === TransactionActionEnum.deposit ||
    action === TransactionActionEnum.withdraw;

  useEffect(() => {
    async function fetchRedirectUrl() {
      if (isStakeAction) {
        // link to etherscan
        const url = getBlockExplorerTransactionUrl(chain!, hash);
        setRedirectUrl(url);
      } else {
        // link to listing
        if (action === TransactionActionEnum.bid) {
          const listing: IListing = (
            await listingService.fetchListing(listingId!)
          ).data;
          setRedirectUrl(routes.marketplace.id(listing.music_id, listing.id));

          // have we been outbid?
          if (
            listing.buyer_address?.toLowerCase() === address!.toLowerCase() &&
            listing.amount === amount
          ) {
            setTxStatus(
              listing.status === ListingStatusEnum.active
                ? TransactionStatusEnum.pending
                : TransactionStatusEnum.complete
            );
          } else {
            setTxStatus(TransactionStatusEnum.outbid);
          }

          if (listing.status === ListingStatusEnum.cancelled) {
            setTxStatus(TransactionStatusEnum.error);
          }
        } else {
          const res = await nftService.fetchNfts({
            where: {
              "metadata.mint_tx_hash": hash,
            },
          });

          const [nft] = res.data.items;

          if (nft) {
            const url = routes.marketplace.id(nft.music_id, nft.listing_id);
            setRedirectUrl(url);
          }
        }
      }
    }

    if (address) {
      fetchRedirectUrl();
    }
  }, [isStakeAction, action, hash, listingId, address, amount]);

  const colorMap = {
    pending: theme.accent.normal,
    complete: theme.greenColor,
    error: theme.errorColor,
    outbid: theme.errorColor,
  };

  const statusMap = {
    pending: t("transaction-pending"),
    complete: t("transaction-completed"),
    error: t("transaction-error"),
    outbid: t("transaction-outbid"),
  };

  const isSecondary = txStatus !== TransactionStatusEnum.pending;

  const handleTransactionItemClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(
      {
        timestamp,
        amount,
        status,
        action,
        hash,
        listingId,
        blockNumber,
        contractAddress,
        senderAddress: address!,
        tokenAddress,
      },
      redirectUrl
    );
  };

  // TODO: create helper for this?
  const token = tokens?.tokens?.find(
    (asset) =>
      asset?.address?.toLowerCase() === tokenAddress?.toLowerCase() &&
      asset.chain.id == chain?.id
  );

  return (
    <Wrapper href={redirectUrl} onClick={handleTransactionItemClick}>
      <Time>
        <Date size="md" weight="500" align="center" secondary={isSecondary}>
          {getLocaleDateAndMonth(timestamp)}
        </Date>
        <TimeText weight="300" align="center" secondary={isSecondary}>
          {getLocaleTime(timestamp)}
        </TimeText>
      </Time>

      <Description>
        <Amount heading size="md" weight="500" secondary={isSecondary}>
          {action === TransactionActionEnum.buy && "-"}
          {token && ethers.utils.formatUnits(amount, token?.decimals)}{" "}
          {token?.symbol}
        </Amount>
        <DescriptionText size="sm" secondary={isSecondary} weight="300">
          {t(`transaction-${action}`)}
        </DescriptionText>
      </Description>

      <Status>
        <StatusText weight="300" size="sm">
          {chain?.name}
        </StatusText>
        <StatusWrapper>
          <StatusText weight="300" secondary={isSecondary} size="sm">
            {statusMap[txStatus]}{" "}
          </StatusText>
          <StatusDot color={colorMap[txStatus]} />
        </StatusWrapper>
      </Status>
    </Wrapper>
  );
};

export default TransactionItem;
