import { captureException } from "@sentry/nextjs";
import { IListing, IListingIssueDetails } from "../common/commonTypes";
import { useQuery } from "react-query";
import { QueryKeys } from "../common/queryKeys";
import { listingService } from "../api/listingService";

export const useListingIssuesQuery = (listing?: IListing) => {
  const { data } = useQuery(
    [QueryKeys.listingIssues, listing?.id],
    async (): Promise<IListingIssueDetails | undefined> => {
      try {
        // find all the listings for this music
        const listings = await listingService.fetchListings({
          where: {
            music_id: listing?.music_id,
          },
          order: [["created", "ASC"]],
        });

        // find the issue number for this listing by order of date created
        const issueNumber = listings.data.items.findIndex(
          (l: IListing) => l.id === listing!.id
        );

        return {
          totalIssues: listings.data.count,
          issueNumber: issueNumber + 1,
        };
      } catch (error) {
        captureException(error);
      }
    },
    {
      enabled: Boolean(listing?.id),
      staleTime: 10 * 60 * 1000,
    }
  );

  return data;
};
