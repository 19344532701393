import styled from "styled-components";
import { Heading6Bold } from "../../Typography/Heading6";
import Icon from "../Icon/Icon";

const Top = styled.div`
  position: relative;
  text-align: center;

  svg {
    position: absolute;
    left: 0;
    cursor: pointer;
  }

  padding-top: 12px;
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.lightBackgroundColor};
  margin-bottom: 24.5px;
`;

interface MenuNavProps {
  onBackClicked?: () => void;
  title: string;
}

const MenuNav = (props: MenuNavProps) => {
  return (
    <Top>
      {props.onBackClicked && (
        <Icon name="chevronLeft" onClick={props.onBackClicked} />
      )}
      <Heading6Bold>{props.title}</Heading6Bold>
    </Top>
  );
};

export default MenuNav;
