import Menu from "../Menu";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";

import useChain from "../../../hooks/useChain";
import { useStaking } from "../../../hooks/useStaking";
import DepositStake from "./DepositStake";

const DepositStakeMenu = () => {
  const {
    isDepositStakeMenuOpen,
    handleDepositStakeMenuClose,
    openTransactionListMenu,
  } = useAccountDropdown();
  const { clearTransaction } = useStaking();
  const { deployment } = useChain();

  const handleClose = () => {
    handleDepositStakeMenuClose();
    clearTransaction();
  };

  const handleOpenTransactions = () => {
    handleDepositStakeMenuClose();
    // clearTransaction();
    openTransactionListMenu();
  };

  return (
    <Menu isOpen={isDepositStakeMenuOpen} onClose={handleClose}>
      <DepositStake
        handleDepositStakeMenuClose={handleClose}
        onContinueClicked={handleOpenTransactions}
        deployment={deployment}
      />
    </Menu>
  );
};

export default DepositStakeMenu;
