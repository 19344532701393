import React from "react";
import styled from "styled-components";
import { BidTypeEnum } from "../../commonEnums";
import BidButton from "./BidButton";
import BidDetails from "./BidDetails";
import Card from "../Card/Card";
import { IListing, IMusic, INft } from "../../commonTypes";
import GlobalPlayerCard from "../GlobalPlayerCard/GlobalPlayerCard";
import UserAvatar from "../UserAvatar";
import { Heading6Bold } from "../../Typography/Heading6";
import Typography from "../Typography/Typography";
import { useTranslation } from "react-i18next";
import { routes } from "../../../routes";
import Icon from "../Icon/Icon";
import Overlay from "../Overlay/Overlay";
import NftItemsPopup from "./NftItemsPopup";
import { IMAGE_SIZES } from "../../../constants/imageSizes";
import StaticLink from "../StaticLink/StaticLink";
import { useSession } from "../../../queries/authentication.query";
import { shouldShowTimer } from "../../../helpers/listingHelper";
import AddressAvatar from "../AddressAvatar/AddressAvatar";
import { useListingOwnerQuery } from "../../../queries/listingOwner.query";
import { getListingBid } from "../../../helpers/bids";
import { useListingIssuesQuery } from "../../../queries/listingIssues.query";

const DetailsContainer = styled.div`
  cursor: pointer;
  margin-top: 17px;
  padding-bottom: 19px;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
`;

const TrackNameContainer = styled.div`
  flex: 1;
  padding-right: 8px;
`;

const TrackName = styled(Heading6Bold)`
  color: ${({ theme }) => theme.primaryColor};
`;

const NftItemsWrapper = styled.div``;

const PendingInfo = styled(Typography)`
  opacity: 0.5;
  text-decoration: none;
  transition: opacity 200ms linear;

  &:hover {
    opacity: 0.8;
  }
`;

const BottomSection = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: flex-end;
`;

const BidDetailsWrapper = styled.div`
  flex: 1;
`;

const OwnerWrapper = styled.div<{ $isLoaded: boolean }>`
  margin-left: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background-color: ${({ theme }) => theme.lightBackgroundColor};

  & > * {
    transition: opacity 100ms ease-in;
    opacity: ${({ $isLoaded }) => ($isLoaded ? 1 : 0)};
  }
`;

interface IProps {
  music: IMusic;
  redirectUrl: string;
  playlist?: IMusic[];
  listing?: IListing;
  nft?: INft;
  className?: string;
}

const NftCard = ({
  music,
  playlist,
  redirectUrl,
  listing,
  nft,
  className,
}: IProps) => {
  const { t } = useTranslation();
  const { artist } = music;
  const {
    ending,
    type,
    status,
    amount: price,
    with_rights: withRights,
  } = listing || {};
  const bid = getListingBid(listing);
  const { owner, isConfirmedOwner, ownerAddress, isLoaded } =
    useListingOwnerQuery(listing, nft);

  const issueDetails = useListingIssuesQuery(listing);

  const { user: loggedUser } = useSession();

  const isTrackPending =
    loggedUser && !artist.approved_artist && loggedUser.id === artist.id;

  const showTimer = type === BidTypeEnum.bid || shouldShowTimer(ending);

  return (
    <Card className={className}>
      <GlobalPlayerCard
        status={status}
        music={music}
        playlist={playlist}
        artist={artist}
        auctionTime={showTimer ? ending : undefined}
        coverResizeWidth={IMAGE_SIZES.trackCoverArt.medium}
      />
      <StaticLink href={redirectUrl}>
        <DetailsContainer>
          <TopSection>
            <TrackNameContainer>
              <TrackName>{music?.title}</TrackName>
              {isTrackPending && (
                <PendingInfo
                  size="sm"
                  secondary
                  forwardedAs={StaticLink}
                  href={routes.uploadMusic}
                  onClick={(event: React.MouseEvent<HTMLElement>) =>
                    event.stopPropagation()
                  }
                >
                  {`(${t("pending-approval")})`}
                </PendingInfo>
              )}
            </TrackNameContainer>
            <NftItemsWrapper onClick={(event) => event.stopPropagation()}>
              <Overlay
                trigger={["hover", "click"]}
                placement="topRight"
                align={{
                  offset: [-8, -24],
                }}
                overlay={
                  <NftItemsPopup music={music} withRights={withRights} />
                }
              >
                <div>
                  <Icon name="list" size="18px" />
                </div>
              </Overlay>
            </NftItemsWrapper>
          </TopSection>
          <Typography>{listing?.deployment.chain.name}</Typography>
          {(!!price || !!owner) && (
            <BottomSection>
              <BidDetailsWrapper>
                {!!price && (
                  <BidDetails
                    price={price}
                    bidType={type}
                    asset={listing?.asset}
                    issueDetails={issueDetails}
                  />
                )}
              </BidDetailsWrapper>
              {owner && (
                <OwnerWrapper $isLoaded={isLoaded}>
                  {isConfirmedOwner ? (
                    <UserAvatar user={owner} size="26px" isBottomAligned />
                  ) : (
                    <AddressAvatar address={ownerAddress} size="26px" />
                  )}
                </OwnerWrapper>
              )}
            </BottomSection>
          )}
        </DetailsContainer>
      </StaticLink>
      <BidButton bid={bid} redirectUrl={redirectUrl} listing={listing} />
    </Card>
  );
};

export default NftCard;
