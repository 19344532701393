import {
  IApiPage,
  IApiResponse,
  IMusicListing,
  IQueryParams,
} from "../common/commonTypes";
import { ICreateMusicDto } from "../common/dtos";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { fetchWrapperProtected } from "../helpers/fetchWrapperProtected";
import { createSequalizeUrl } from "../helpers/requestHelper";
import { getEnv } from "../helpers/env";

const NEXT_PUBLIC_API_URL = getEnv("NEXT_PUBLIC_API_URL");

export const musicService = {
  fetchMusics: async (params: IQueryParams) => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("music", params)
    );

    return response;
  },

  createMusic: async (music: ICreateMusicDto) => {
    return fetchWrapperProtected.post(`${NEXT_PUBLIC_API_URL}/music`, music);
  },

  updateMusic: async (music_id: string, music: Partial<ICreateMusicDto>) => {
    return fetchWrapperProtected.put(
      `${NEXT_PUBLIC_API_URL}/music/${music_id}`,
      music
    );
  },

  fetchMusic: async (id: string, params: IQueryParams) => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl(`music/${id}`, params)
    );

    return response;
  },

  fetchMusicListingsForArtist: async (
    params: IQueryParams
  ): Promise<IApiPage<IMusicListing>> => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl(`music_listing`, params)
    );

    return response.data;
  },

  fetchIsTrackNameUnique: async (
    trackName: string,
    artistId: string
  ): Promise<boolean> => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("music", {
        where: {
          title: {
            $iLike: trackName.trim(),
          },
          artist_id: artistId,
        },
      })
    );
    return response.data.count === 0;
  },
};
