import React, { useRef } from "react";
import styled from "styled-components";
import Cover from "../Cover";
import { IMAGE_SIZES } from "../../../constants/imageSizes";
import StaticLink from "../StaticLink/StaticLink";
import { routes } from "../../../routes";
import Typography from "../Typography/Typography";
import { getFlexCenter, getHorizontalGap, getVerticalGap } from "../../styles";
import RangeProgressBar from "../RangeProgressBar/RangeProgressBar";
import { useGlobalPlayer } from "../../../context/GlobalPlayerProvider";
import LoadingSpinner from "../LoadingSpinner";
import { IconKeys } from "../../commonTypes";
import { formatTrackDuration } from "../../../helpers/dateTime";
import {
  Root,
  TrackCoverWrapper,
  TrackInfo,
  ControlButton,
  ControlIcon,
} from "./sharedStyles";
import TrackPreviewOverlay from "./TrackPreviewOverlay";

const SideArea = styled.div`
  width: 30%;
`;

const TrackInfoArea = styled(SideArea)`
  display: flex;
  align-items: center;
`;

const Link = styled(Typography)`
  &:hover {
    text-decoration: underline;
  }
`;

const PlayerArea = styled.div`
  flex: 1;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  ${getVerticalGap("8px")};
`;

const PlayerAreaRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${getHorizontalGap("8px")};
`;

const PlayerAreaControls = styled(PlayerAreaRow)`
  justify-content: center;
`;

const PlayerAreaProgress = styled(PlayerAreaRow)`
  padding-bottom: 4px;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  ${getFlexCenter}
`;

const ActionsArea = styled(SideArea)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${getHorizontalGap("12px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const VolumePanel = styled.div`
  padding-right: 4px;
  ${getHorizontalGap("12px")};
  display: flex;
  align-items: center;
`;

const VolumeProgress = styled(RangeProgressBar)`
  & {
    width: 100px;
  }
`;

const GlobalPlayer = () => {
  const playerContainer = useRef<HTMLDivElement | null>(null);
  const {
    isOpen,
    closePlayer,
    track,
    duration,
    playProgress,
    isPlaying,
    volume,
    togglePlay,
    onProgressChange,
    onVolumeChange,
    toggleMute,
    isBuffering,
    previousTrack,
    previous,
    previousDisabled,
    nextTrack,
    next,
    nextDisabled,
    repeatMode,
    toggleRepeatMode,
  } = useGlobalPlayer();

  if (!track) {
    return null;
  }

  const getVolumeIcon = (): IconKeys => {
    if (volume === 0) {
      return "volumeMute";
    }

    if (volume < 0.5) {
      return "volumeMedium";
    }

    return "volume";
  };

  return (
    <>
      <Root $isOpen={isOpen} ref={playerContainer}>
        <TrackInfoArea>
          <StaticLink href={routes.marketplace.id(track.id)}>
            <TrackCoverWrapper>
              <Cover
                mime={track?.cover.mime}
                url={track?.cover.url}
                size={IMAGE_SIZES.trackCoverArt.xs}
              />
            </TrackCoverWrapper>
          </StaticLink>
          <TrackInfo>
            <Link
              truncate
              element={StaticLink}
              weight="400"
              href={routes.marketplace.id(track.id)}
            >
              {track.title}
            </Link>
            <Link
              truncate
              element={StaticLink}
              size="sm"
              secondary
              href={routes.user.url(track.artist.uri)}
            >
              {track.artist.username}
            </Link>
          </TrackInfo>
        </TrackInfoArea>
        <PlayerArea>
          <PlayerAreaControls>
            <TrackPreviewOverlay
              track={previousTrack}
              placement="topRight"
              attachContainer={playerContainer}
            >
              <ControlButton onClick={previous} disabled={previousDisabled}>
                <ControlIcon name="previousTrack" size="24px" />
              </ControlButton>
            </TrackPreviewOverlay>

            {isBuffering ? (
              <SpinnerWrapper>
                <LoadingSpinner />
              </SpinnerWrapper>
            ) : (
              <ControlButton onClick={togglePlay}>
                <ControlIcon name={isPlaying ? "pause" : "play"} size="18px" />
              </ControlButton>
            )}

            <TrackPreviewOverlay
              track={nextTrack}
              placement="topLeft"
              attachContainer={playerContainer}
            >
              <ControlButton onClick={next} disabled={nextDisabled}>
                <ControlIcon name="nextTrack" size="24px" />
              </ControlButton>
            </TrackPreviewOverlay>
          </PlayerAreaControls>
          <PlayerAreaProgress>
            <Typography secondary size="sm" lineHeight="1">
              {formatTrackDuration(playProgress)}
            </Typography>
            <RangeProgressBar
              progress={playProgress}
              onProgressChange={onProgressChange}
              min={0}
              max={duration}
              step={0.1}
            />
            <Typography secondary size="sm" lineHeight="1">
              {formatTrackDuration(duration)}
            </Typography>
          </PlayerAreaProgress>
        </PlayerArea>
        <ActionsArea>
          <ControlButton onClick={toggleRepeatMode}>
            <ControlIcon name="repeat" size="18px" $active={repeatMode} />
          </ControlButton>
          <VolumePanel>
            <ControlButton onClick={toggleMute}>
              <ControlIcon name={getVolumeIcon()} size="22px" />
            </ControlButton>
            <VolumeProgress
              min={0}
              progress={volume}
              max={1}
              step={0.01}
              onProgressChange={onVolumeChange}
            />
          </VolumePanel>
          <ControlButton onClick={closePlayer}>
            <ControlIcon name="close" size="16px" />
          </ControlButton>
        </ActionsArea>
      </Root>
    </>
  );
};

export default GlobalPlayer;
