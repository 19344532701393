import { ethers } from "ethers";
import {
  IApiResponse,
  IAsset,
  IDeployment,
  IListing,
  ITransactionEvent,
} from "../common/commonTypes";
import {
  IBidMessage,
  IWithdrawal,
  TransactionEvent,
} from "../common/hooks/useStaking";
import NOIZDv1 from "./strategies/NOIZDv1";
import NOIZDv2 from "./strategies/NOIZDv2";
import { TransactionResponse } from "@ethersproject/abstract-provider";

export interface IStakingClient {
  stake(amount: string, asset: IAsset): Promise<TransactionResponse>;
  withdrawInstant(
    withdrawal: IWithdrawal,
    userSignature: string,
    ownerSignature: string
  ): Promise<TransactionResponse>;
  approveToken(asset: IAsset, amount: string): Promise<TransactionResponse>;
  signBid(bid: IBidMessage): Promise<string>;
  getStake(user: string, asset: IAsset): Promise<ethers.BigNumber>;
  getBalance(user: string, asset: IAsset): Promise<ethers.BigNumber>;
  getTokenAllowance(user: string, asset: IAsset): Promise<ethers.BigNumber>;
  onStakeEvent(
    address: string,
    asset: string,
    callback: (event: TransactionEvent) => void
  ): void;
  onWithdrawEvent(
    address: string,
    asset: string,
    nonce: number,
    callback: (event: TransactionEvent) => void
  ): void;
  placeBid(
    listing: IListing,
    amount: string,
    address: string
  ): Promise<IApiResponse>;
  signWithdrawal(withdrawal: IWithdrawal): Promise<{ signature: string }>;
  getWithdrawalData(
    amount: string,
    token: IAsset,
    user: string
  ): Promise<IWithdrawal>;
  getStakeEvents(address: string): Promise<ITransactionEvent[]>;
  getWithdrawEvents(address: string): Promise<ITransactionEvent[]>;
  getPurchaseEvents(address: string): Promise<ITransactionEvent[]>;
  getSaleEvents(address: string): Promise<ITransactionEvent[]>;
}

export class StakingClient implements IStakingClient {
  strategy: IStakingClient;

  constructor(deployment: IDeployment, signer: ethers.providers.JsonRpcSigner) {
    switch (deployment.name) {
      case "NOIZD_v1":
        this.strategy = new NOIZDv1(deployment, signer);
        break;
      case "NOIZD_v2":
      case "NOIZD_local":
        this.strategy = new NOIZDv2(deployment, signer);
        break;

      default:
        this.strategy = new NOIZDv2(deployment, signer);

        break;
    }
  }
  getWithdrawEvents(address: string): Promise<ITransactionEvent[]> {
    return this.strategy.getWithdrawEvents(address);
  }
  getSaleEvents(address: string): Promise<ITransactionEvent[]> {
    return this.strategy.getSaleEvents(address);
  }
  getStakeEvents(address: string): Promise<ITransactionEvent[]> {
    return this.strategy.getStakeEvents(address);
  }
  getPurchaseEvents(address: string): Promise<ITransactionEvent[]> {
    return this.strategy.getPurchaseEvents(address);
  }
  onWithdrawEvent(
    address: string,
    asset: string,
    nonce: number,
    callback: (event: TransactionEvent) => void
  ): void {
    return this.strategy.onWithdrawEvent(address, asset, nonce, callback);
  }
  getWithdrawalData(amount: string, token: IAsset, user: string) {
    return this.strategy.getWithdrawalData(amount, token, user);
  }
  signWithdrawal(withdrawal: IWithdrawal): Promise<{ signature: string }> {
    return this.strategy.signWithdrawal(withdrawal);
  }
  placeBid(
    listing: IListing,
    amount: string,
    address: string
  ): Promise<IApiResponse> {
    return this.strategy.placeBid(listing, amount, address);
  }
  approveToken(asset: IAsset, amount: string): Promise<TransactionResponse> {
    return this.strategy.approveToken(asset, amount);
  }
  withdrawInstant(
    withdrawal: IWithdrawal,
    userSignature: string,
    ownerSignature: string
  ): Promise<TransactionResponse> {
    return this.strategy.withdrawInstant(
      withdrawal,
      userSignature,
      ownerSignature
    );
  }
  getTokenAllowance(user: string, asset: IAsset): Promise<ethers.BigNumber> {
    return this.strategy.getTokenAllowance(user, asset);
  }
  stake(amount: string, asset: IAsset): Promise<TransactionResponse> {
    return this.strategy.stake(amount, asset);
  }
  getBalance(user: string, asset: IAsset): Promise<ethers.BigNumber> {
    return this.strategy.getBalance(user, asset);
  }
  getStake(user: string, asset: IAsset): Promise<ethers.BigNumber> {
    return this.strategy.getStake(user, asset);
  }

  signBid(bid: IBidMessage): Promise<string> {
    if (!bid.listing || !bid.staker || !bid.target || !bid.amount) {
      throw Error("Missing required fields from Bid message");
    }

    return this.strategy.signBid(bid);
  }

  onStakeEvent(
    user: string,
    asset: string,
    callback: (event: TransactionEvent) => void
  ): void {
    return this.strategy.onStakeEvent(user, asset, callback);
  }
}
