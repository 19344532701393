import React, { useEffect, useState, useRef } from "react";
import Modal, { IModalProps, ModalContent } from "../Modal/Modal";
import { useGlobalPlayer } from "../../../context/GlobalPlayerProvider";
import Cover from "../Cover";
import { IMAGE_SIZES } from "../../../constants/imageSizes";
import styled from "styled-components";
import Typography from "../Typography/Typography";
import {
  getAbsoluteCenter,
  getAbsoluteFill,
  getFlexCenter,
  getHorizontalGap,
  getVerticalGap,
} from "../../styles";
import StaticLink from "../StaticLink/StaticLink";
import { routes } from "../../../routes";
import RangeProgressBar from "../RangeProgressBar/RangeProgressBar";
import { formatTrackDuration } from "../../../helpers/dateTime";
import { ControlButton, ControlIcon } from "./sharedStyles";
import LoadingSpinner from "../LoadingSpinner";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { useRouter } from "next/router";
import { isEventOutside } from "../../../helpers/domHelper";

const StyledModal = styled(Modal)<{ $isVisible: boolean }>`
  overflow: hidden;
  max-height: 100%;

  & > * {
    transform: translateY(${({ $isVisible }) => ($isVisible ? 0 : 100)}%);
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition: transform 200ms ease-out, opacity 200ms linear;
    transition-delay: 100ms;
  }

  ${ModalContent} {
    background-image: linear-gradient(
      ${({ theme }) => theme.backgroundColor},
      ${({ theme }) => theme.darkBackgroundColor} 85%
    );
  }
`;

const Root = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CoverFill = styled.div`
  position: relative;
  flex: 1;
`;

const CoverContainer = styled.div`
  ${getAbsoluteFill};
`;

const CoverWrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  aspect-ratio: 1;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  background: ${({ theme }) => theme.backgroundColor};
  filter: drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 1px)
    drop-shadow(rgba(0, 0, 0, 0.3) 0px 0px 10px);
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileS}) {
    max-width: 248px;
  }
`;

const SpinnerWrapper = styled.div`
  ${getAbsoluteCenter};
`;

const TrackInfo = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${getVerticalGap("4px")};
`;

const Link = styled(Typography)`
  display: block;
`;

const ArtistLink = styled(Link)`
  width: auto;
  max-width: 100%;
`;

const PlayerArea = styled.div`
  margin-top: 24px;
`;

const DurationInfo = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Controls = styled.div`
  margin-top: 12px;
  position: relative;
  padding-bottom: env(safe-area-inset-bottom);
  ${getHorizontalGap("8px")};
  ${getFlexCenter};
`;

const RightControls = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
`;

const MobileControlButton = styled(ControlButton)`
  width: 40px;
  height: 40px;
  flex: 0 0 40px;
`;

interface IModalPlayerProps extends IModalProps {
  onClose: () => void;
}

const ModalPlayer = ({ onClose }: IModalPlayerProps) => {
  const router = useRouter();
  const {
    track,
    playProgress,
    onProgressChange,
    duration,
    togglePlay,
    isPlaying,
    isBuffering,
    previous,
    previousDisabled,
    next,
    nextDisabled,
    repeatMode,
    toggleRepeatMode,
  } = useGlobalPlayer();
  const [isVisible, setIsVisible] = useState(false);
  const progressBarRef = useRef<HTMLInputElement | null>(null);

  useUpdateEffect(() => {
    onClose();
  }, [router]);

  useEffect(() => {
    requestAnimationFrame(() => setIsVisible(true));
  }, []);

  // prevent touch events for scrolling and pull to refresh (except of progress bar touches)
  useEffect(() => {
    const preventDefault = (event: TouchEvent) => {
      if (isEventOutside(event, progressBarRef.current)) {
        event.preventDefault();
      }
    };

    document.body.addEventListener("touchmove", preventDefault, {
      passive: false,
    });

    return () => {
      document.body.removeEventListener("touchmove", preventDefault);
    };
  }, []);

  if (!track) {
    return null;
  }

  return (
    <StyledModal isOpen onClose={onClose} $isVisible={isVisible}>
      <Root>
        <CoverFill onClick={onClose}>
          <CoverContainer>
            <CoverWrapper>
              <Cover
                url={track.cover.url}
                mime={track.cover.mime}
                size={IMAGE_SIZES.trackCoverArt.medium}
              />
              {isBuffering && (
                <SpinnerWrapper>
                  <LoadingSpinner />
                </SpinnerWrapper>
              )}
            </CoverWrapper>
          </CoverContainer>
        </CoverFill>
        <TrackInfo>
          <Link
            size="xl"
            weight="bold"
            element={StaticLink}
            href={routes.marketplace.id(track.id)}
          >
            {track.title}
          </Link>
          <ArtistLink
            secondary
            element={StaticLink}
            href={routes.user.url(track.artist.uri)}
            truncate
          >
            {track.artist.username}
          </ArtistLink>
        </TrackInfo>
        <PlayerArea>
          <RangeProgressBar
            ref={progressBarRef}
            progress={playProgress}
            onProgressChange={onProgressChange}
            min={0}
            max={duration}
            step={0.1}
          />

          <DurationInfo>
            <Typography secondary size="sm">
              {formatTrackDuration(playProgress)}
            </Typography>
            <Typography secondary size="sm">
              {formatTrackDuration(duration)}
            </Typography>
          </DurationInfo>

          <Controls>
            <MobileControlButton onClick={previous} disabled={previousDisabled}>
              <ControlIcon name="previousTrack" size="24px" />
            </MobileControlButton>
            <MobileControlButton onClick={togglePlay}>
              <ControlIcon name={isPlaying ? "pause" : "play"} size="24px" />
            </MobileControlButton>
            <MobileControlButton onClick={next} disabled={nextDisabled}>
              <ControlIcon name="nextTrack" size="24px" />
            </MobileControlButton>

            <RightControls>
              <MobileControlButton onClick={toggleRepeatMode}>
                <ControlIcon name="repeat" $active={repeatMode} size="16px" />
              </MobileControlButton>
            </RightControls>
          </Controls>
        </PlayerArea>
      </Root>
    </StyledModal>
  );
};

export default ModalPlayer;
