import { IApiResponse, IListing } from "../common/commonTypes";
import { fetchWrapperProtected } from "../helpers/fetchWrapperProtected";
import { createSequalizeUrl } from "../helpers/requestHelper";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { getEnv } from "../helpers/env";

const NEXT_PUBLIC_API_URL = getEnv("NEXT_PUBLIC_API_URL");

export const bidService = {
  placeBid: async (
    listing: IListing,
    amount: string,
    address: string,
    data: { [key: string]: any }
  ) => {
    const response: IApiResponse = await fetchWrapperProtected.post(
      `${NEXT_PUBLIC_API_URL}/bid`,
      {
        type: listing.type,
        amount,
        address,
        listing_id: Number(listing.id),
        data,
      },
      "application/json"
    );
    return response;
  },
  fetchBids: async (address: string, chainId?: number) => {
    const where = {
      address,
    };

    // if (chainId) {
    //   (where as any)["listing"] = {
    //     deployment: {
    //       chain_id: chainId,
    //     },
    //   };
    // }

    const response: IApiResponse = await fetchWrapperProtected.get(
      createSequalizeUrl("bid", {
        where,
      })
    );
    return response;
  },
  fetchBidsForListing: async (listingId: string) => {
    const response: IApiResponse = await fetchWrapper.get(
      createSequalizeUrl("bid", {
        where: {
          listing_id: listingId,
        },
        order: [["created", "DESC"]],
      })
    );
    return response;
  },
};
