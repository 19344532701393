import React, { useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Logo from "../Logo/Logo";
import Nav from "./Nav";
import { INavLink } from "../../commonTypes";
import AccountDropdown from "./AccountDropdown";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import { routes } from "../../../routes";
import Button from "../Buttons/Button";
import ButtonOutlined from "../Buttons/ButtonOutlined";
import { getHorizontalGap, navbarHeight } from "../../styles";
import MainSearch from "../MainSearch/MainSearch";
import { useSearch } from "../../../context/SearchContext";
import IconButton from "../Buttons/IconButton";
import Icon from "../Icon/Icon";
import { useLoginRoute } from "../../hooks/useLoginRoute";
import TransactionListMenu from "../Menus/TransactionList/TransactionList";
import { useAccountDropdown } from "../../../context/AccountDropdownContext";
import { useWeb3 } from "@chainsafe/web3-context";
import { useSession } from "../../../queries/authentication.query";
import { zIndex } from "../../../constants/zIndex";

const MainContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: ${zIndex.navbar};
  overflow-y: hidden;
  width: 100%;
  background: ${({ theme }) => theme.navbarBackgroundColor};
  backdrop-filter: blur(7px);
  border-bottom: 1px solid ${({ theme }) => theme.darkBorderColor};
  padding: 0 50px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0 20px;
  }
`;

const Wrapper = styled.div`
  padding: 18px 0 18px 0;
  height: ${navbarHeight.desktop}px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: visible;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    height: ${navbarHeight.mobile}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 0;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const HideOnSearchOpen = styled.div<{ isSearchOpen: boolean }>`
  display: flex;

  ${({ isSearchOpen, theme }) =>
    isSearchOpen &&
    css`
      @media (max-width: ${theme.breakpoints.laptopL}) {
        display: none;
      }
    `}
`;

const Navigation = styled.div`
  display: grid;
  align-items: center;
  column-gap: 30px;
  grid-template-columns: auto auto auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    column-gap: 10px;
  }
`;

const Main = styled.div<{ $isVisible: boolean }>`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 100ms ease-in;
`;

const Menus = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  ${getHorizontalGap("10px")};

  div {
    margin: auto 0;
  }
`;

const Mobile = styled.div`
  display: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptopL}) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptopL}) {
    display: none;
  }
`;

const JoinButton = styled(Button)`
  margin-left: 12px;
`;
const UploadButton = styled(Button)`
  margin-right: 12px;
  text-align: center;
`;

const AppNavbar = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const loginPageRoute = useLoginRoute();
  const { isSearchExpanded, setIsSearchFocused } = useSearch();
  const { isLoggedIn, isFetched: isSessionFetched } = useSession();
  const { openTransactionListMenu } = useAccountDropdown();
  const { address } = useWeb3();

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  const navLinks: INavLink[] = [
    {
      name: t("app-navbar-home"),
      path: routes.home,
    },
    {
      name: t("app-navbar-market"),
      path: routes.marketplace.root,
    },
    {
      name: t("artists"),
      path: routes.artists,
    },
  ];

  const TxMenuButton = () => {
    return isLoggedIn && address ? (
      <IconButton onClick={openTransactionListMenu}>
        <Icon name="transaction" size="20px" />
      </IconButton>
    ) : null;
  };

  return (
    <MainContainer>
      <Wrapper>
        <HideOnSearchOpen isSearchOpen={isSearchExpanded}>
          <Navigation>
            <Logo />
            <Nav items={navLinks} />
          </Navigation>
        </HideOnSearchOpen>
        <Main $isVisible={isSessionFetched}>
          <MainSearch />
          <HideOnSearchOpen isSearchOpen={isSearchExpanded}>
            <Menus>
              <Mobile>
                <IconButton onClick={() => setIsSearchFocused(true)}>
                  <Icon name="search" size="20px" />
                </IconButton>
              </Mobile>
              <Mobile>
                <BurgerMenu
                  navLinks={navLinks}
                  isOpen={isBurgerMenuOpen}
                  setIsOpen={setIsBurgerMenuOpen}
                />
              </Mobile>
              <Mobile>
                <TxMenuButton />
              </Mobile>

              <ButtonContainer>
                {!router.pathname.includes(routes.auth.root) &&
                  router.pathname !== routes.uploadMusic && (
                    <UploadButton
                      href={routes.uploadMusic}
                      text={t("upload-track")}
                    />
                  )}
                {router.pathname !== routes.auth.login && !isLoggedIn && (
                  <ButtonOutlined
                    href={loginPageRoute}
                    text={t("app-navbar-login-button")}
                  />
                )}
                {router.pathname !== routes.auth.login && !isLoggedIn && (
                  <JoinButton href={routes.auth.join.root} text={t("join")} />
                )}
                <TxMenuButton />
              </ButtonContainer>
              <AccountDropdown />
            </Menus>
            <TransactionListMenu />
          </HideOnSearchOpen>
        </Main>
      </Wrapper>
    </MainContainer>
  );
};

export default AppNavbar;
