import React, { ReactNode } from "react";
import Link, { LinkProps } from "next/link";
import styled from "styled-components";

const StyledLink = styled.a`
  cursor: pointer;
  position: relative;
  text-decoration: none;
`;

export interface IStaticLinkProps extends LinkProps {
  children: ReactNode;
  target?: string;
  rel?: string;
  className?: string;
  external?: boolean;
}

const StaticLink = ({
  href,
  children,
  target,
  className,
  rel,
  external = false,
  ...props
}: IStaticLinkProps) => {
  if (external && typeof href === "string") {
    return (
      <StyledLink
        href={href}
        className={className}
        target={target || "_blank"}
        rel={rel || "noreferrer"}
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <Link passHref href={href} {...props}>
      <StyledLink className={className} target={target} rel={rel}>
        {children}
      </StyledLink>
    </Link>
  );
};

export default StaticLink;
