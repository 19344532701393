import { IChain, ITransactionEvent } from "../common/commonTypes";
import { localStorageHelper, LocalStorageKeys } from "./localStorageHelper";
import { captureException } from "@sentry/nextjs";
import { isClientEnv } from "./nextHelper";
import { stripTrailingSlash } from "./format";

export function getBlockExplorerTransactionUrl(
  chain: IChain,
  txHash: string
): string {
  if (!txHash || !chain) {
    return "";
  }

  try {
    const url = stripTrailingSlash(chain.block_explorer_url);

    return `${url}/tx/${txHash}`;
  } catch (error) {
    captureException(error);
    return "";
  }
}

export function getBlockExplorerAddressUrl(
  address: string,
  chain: IChain
): string {
  if (!address || !isClientEnv()) {
    return "";
  }

  try {
    const url = chain.block_explorer_url.replace(/\/+$/, "");

    return `${url}/address/${address}`;
  } catch (error) {
    captureException(error);
    return "";
  }
}

export function getTransactions(): ITransactionEvent[] {
  const transactions =
    localStorageHelper.get(LocalStorageKeys.transactions) ?? [];

  return transactions.map((tx: ITransactionEvent): ITransactionEvent => {
    return {
      ...tx,
      timestamp: new Date(tx.timestamp),
    };
  });
}

export function addTransaction(transaction: ITransactionEvent) {
  const transactions = getTransactions();
  transactions.push(transaction);
  localStorageHelper.set(LocalStorageKeys.transactions, transactions);
}
