import React from "react";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import styled from "styled-components";
import { getParagraph16BoldTypography } from "../../Typography";
import StaticLink from "../StaticLink/StaticLink";

interface IStyledLinkProps {
  selected: boolean;
}

const StyledItem = styled.div`
  display: inline-block;
  white-space: nowrap;
`;

const StyledLink = styled(StaticLink)<IStyledLinkProps>`
  display: block;
  color: ${(props: any) =>
    props.selected ? props.theme.accent.normal : props.theme.primaryColor};
  ${getParagraph16BoldTypography};
  line-height: 19px;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.accent.normal};
  }
`;

interface INavlinkProps {
  text: string;
  path: string;
}

const NavItem = ({ text, path }: INavlinkProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const selected = router.pathname === path;

  return (
    <StyledItem>
      <StyledLink
        className="web-transition-mid"
        selected={selected}
        href={path}
      >
        {t(text)}
      </StyledLink>
    </StyledItem>
  );
};

export default NavItem;
