import { t } from "i18next";
import React from "react";
import NoResults from "./NoResults/NoResults";

const ErrorPage = () => {
  return (
    <NoResults
      title={t("something-went-wrong")}
      subtitle={t("working-on-a-fix")}
    />
  );
};

export default ErrorPage;
