import { HTMLAttributes, useState } from "react";
import NextImage, { ImageProps } from "next/image";
import styled from "styled-components";
import { MimeEnum } from "../../commonEnums";
import { isGif } from "../../../helpers/fileHelper";
import { getResizedUrl } from "../../../helpers/imageHelper";
import { useIsClientRender } from "../../hooks/useIsClientRender";

interface ImageInterface {
  src: string;
  $isLoaded: boolean;
  $isError: boolean;
  $fadingDisabled?: boolean;
}

const StyledImage = styled(NextImage)<ImageInterface>`
  width: 100%;
  height: 100%;
  opacity: ${(props) =>
    props.src && (props.$isLoaded || props.$fadingDisabled) ? 1 : 0};
  visibility: ${(props) =>
    props.src && !props.$isError ? "visible" : "hidden"};
  transition: opacity 200ms;
  display: block;
  object-fit: cover;
`;

export interface IFadeImageProps extends ImageProps {
  mimeType?: MimeEnum;
  resizeWidth?: number;
}

export default function FadeImage({
  src,
  mimeType,
  resizeWidth,
  layout = "fill",
  ...props
}: IFadeImageProps & HTMLAttributes<HTMLImageElement>) {
  const isClientRender = useIsClientRender();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(false);
  const isGifImage = isGif(mimeType);
  const isDataUri =
    typeof src === "string" &&
    (src.startsWith("data:") || src.startsWith("blob:"));

  const commonProps = {
    onLoad: () => setIsLoaded(true),
    onError: () => setIsError(true),
    $isError: isError,
    $isLoaded: isLoaded,
    $fadingDisabled: isGifImage,
    alt: "",
    ...props,
  };

  if (isGifImage && !isClientRender) {
    return null;
  }

  if (isDataUri) {
    return <StyledImage as="img" key={src} src={src} {...commonProps} />;
  }

  const resizedSrc = getResizedUrl(src, resizeWidth);

  return (
    <StyledImage
      key={resizedSrc}
      src={resizedSrc}
      layout={layout}
      unoptimized={isGifImage}
      loading={isGifImage ? "eager" : "lazy"}
      {...commonProps}
    />
  );
}
