import styled, { useTheme } from "styled-components";
import { BigNumber } from "ethers";
import { Dot } from "../../styled";
import Expand from "../../Expand/Expand";
import { EthAddress } from "../../AppNavbar/Address";
import {
  Paragraph16Bold,
  Paragraph16BoldSecondary,
} from "../../../Typography/Paragraph16";
import { Paragraph14Bold } from "../../../Typography/Paragraph14";
import { Buttons as ButtonWrapper } from "../Account/Staked";
import Button from "../../Buttons/Button";
import { Paragraph12Bold } from "../../../Typography/Paragraph12";
import { useWeb3 } from "@chainsafe/web3-context";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import WalletIcon from "./WalletIcon";
import { truncateAmount } from "../../../../helpers/format";
import { useStakedQuery } from "../../../../queries/staking.query";
import { useTokenQuery } from "../../../../queries/token.query";
import useChain from "../../../hooks/useChain";

const Left = styled.div`
  display: flex;
  padding: 0 !important;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
`;

const StyledEthAddress = styled(EthAddress)`
  margin-top: 16px;
  margin-bottom: 24px;
`;

const Warning = styled.div`
  background: ${(props) => props.theme.red}19;
  border: 1px solid ${(props) => props.theme.red}33;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  margin-bottom: 12px;
  text-align: center;
  padding: 8px;
  max-width: 300px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: unset;
  }

  p {
    opacity: 1;
    color: ${(props) => props.theme.red};
    white-space: break-spaces;
  }
`;

const Buttons = styled(ButtonWrapper)`
  border: none;
  margin-top: 12px;
  padding-top: 0;

  button {
    margin-right: 0 !important;
  }
`;

const StyledExpand = styled(Expand)`
  padding: 12px;
`;

interface WalletItemProps {
  name: string;
  address?: string;
  balance?: string;
  onActivate?: () => void;
  onDelete: () => void;
}

const WalletItem = ({
  name,
  address,
  balance,
  onDelete,
  onActivate,
}: WalletItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { wallet } = useWeb3();
  const { data } = useStakedQuery();
  const { chain } = useChain();
  const { data: tokenData } = useTokenQuery(chain);
  const [showWarning, setShowWarning] = useState(false);
  const isActiveWallet = wallet?.name === name;

  const handleDeleteClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!showWarning) {
      setShowWarning(true);
      return;
    }

    onDelete();
  };

  const getWarningMessage = () => {
    if (isActiveWallet && !BigNumber.from(data?.stake ?? "0").isZero()) {
      return t("delete-stake-warning");
    }

    return t("delete-wallet");
  };

  return (
    <div className="wallet-item">
      <StyledExpand
        closedContent={
          !onActivate ? (
            <Left>
              <WalletIcon name={name} />
              <div>
                <Paragraph16Bold>{name}</Paragraph16Bold>
                <Details>
                  {balance && (
                    <Paragraph14Bold>
                      {truncateAmount(balance)} {tokenData?.nativeToken?.symbol}
                    </Paragraph14Bold>
                  )}
                </Details>
              </div>
            </Left>
          ) : (
            <Left>
              <WalletIcon name={name} />
              <div>
                <Paragraph16BoldSecondary style={{ opacity: 0.7 }}>
                  {name}
                </Paragraph16BoldSecondary>
              </div>
            </Left>
          )
        }
        icon={isActiveWallet ? <Dot color={theme.greenColor} /> : <></>}
      >
        <StyledEthAddress address={address} />
        {showWarning && (
          <Warning>
            <Paragraph12Bold>{getWarningMessage()}</Paragraph12Bold>
          </Warning>
        )}
        <Buttons>
          <Button
            background="none"
            labelColor={theme.red}
            text={t("delete")}
            borderColor={theme.red}
            backgroundHover="none"
            height="28px"
            fullWidth
            onClick={handleDeleteClicked}
          />
          {onActivate && (
            <Button
              background={theme.inputBackgroundColor}
              labelColor={theme.greyColor}
              text={t("make-active")}
              backgroundHover="none"
              height="28px"
              fullWidth
              onClick={onActivate}
            />
          )}
        </Buttons>
      </StyledExpand>
    </div>
  );
};

export default WalletItem;
