import React, { useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { AppProps } from "next/app";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import Head from "../common/components/Head/Head";
import ErrorBoundary from "../common/components/ErrorBoundary";
import { SearchProvider } from "../context/SearchContext";
import Web3Wrapper from "../common/components/Web3Wrapper";
import { StakingProvider } from "../common/hooks/useStaking";
import Layout from "../common/components/Layout";
import GlobalQueries from "../common/components/GlobalQueries";
import { reactQueryService } from "../services/reactQueryService";

import i18n from "../i18n";
import { useRouter } from "next/router";
import { GlobalPlayerProvider } from "../context/GlobalPlayerProvider";
import { WindowSizeProvider } from "../context/WindowSizeContext";
import { ThemeProvider } from "../context/ThemeProvider";

const NoizdApp = ({ Component, pageProps }: AppProps) => {
  const { dehydratedState, initialTheme } = pageProps;
  const { pathname } = useRouter();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
          },
        },
      })
  );

  useEffect(() => {
    // Set queryClient instance globally to make it available outside react components
    // By using useEffect we make sure that it affects only the client side
    reactQueryService.setQueryClient(queryClient);
  }, [queryClient]);

  return (
    <>
      <Head />
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={dehydratedState}>
            <GlobalQueries />
            <WindowSizeProvider>
              <ThemeProvider initialTheme={initialTheme}>
                <ErrorBoundary>
                  <SearchProvider>
                    <Web3Wrapper>
                      <GlobalPlayerProvider>
                        <StakingProvider>
                          <Layout>
                            <ErrorBoundary key={pathname}>
                              <Component {...pageProps} />
                            </ErrorBoundary>
                          </Layout>
                        </StakingProvider>
                      </GlobalPlayerProvider>
                    </Web3Wrapper>
                  </SearchProvider>
                </ErrorBoundary>
              </ThemeProvider>
            </WindowSizeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </Hydrate>
        </QueryClientProvider>
      </I18nextProvider>
    </>
  );
};

export default NoizdApp;
