import React, { ReactNode, useContext, useEffect, useState } from "react";
import { useWeb3 } from "@chainsafe/web3-context";
import { ethers } from "ethers";
import NoizdNFT from "../contracts/NOIZDNFT.sol/NOIZDNFT.json";
import { useWeb3Query } from "../queries/web3.query";
import { IDeployment } from "../common/commonTypes";

interface IContractSet {
  [deplomentId: string]: ethers.Contract;
}
interface ContractsContextValue {
  localStakingContract?: ethers.Contract;
  localNftContract?: ethers.Contract;
  stakingContracts: IContractSet;
  nftContracts: IContractSet;
}

const initialValues: ContractsContextValue = {
  stakingContracts: {},
  nftContracts: {},
};

const ContractsContext =
  React.createContext<ContractsContextValue>(initialValues);

interface Props {
  children: ReactNode;
  openWrongNetworkModal: () => void;
  closeWrongNetworkModal: () => void;
}

const ContractsProvider = ({
  children,
  openWrongNetworkModal,
  closeWrongNetworkModal,
}: Props) => {
  const [localStakingContract, setLocalStakingContract] = useState<
    ethers.Contract | undefined
  >();
  const [localNftContract, setLocalNftContract] = useState<
    ethers.Contract | undefined
  >();
  const [stakingContracts, setStakingContracts] = useState<IContractSet>({});
  const [nftContracts, setNftContracts] = useState<IContractSet>({});

  const { provider: localProvider, network: localNetwork } = useWeb3();

  const { data: deployments } = useWeb3Query();

  useEffect(() => {
    // This should create a default provider for each chain,
    // as well as managing a local provider connected to the users wallet
    async function initContracts() {
      // create all default providers
      const providers: {
        [deploymentId: string]: ethers.providers.Provider;
      } = {};

      const contracts: {
        staking: IContractSet;
        nft: IContractSet;
      } = {
        staking: {},
        nft: {},
      };

      // init all contracts and providers
      // create an instance for each contract deployment on all chains
      // these use the default provider so can only be used for reading
      await Promise.all(
        deployments?.map(async (deployment) => {
          providers[deployment.id] = new ethers.providers.JsonRpcProvider(
            deployment.chain.public_rpc_url,
            {
              chainId: Number(deployment.chain.id),
              name: deployment.chain.name,
            }
          );

          contracts.staking[deployment.id] = new ethers.Contract(
            deployment.staking_address,
            deployment.staking_abi,
            providers[deployment.id]
          );

          contracts.nft[deployment.id] = new ethers.Contract(
            await deployment.nft_address,
            NoizdNFT.abi,
            providers[deployment.id]
          );
        }) as Promise<undefined>[]
      );

      setStakingContracts(contracts.staking);
      setNftContracts(contracts.nft);

      // try init local contracts
      // these are contracts deployed to the currently selected netowrk
      // and are connected with the users provider
      if (localProvider && localNetwork) {
        const validChainIds = deployments?.map(({ chain }) => chain.id);

        if (validChainIds?.includes(localNetwork)) {
          // use the latest deployment from the target network
          const stakingDeployment = deployments?.find(
            (deployment) => deployment.chain.id === localNetwork
          );

          if (stakingDeployment) {
            const staking = new ethers.Contract(
              contracts.staking[stakingDeployment!.id].address,
              stakingDeployment?.staking_abi,
              localProvider
            );

            setLocalStakingContract(staking);

            let nft = new ethers.Contract(
              contracts.nft[stakingDeployment!.id].address,
              NoizdNFT.abi,
              localProvider
            );

            setLocalNftContract(nft);
          }
          closeWrongNetworkModal();
        } else {
          openWrongNetworkModal();
        }
      }
    }

    if (deployments) {
      initContracts();
    }
  }, [localProvider, localNetwork, deployments]);

  return (
    <ContractsContext.Provider
      value={{
        localStakingContract,
        localNftContract,
        nftContracts,
        stakingContracts,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

export default ContractsProvider;

export function useContracts() {
  return useContext(ContractsContext);
}

export function useContractsForDeployment(deployment?: IDeployment) {
  const { nftContracts, stakingContracts } = useContracts();

  if (!deployment) {
    return {
      nftContract: undefined,
      stakingContract: undefined,
    };
  }

  return {
    nftContract: nftContracts[deployment.id],
    stakingContract: stakingContracts[deployment.id],
  };
}

export function useDeploymentsForChain(chainId?: number) {
  const { data: deployments } = useWeb3Query();

  if (!chainId) {
    return {
      deployments: [],
    };
  }

  const deploymentsForChain = deployments?.filter(
    (deploment) => deploment.chain.id === chainId
  );

  return {
    deployments: deploymentsForChain,
  };
}
