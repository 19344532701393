import styled, { css } from "styled-components";
import {
  getBasePoppinsTypography,
  getBaseTypography,
  getBoldTypography,
  getMediumTypography,
  getRegularTypography,
} from "./helpers";

export const getParagraph16BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.paragraph.lg};
  line-height: 24px;
`;

export const getParagraph16PoppinsBoldTypography = css`
  ${getBasePoppinsTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.paragraph.lg};
  line-height: 24px;
`;

export const getParagraph16MediumTypography = css`
  ${getBaseTypography};
  ${getMediumTypography};
  font-size: ${(props) => props.theme.paragraph.lg};
  line-height: 24px;
`;

export const getParagraph16RegularTypography = css`
  ${getBaseTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.paragraph.lg};
  line-height: 24px;
`;

export const getParagraph16PoppinsRegularTypography = css`
  ${getBasePoppinsTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.paragraph.lg};
  line-height: 24px;
`;

export const Paragraph16Bold = styled.p`
  ${getParagraph16BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph16PoppinsBold = styled.p`
  ${getParagraph16PoppinsBoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph16Regular = styled.p`
  ${getParagraph16RegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph16PoppinsRegular = styled.p`
  ${getParagraph16PoppinsRegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph16RegularSecondary = styled.p`
  ${getParagraph16RegularTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.7;
`;

export const Paragraph16BoldSecondary = styled.p`
  ${getParagraph16BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.7;
`;
