import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { formatDuration, IDurationLabels } from "../../helpers/dateTime";

type Props = {
  endTime: string;
  labels?: IDurationLabels;
  showWhenEnded?: boolean;
  onComplete?: () => void;
};

const getRemainingTime = (end: Moment, labels?: IDurationLabels): string => {
  const now = moment();
  const diff = moment.duration(end.diff(now));

  return formatDuration(diff, labels);
};

const isComplete = (endTime: string): boolean => {
  return moment() >= moment(endTime);
};

const Countdown = ({
  endTime,
  labels,
  showWhenEnded = true,
  onComplete,
}: Props) => {
  const defaultResult = "0h 0m 0s";
  const [result, setResult] = useState(
    getRemainingTime(moment(endTime), labels)
  );

  useEffect(() => {
    function startTimer() {
      return setInterval(() => {
        setResult(getRemainingTime(moment(endTime), labels));

        if (isComplete(endTime)) {
          clearInterval(timer);
          onComplete && onComplete();
        }
      }, 1000);
    }

    let timer = startTimer();

    return () => {
      clearInterval(timer);
    };
  }, [endTime]);

  if (result === defaultResult) {
    return showWhenEnded ? <>{defaultResult}</> : null;
  }

  return <>{result}</>;
};

export default Countdown;
