import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../Icon/Icon";
import {
  LinksSection,
  LinkSectionTitle,
  LinkSectionTitleContainer,
  StyledLink,
  LinkBody,
  LinksContent,
  Divider,
} from "./shared";

type Props = {
  isTablet: boolean;
  onAboutModalOpen: () => void;
};

const NoizdLinks = ({ isTablet, onAboutModalOpen }: Props) => {
  const { t } = useTranslation();

  const [bodyHeight, setBodyHeight] = useState<number | null>(null);
  const noizdRef: any = useRef(null);
  const isActive = bodyHeight !== null;

  const handleOnAccordionClick = () => {
    if (isActive) {
      setBodyHeight(null);
    } else {
      setBodyHeight(noizdRef.current.clientHeight);
    }
  };

  return (
    <>
      <Divider />
      <LinksSection>
        <LinkSectionTitleContainer
          isTablet={isTablet}
          isActive={isActive}
          onClick={handleOnAccordionClick}
        >
          <LinkSectionTitle>
            {t("footer-section-link-title-noizd")}
          </LinkSectionTitle>
          {isTablet && <Icon name="chevronDown" />}
        </LinkSectionTitleContainer>
        {isTablet ? (
          <LinkBody active={isActive} bodyHeight={bodyHeight}>
            <LinksContent ref={noizdRef}>
              <StyledLink onClick={onAboutModalOpen}>
                {t("footer-section-link-about")}
              </StyledLink>
            </LinksContent>
          </LinkBody>
        ) : (
          <StyledLink onClick={onAboutModalOpen}>
            {t("footer-section-link-about")}
          </StyledLink>
        )}
      </LinksSection>
    </>
  );
};

export default NoizdLinks;
