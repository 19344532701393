interface IPictureSize {
  WIDTH: number;
  HEIGHT: number;
}

interface AppSettings {
  MAX_PROFILE_PICTURE_SIZE: number;
  MAX_IMAGE_SIZE: number;
  MAX_AUDIO_SIZE: number;
  MAX_VIDEO_SIZE: number;
  COVER_WIDTH: number;
  TRACK_POSTER: IPictureSize;
  PROFILE_PICTURE_WIDTH: number;
  USER_COVER_PICTURE: IPictureSize;
  USER_ABOUT_PICTURE: IPictureSize;
  REFRESH_INTERVAL: number;
  explorers: {
    [network: number]: string;
  };
  MINIMUM_BID_INCREASE: string;
  GAS_LIMITS: {
    WITHDRAWAL: number;
    STAKE: number;
  };
  FEATURED_ITEMS_COUNT: number;
  ROUTE_LOADER_SHOW_DELAY: number;
  VIDEO_FORMAT_PREFERENCE: string[];
  FAQ_URL: string;
  DEFAULT_SPEND_DEADLINE: number;
}

export const settings: AppSettings = {
  MAX_PROFILE_PICTURE_SIZE: 10 * 1024 * 1024, // 10mb, has to be in bytes
  MAX_IMAGE_SIZE: 50 * 1024 * 1024, // 50mb, has to be in bytes
  MAX_AUDIO_SIZE: 1024 * 1024 * 1024, // 1GB, has to be in bytes
  MAX_VIDEO_SIZE: 1024 * 1024 * 1024, // 1GB, has to be in bytes
  COVER_WIDTH: 650,
  TRACK_POSTER: {
    WIDTH: 820,
    HEIGHT: 426,
  },
  PROFILE_PICTURE_WIDTH: 400,
  USER_COVER_PICTURE: {
    WIDTH: 1440,
    HEIGHT: 426,
  },
  USER_ABOUT_PICTURE: {
    WIDTH: 820,
    HEIGHT: 426,
  },
  REFRESH_INTERVAL: 10000,
  explorers: {
    1: "https://etherscan.io",
    3: "https://ropsten.etherscan.io",
    4: "https://rinkeby.etherscan.io",
    1337: "https://ropsten.etherscan.io",
  },
  MINIMUM_BID_INCREASE: "10000000000000000",
  GAS_LIMITS: {
    WITHDRAWAL: 87000,
    STAKE: 95000,
  },
  FEATURED_ITEMS_COUNT: 4,
  ROUTE_LOADER_SHOW_DELAY: 400,
  VIDEO_FORMAT_PREFERENCE: ["webm", "mp4"],
  FAQ_URL:
    "https://noizd.notion.site/NOIZD-FAQ-fd6b183b6d874b65a17c58b07e837bae",
  DEFAULT_SPEND_DEADLINE: 10000,
};
