import ProfileQuickView from "./ProfileQuickView";
import Avatar from "../Avatar";
import styled from "styled-components";
import { IUserProfile } from "../../commonTypes";
import Overlay from "../Overlay/Overlay";
import { useState } from "react";

type Props = {
  user?: IUserProfile;
  size?: string;
  isBottomAligned?: boolean;
  [any: string]: any;
};

const Wrapper = styled.div`
  position: relative;
  text-align: left;
`;

const UserAvatar = ({ user, size, isBottomAligned, ...props }: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <Wrapper {...props} onClick={(event) => event.stopPropagation()}>
      <Overlay
        visible={isVisible}
        onVisibleChange={setIsVisible}
        destroyTooltipOnHide={false}
        align={{
          points: isBottomAligned ? ["br", "br"] : ["tl", "tl"],
          offset: [0, 0],
        }}
        overlay={() =>
          user && (
            <ProfileQuickView
              user={user}
              closeQuickView={() => setIsVisible(false)}
            />
          )
        }
      >
        <Avatar size={size} user={user} />
      </Overlay>
    </Wrapper>
  );
};

export default UserAvatar;
