import React from "react";
import styled from "styled-components";
import { IMusic, IStyleableProps, IUserProfile } from "../../commonTypes";
import { getParagraph14RegularTypography } from "../../Typography";
import Countdown from "../Countdown";
import UserAvatar from "../UserAvatar";
import { ListingStatusEnum } from "../../commonEnums";
import { useGlobalPlayer } from "../../../context/GlobalPlayerProvider";
import PlayerCard from "../PlayerCard/PlayerCard";

const AvatarWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 10px;
  left: 10px;
`;

const TimerContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 10px;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const Timer = styled.div`
  background: rgba(18, 18, 18, 0.4);
  background: ${({ theme }) => theme.timerBackgroundColor};
  backdrop-filter: blur(40px);
  border-radius: 6px;
  padding: 8px 20px 8px 19px;
  ${getParagraph14RegularTypography};
  color: ${({ theme }) => theme.primaryColor};
  font-weight: 700;
  line-height: 16px;
  max-height: 30px;
`;

interface IAudioPlayerProps {
  music: IMusic;
  playlist?: IMusic[];
  status?: ListingStatusEnum;
  artist?: IUserProfile;
  auctionTime?: string;
  hideLoader?: boolean;
  coverResizeWidth?: number;
}

const GlobalPlayerCard = ({
  status,
  music,
  playlist,
  artist,
  auctionTime,
  hideLoader,
  coverResizeWidth,
  ...props
}: IAudioPlayerProps & IStyleableProps) => {
  const { track: playedTrack, isPlaying, triggerTrack } = useGlobalPlayer();
  const isTrackPlaying = isPlaying && music?.id === playedTrack?.id;
  const showTimer = auctionTime && status && status === "active";

  return (
    <>
      <PlayerCard
        music={music}
        isPlaying={isTrackPlaying}
        togglePlay={() => triggerTrack(music, playlist)}
        coverResizeWidth={coverResizeWidth}
        {...props}
      >
        {artist && (
          <AvatarWrapper>
            <UserAvatar user={artist} size="34px" />
          </AvatarWrapper>
        )}

        {showTimer && (
          <TimerContainer>
            <Timer>
              <Countdown endTime={auctionTime!} showWhenEnded={false} />
            </Timer>
          </TimerContainer>
        )}
      </PlayerCard>
    </>
  );
};

export default GlobalPlayerCard;
