import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { Paragraph14Regular } from "../../../Typography/Paragraph14";
import Button from "../../Buttons/Button";
import Icon from "../../Icon/Icon";
import Typography from "../../Typography/Typography";
import TransactionResult from "./TransactionResult";

export const Wrapper = styled.div`
  text-align: center;
`;

export const Content = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.lightBackgroundColor};
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
`;

export const ActionButton = styled(Button)`
  margin-top: 24px;
`;

const Text = styled(Paragraph14Regular)`
  max-width: 356px;
  margin-bottom: 24px;
  margin-top: 12px;
`;

const IconWrapper = styled.div`
  padding: 10px;
  background: ${(props) => props.theme.inputBackgroundColor};
  width: fit-content;
  border-radius: ${(props) => props.theme.borderRadius.md};
  margin: 0 auto;
`;

const LoaderWrapper = styled.div`
  margin-bottom: 15px;
`;
interface Props {
  onContinueClick: () => void;
  error?: string;
  hash?: string;
  blockNumber?: number;
  redirectUrl?: string;
  hideButtons?: boolean;
}

const InProgress = ({
  onContinueClick,
  error,
  hash,
  blockNumber,
  redirectUrl,
  hideButtons,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const isTransactionConfirmed = hash && blockNumber;

  if (isTransactionConfirmed) {
    return (
      <TransactionResult
        message={t("transaction-complete")}
        success={true}
        onCloseClick={onContinueClick}
        redirectUrl={redirectUrl!}
        hideButtons={hideButtons}
      />
    );
  }

  if (error) {
    return (
      <TransactionResult
        message={error}
        success={false}
        onCloseClick={onContinueClick}
        hideButtons={hideButtons}
        redirectUrl={redirectUrl}
      />
    );
  }

  if (hash) {
    return (
      <Wrapper>
        <Content>
          <LoaderWrapper>
            <Icon
              name="circleLoader"
              width="36px"
              height="36px"
              color={theme.accent.normal}
            />
          </LoaderWrapper>
          <Text>{t("transaction-in-progress")}</Text>
          <a href={redirectUrl} target="_blank" rel="noreferrer">
            <Typography align="center" size="lg" weight="bold">
              {t("view-transaction")}
            </Typography>
          </a>
        </Content>
        {!hideButtons && (
          <ActionButton
            text={t("view-your-transactions")}
            fullWidth
            onClick={onContinueClick}
          />
        )}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Content>
        <IconWrapper>
          <Icon name="repeat" />
        </IconWrapper>
        <Text>{t("confirm-in-wallet")}</Text>
        {isTransactionConfirmed && (
          <Button text={t("continue")} fullWidth onClick={onContinueClick} />
        )}
      </Content>
    </Wrapper>
  );
};

export default InProgress;
