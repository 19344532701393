import { isClientEnv } from "./nextHelper";

export const safe = {
  get window(): typeof window | undefined {
    if (!isClientEnv()) {
      return undefined;
    }

    return window;
  },
  get document(): typeof document | undefined {
    if (!isClientEnv()) {
      return undefined;
    }

    return document;
  },
  get localStorage(): typeof localStorage | undefined {
    if (!isClientEnv()) {
      return undefined;
    }

    return localStorage;
  },
};
