import React from "react";
import styled from "styled-components";
import Icon from "../Icon/Icon";
import Typography, { TypographySize } from "../Typography/Typography";
import { getFlexCenter } from "../../styles";
import { IconKeys } from "../../commonTypes";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div<{ size: string; spacing: string }>`
  margin-right: ${({ spacing }) => spacing};
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background: ${({ theme }) => theme.lightBackgroundColor};
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  ${getFlexCenter};
  flex-shrink: 0;
`;

interface IProps {
  icon: IconKeys;
  text: string;
  spacing?: string;
  textSize?: TypographySize;
  iconWrapperSize?: string;
  iconSize?: string;
  secondary?: boolean;
}

const InfoIcon = ({
  icon,
  text,
  spacing = "16px",
  textSize = "md",
  iconWrapperSize = "32px",
  iconSize = "18px",
  secondary = false,
}: IProps) => {
  return (
    <Wrapper>
      <IconWrapper size={iconWrapperSize} spacing={spacing}>
        <Icon name={icon} size={iconSize} />
      </IconWrapper>
      <Typography size={textSize} secondary={secondary} weight="400">
        {text}
      </Typography>
    </Wrapper>
  );
};

export default InfoIcon;
