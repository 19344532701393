import { ethers } from "ethers";
import {
  IApiResponse,
  IAsset,
  IDeployment,
  IListing,
  ITransactionEvent,
} from "../../common/commonTypes";
import { IStakingClient } from "../stakingClient";
import { TypedDataDomain } from "@ethersproject/abstract-signer";

import {
  IBidMessage,
  IWithdrawal,
  TransactionEvent,
} from "../../common/hooks/useStaking";
import { TransactionResponse } from "@ethersproject/abstract-provider";

export default class NOIZDBase implements IStakingClient {
  deployment: IDeployment;
  signer: ethers.providers.JsonRpcSigner;
  provider: ethers.providers.Provider;
  stakingContract: ethers.Contract;
  stakingDomain: TypedDataDomain;

  constructor(deployment: IDeployment, signer: ethers.providers.JsonRpcSigner) {
    this.deployment = deployment;
    this.signer = signer;
    this.provider = signer.provider;
    this.stakingContract = new ethers.Contract(
      deployment.staking_address,
      deployment.staking_abi,
      signer
    );
    this.stakingDomain = {};
  }
  getWithdrawEvents(address: string): Promise<ITransactionEvent[]> {
    throw new Error("Method not implemented.");
  }
  getSaleEvents(address: string): Promise<ITransactionEvent[]> {
    throw new Error("Method not implemented.");
  }
  getPurchaseEvents(address: string): Promise<ITransactionEvent[]> {
    throw new Error("Method not implemented.");
  }
  getStakeEvents(address: string): Promise<ITransactionEvent[]> {
    throw new Error("Method not implemented.");
  }
  getWithdrawalData(
    amount: string,
    token: IAsset,
    user: string
  ): Promise<IWithdrawal> {
    throw new Error("Method not implemented.");
  }
  signWithdrawal(withdrawal: IWithdrawal): Promise<{ signature: string }> {
    throw new Error("Method not implemented.");
  }
  placeBid(
    listing: IListing,
    amount: string,
    address: string
  ): Promise<IApiResponse> {
    throw new Error("Method not implemented.");
  }
  onStakeEvent(
    address: string,
    asset: string,
    callback: (event: TransactionEvent) => void
  ): void {
    throw new Error("Method not implemented.");
  }
  approveToken(asset: IAsset, amount: string): Promise<TransactionResponse> {
    throw new Error("Method not implemented.");
  }
  withdrawInstant(
    withdrawal: IWithdrawal,
    userSignature: string,
    ownerSignature: string
  ): Promise<ethers.providers.TransactionResponse> {
    throw new Error("Method not implemented.");
  }
  stake(
    amount: string,
    asset: IAsset
  ): Promise<ethers.providers.TransactionResponse> {
    throw new Error("Method not implemented.");
  }
  getTokenAllowance(user: string, asset: IAsset): Promise<ethers.BigNumber> {
    throw new Error("Method not implemented.");
  }
  getBalance(user: string, asset: IAsset): Promise<ethers.BigNumber> {
    throw new Error("Method not implemented.");
  }
  getStake(user: string, asset: IAsset): Promise<ethers.BigNumber> {
    throw new Error("Method not implemented.");
  }
  signBid(bid: IBidMessage): Promise<string> {
    throw new Error("Method not implemented.");
  }
  onWithdrawEvent(
    address: string,
    asset: string,
    nonce: number,
    callback: (event: TransactionEvent) => void
  ): void {
    throw new Error("Method not implemented.");
  }
}
