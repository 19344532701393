import React, { ReactNode } from "react";
import NextHead from "next/head";
import { getEnv } from "../../../helpers/env";
import { TwitterCardType } from "../../commonEnums";
import { IShareLinkMetadata } from "../../commonTypes";

interface IProps extends IShareLinkMetadata {
  title?: string;
  children?: ReactNode;
}

const Head = ({
  title = "NOIZD",
  previewTitle = "Check out these tracks on NOIZD 😎",
  previewDescription = "The #1 marketplace to buy and sell music NFTs!",
  previewTwitterImage = getEnv("NEXT_PUBLIC_SHARE_LOGO_URL"),
  previewOgImage = getEnv("NEXT_PUBLIC_SHARE_LOGO_URL"),
  twitterCardType = TwitterCardType.summaryLarge,
  children,
}: IProps) => {
  // key prop is needed on every meta tag to prevent duplication of tags when overriding it in other pages
  return (
    <NextHead>
      <meta
        key="viewport"
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />

      <title key="title">{title}</title>
      <meta key="og:title" property="og:title" content={previewTitle} />
      <meta key="twitter:title" name="twitter:title" content={previewTitle} />

      <meta key="description" name="description" content={previewDescription} />
      <meta
        key="og:description"
        property="og:description"
        content={previewDescription}
      />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={previewDescription}
      />

      <meta key="og:image" property="og:image" content={previewOgImage} />
      <meta
        key="twitter:image"
        name="twitter:image"
        content={previewTwitterImage}
      />

      <meta key="twitter:card" name="twitter:card" content={twitterCardType} />
      <meta key="og:type" property="og:type" content="website" />
      {children}
    </NextHead>
  );
};

export default Head;
