import styled from "styled-components";
import { zIndex } from "../../../constants/zIndex";
import { getFlexCenter } from "../../styles";
import Icon from "../Icon/Icon";

export const Root = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.globalPlayer};
  width: 100%;
  height: 90px;
  background-color: ${({ theme }) => theme.navbarBackgroundColor};
  backdrop-filter: blur(20px);
  border: 0 solid ${({ theme }) => theme.darkBorderColor};
  border-top-width: 1px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(${({ $isOpen }) => ($isOpen ? 0 : "100%")});
  transition: transform 200ms ease-in;
`;

export const TrackCoverWrapper = styled.div`
  width: 58px;
  height: 58px;
  border-radius: ${({ theme }) => theme.borderRadius.xs};
  flex-shrink: 0;
  background: ${({ theme }) => theme.backgroundColor};
`;

export const TrackInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  overflow: hidden;
`;

export const ControlButton = styled.button`
  width: 32px;
  height: 32px;
  flex: 0 0 32px;
  ${getFlexCenter};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }
`;

export const ControlIcon = styled(Icon)<{ $active?: boolean }>`
  opacity: ${({ $active }) => ($active ? 1 : 0.7)};
  color: ${({ $active, theme }) =>
    $active ? theme.accent.normal : theme.primaryColor};
`;
