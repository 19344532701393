import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getFlexCenter, navbarHeight } from "../../styles";
import LoadingSpinner from "../LoadingSpinner";
import { settings } from "../../../settings";
import { zIndex } from "../../../constants/zIndex";

const Wrapper = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: ${navbarHeight.desktop}px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex.routeLoader};
  background: ${({ theme }) => theme.navbarBackgroundColor};
  backdrop-filter: blur(7px);
  ${getFlexCenter};
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 150ms linear;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    top: ${navbarHeight.mobile}px;
  }
`;

const RouteLoader = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, settings.ROUTE_LOADER_SHOW_DELAY);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Wrapper $isVisible={isVisible}>
      <LoadingSpinner size="50px" />
    </Wrapper>
  );
};

export default RouteLoader;
