import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getFormattedAmountWithLabel } from "../../../../helpers/format";
import { IAsset } from "../../../commonTypes";
import {
  Paragraph10RegularSecondary,
  Paragraph10Bold,
} from "../../../Typography/Paragraph10";
import { Paragraph12RegularSecondary } from "../../../Typography/Paragraph12";
import { Paragraph16Bold } from "../../../Typography/Paragraph16";
import ProgressBar from "../../ProgressBar";

const TotalStakedWrapper = styled.div`
  background: ${(props) => props.theme.inputBackgroundColor};
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius.md};
  margin-top: 16px;
  padding: 16px;
`;

const Labels = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 16px;

  p {
    margin: 0;
    padding: 0;
  }
`;

const Balances = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface IStakedAssetProps {
  asset: IAsset;
  available: string;
  total: string;
  locked: string;
}

const StakedAsset = ({
  asset,
  available,
  total,
  locked,
}: IStakedAssetProps) => {
  const { t } = useTranslation();

  return (
    <TotalStakedWrapper>
      <Paragraph12RegularSecondary>
        {t("stake-total-staked", {
          name: asset.name,
        })}
      </Paragraph12RegularSecondary>
      <Paragraph16Bold>
        {getFormattedAmountWithLabel(total, asset)}
      </Paragraph16Bold>
      <Labels>
        <Paragraph10RegularSecondary>
          {t("stake-available-stake")}
        </Paragraph10RegularSecondary>
        <Paragraph10RegularSecondary>
          {t("stake-locked")}
        </Paragraph10RegularSecondary>
      </Labels>
      <ProgressBar progress={Number(available)} duration={Number(total)} />
      <Balances>
        <Paragraph10Bold>
          {getFormattedAmountWithLabel(available, asset)}
        </Paragraph10Bold>
        <Paragraph10Bold>
          {getFormattedAmountWithLabel(locked, asset)}
        </Paragraph10Bold>
      </Balances>
    </TotalStakedWrapper>
  );
};

export default StakedAsset;
