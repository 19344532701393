import { useEffect } from "react";
import { useRouter } from "next/router";

import { useSession } from "../../queries/authentication.query";
import { useWebsockets } from "../hooks/useWebsockets";

import { routes } from "../../routes";

const GlobalQueries = () => {
  const router = useRouter();

  useSession();

  useWebsockets();

  useEffect(() => {
    router.prefetch(routes.page404);
    router.prefetch(routes.auth.login);
  }, []);

  return null;
};

export default GlobalQueries;
