import { useRouter } from "next/router";
import Menu from "../Menu";
import MenuNav from "../MenuNav";
import { useTranslation } from "react-i18next";
import TransactionItem from "./TransactionItem";
import Typography from "../../Typography/Typography";
import useTransactionList from "./useTransactionList";
import LoadingSpinner from "../../LoadingSpinner";
import styled from "styled-components";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";
import Button from "../../Buttons/Button";
import { useState } from "react";
import InProgress from "../Wallet/InProgress";
import {
  ITransactionEvent,
  TransactionActionEnum,
  TransactionStatusEnum,
} from "../../../commonTypes";
import { getBlockExplorerTransactionUrl } from "../../../../helpers/transactions";

const Loading = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

const Transactions = styled.div`
  a:last-child {
    margin-bottom: 0 !important;
  }
`;

const EmptyWrapper = styled.div`
  background: ${(props) => props.theme.inputBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};
  padding: 21px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EmptyText = styled(Typography)`
  line-height: 19px;
  margin-bottom: 1px;
`;

const EmptyDescription = styled(Typography)`
  line-height: 14px;
  opacity: 0.5;
  margin-bottom: 16px;
`;

const TransactionListMenu = () => {
  const { t } = useTranslation();
  const {
    isTransactionListMenuOpen,
    handleTransactionListMenuClose,
    openDepositStakeMenu,
  } = useAccountDropdown();
  const { txEvents, isLoading } = useTransactionList(isTransactionListMenuOpen);
  const router = useRouter();

  const [selectedEvent, setSelectedEvent] = useState<
    ITransactionEvent | undefined
  >();

  const handleEmptyAction = () => {
    handleTransactionListMenuClose();
    openDepositStakeMenu();
  };

  const handleCloseStatus = () => {
    setSelectedEvent(undefined);
    handleTransactionListMenuClose();
  };

  const handleProgressClick = () => {
    setSelectedEvent(undefined);
  };

  const handleTransactionItemClick = (
    event: ITransactionEvent,
    redirectUrl: string
  ) => {
    if (
      [TransactionActionEnum.deposit, TransactionActionEnum.withdraw].includes(
        event.action
      )
    ) {
      setSelectedEvent(event);
    } else {
      router.push(redirectUrl);
      handleTransactionListMenuClose();
    }
  };

  if (selectedEvent) {
    let error =
      selectedEvent.status === TransactionStatusEnum.error
        ? t("transaction-failed")
        : "";

    return (
      <Menu isOpen={isTransactionListMenuOpen} onClose={handleCloseStatus}>
        <InProgress
          onContinueClick={handleProgressClick}
          hash={selectedEvent?.hash}
          blockNumber={selectedEvent?.blockNumber}
          redirectUrl={getBlockExplorerTransactionUrl(
            selectedEvent.chain!,
            selectedEvent!.hash
          )}
          error={error}
        />
      </Menu>
    );
  }

  return (
    <Menu
      isOpen={isTransactionListMenuOpen}
      onClose={handleTransactionListMenuClose}
    >
      <MenuNav title={t("transactions")} />

      {isLoading && (
        <Loading>
          <LoadingSpinner />
        </Loading>
      )}

      <Transactions>
        {txEvents.map((event, i) => (
          <TransactionItem
            key={i + event.timestamp.toString()}
            event={event}
            onClick={handleTransactionItemClick}
          />
        ))}
      </Transactions>

      {txEvents.length === 0 && !isLoading && (
        <EmptyWrapper>
          <EmptyText weight="400" size="lg">
            {t("transaction-list-empty-title")}
          </EmptyText>
          <EmptyDescription>
            {t("transaction-list-empty-description")}
          </EmptyDescription>
          <Button
            text={t("transaction-list-empty-CTA")}
            onClick={handleEmptyAction}
          />
        </EmptyWrapper>
      )}
    </Menu>
  );
};

export default TransactionListMenu;
