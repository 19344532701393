import React, { useEffect } from "react";
import styled from "styled-components";
import { useSearch } from "../../context/SearchContext";
import {
  getHeading4BoldTypography,
  getParagraph14RegularTypography,
  getParagraph16BoldTypography,
} from "../../common/Typography";
import { useTranslation } from "react-i18next";
import Icon from "../../common/components/Icon/Icon";
import { routes } from "../../routes";
import NftCard from "../../common/components/NftCard/NftCard";
import { IUserProfile } from "../../common/commonTypes";
import ArtistCard from "../../common/components/ArtistCard/ArtistCard";
import ButtonOutlined from "../../common/components/Buttons/ButtonOutlined";
import {
  getFlexCenter,
  getHorizontalGap,
  getVerticalGap,
} from "../../common/styles";
import LoadingSpinner from "../../common/components/LoadingSpinner";
import NoResults from "../../common/components/NoResults/NoResults";
import IconButton from "../../common/components/Buttons/IconButton";

const Root = styled.div`
  position: relative;
`;

const CloseWrapper = styled.div`
  width: 100%;
  padding: 32px 32px 0;
  display: flex;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const SearchPhraseWrapper = styled.div<{ hidden?: boolean }>`
  align-self: center;
  min-height: 24px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  ${getHorizontalGap("4px")};
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  transition: opacity 200ms linear;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    flex-direction: column;
    ${getVerticalGap("4px")};
    ${getHorizontalGap("0")};
  }
`;

const SearchPhraseLabel = styled.p`
  color: ${(props) => props.theme.primaryColor};
  ${getParagraph14RegularTypography};
  font-weight: 500;
  margin-right: 4px;
`;

const SearchPhrase = styled.p`
  color: ${(props) => props.theme.primaryColor};
  ${getParagraph16BoldTypography};
  text-align: center;
`;

const LoadingContainer = styled.div`
  height: 64px;
  width: 100%;
  ${getFlexCenter};
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 64px;
`;

const Title = styled.h4`
  color: ${(props) => props.theme.primaryColor};
  ${getHeading4BoldTypography};
  font-weight: 600;
  margin-bottom: 24px;
`;

const LoadMoreButton = styled(ButtonOutlined)`
  height: 50px;
  width: 350px;
  align-self: center;
  margin-top: 32px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
  }
`;

const ListingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto auto;
  column-gap: 24px;
  row-gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const ArtistsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: auto auto;
  column-gap: 24px;
  row-gap: 24px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const SearchResults = () => {
  const { t } = useTranslation();
  const {
    searchValue,
    closeSearch,
    listings,
    haveListingsNextPage,
    areListingsLoading,
    fetchMoreListings,
    artists,
    haveArtistsNextPage,
    areArtistsLoading,
    fetchMoreArtists,
    isSearching,
  } = useSearch();
  const playlist = listings.map(({ music }) => music);

  const isEmpty = artists.length === 0 && listings.length === 0;

  useEffect(() => {
    window.scroll({
      top: 0,
    });
  }, []);

  return (
    <Root>
      <CloseWrapper>
        <IconButton onClick={closeSearch}>
          <Icon name="close" size="16px" />
        </IconButton>
      </CloseWrapper>
      <Wrapper className="main-container">
        <SearchPhraseWrapper hidden={searchValue === ""}>
          <SearchPhraseLabel>{t("results-for")}</SearchPhraseLabel>
          <SearchPhrase>{searchValue}</SearchPhrase>
        </SearchPhraseWrapper>

        <LoadingContainer>{isSearching && <LoadingSpinner />}</LoadingContainer>

        {listings.length > 0 && (
          <Section>
            <Title>{t("tracks")}</Title>
            <ListingContainer>
              {listings.map((item) => (
                <NftCard
                  key={item.id}
                  music={item.music}
                  playlist={playlist}
                  listing={item}
                  redirectUrl={routes.marketplace.id(item.music_id!, item.id)}
                />
              ))}
            </ListingContainer>
            {haveListingsNextPage && (
              <LoadMoreButton
                text={t("load-more")}
                onClick={() => fetchMoreListings()}
                isLoading={areListingsLoading}
              />
            )}
          </Section>
        )}

        {artists.length > 0 && (
          <Section>
            <Title>{t("artists")}</Title>
            <ArtistsContainer>
              {artists.map((artist: IUserProfile) => (
                <ArtistCard key={artist.id} artist={artist} />
              ))}
            </ArtistsContainer>
            {haveArtistsNextPage && (
              <LoadMoreButton
                text={t("load-more")}
                onClick={() => fetchMoreArtists()}
                isLoading={areArtistsLoading}
              />
            )}
          </Section>
        )}

        {isEmpty && !isSearching && <NoResults />}
      </Wrapper>
    </Root>
  );
};

export default SearchResults;
