import styled from "styled-components";

import {
  getHeading6BoldTypography,
  getParagraph14BoldTypography,
  getParagraph14RegularTypography,
} from "../../Typography";
import Copy from "../Copy";
import { ReactNode } from "react";
import { IStyleableProps } from "../../commonTypes";
import Icon from "../Icon/Icon";

type Props = {
  address?: string;
  balance?: number;
  icon?: ReactNode;
  onClick?: () => void;
};

const Container = styled.div`
  background-color: ${(props) => props.theme.lightBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};
  display: flex;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
`;

const EmptyContainer = styled(Container)`
  align-items: center;
`;

const EmptyContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Logo = styled(Icon)`
  margin-right: 16px;
`;

const Account = styled.p`
  ${getParagraph14RegularTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.5;

  max-width: calc(100% - 50px);
`;

const Balance = styled.h6`
  ${getHeading6BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

const Text = styled.p`
  ${getParagraph14BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

const IconWrapper = styled.div`
  margin-left: auto;
  margin-top: auto;
`;

const Content = styled.div`
  text-align: left;
  flex-grow: 1;
`;

export const EthAddress = (
  props: IStyleableProps & { address?: string; visibleChars?: number }
) => {
  const { address, visibleChars = 6 } = props;
  return (
    <Account {...props}>
      {address &&
        `${address?.substring(0, visibleChars)}...${address?.substring(
          address?.length - visibleChars
        )}`}
      {address && <Copy payload={address || ""} />}
    </Account>
  );
};
