import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { defaultTheme } from "../theme/theme";
import { mergeThemes } from "../helpers/theme";
import { ICustomTheme, IUserProfile } from "../common/commonTypes";
import { useRouter } from "next/router";
import { usePrevious } from "../common/hooks/usePrevious";
import { routes } from "../routes";

interface IThemeProps {
  defaultTheme: DefaultTheme;
  theme: DefaultTheme;
  customizeTheme: (inputColors: ICustomTheme) => void;
  resetTheme: () => void;
}

const ThemeContext = createContext<IThemeProps>({
  defaultTheme: defaultTheme,
  theme: defaultTheme,
  customizeTheme: () => {},
  resetTheme: () => {},
});

interface IThemeProviderProps {
  children: ReactNode;
  initialTheme?: ICustomTheme;
}

const customizableRoutes = [routes.user.pathname, routes.marketplace.pathname];

export const ThemeProvider = ({
  children,
  initialTheme,
}: IThemeProviderProps) => {
  const { pathname } = useRouter();
  const prevPathname = usePrevious(pathname);
  const [theme, setTheme] = useState(mergeThemes(defaultTheme, initialTheme));

  const customizeTheme = (inputColors: ICustomTheme) => {
    setTheme(mergeThemes(defaultTheme, inputColors));
  };

  const resetTheme = () => {
    if (theme !== defaultTheme) {
      setTheme(defaultTheme);
    }
  };

  useEffect(() => {
    if (
      prevPathname &&
      customizableRoutes.includes(prevPathname) &&
      !customizableRoutes.includes(pathname)
    ) {
      resetTheme();
    }
  }, [pathname]);

  return (
    <StyledThemeProvider theme={theme}>
      <ThemeContext.Provider
        value={{ theme, defaultTheme, customizeTheme, resetTheme }}
      >
        {children}
      </ThemeContext.Provider>
    </StyledThemeProvider>
  );
};

export const useTheme = () => useContext(ThemeContext);

export const useThemeCustomizer = (user?: IUserProfile) => {
  const { customizeTheme, resetTheme } = useTheme();

  useEffect(() => {
    if (user) {
      if (user.profile.theme) {
        customizeTheme(user.profile.theme);
      } else {
        resetTheme();
      }
    }
  }, [user?.profile.id]);
};
