import styled from "styled-components";
import { IStyleableProps, ITab } from "../../commonTypes";

const Root = styled.div`
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
`;

const TabsWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledTabs = styled.div<any>`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
`;

const StyledTab = styled.div<any>`
  display: flex;
  justify-content: center;
  border-bottom: ${(props) =>
    props.active ? `1px solid ${props.theme.primaryColor}` : "none"};
  cursor: pointer;
  z-index: 1;
`;

export const TabsLine = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: ${({ theme }) => theme.borderColor};
  z-index: 0;
`;

type Props = {
  tabs: ITab[];
  activeTab: number;
  maxWidth?: string;
  showBottomBorder?: boolean;
  onTabClick: (tab: number) => void;
};

const Tabs = ({
  tabs,
  activeTab,
  maxWidth,
  showBottomBorder,
  onTabClick,
  ...props
}: Props & IStyleableProps) => {
  const tab = tabs.filter((tab) => tab.id === activeTab);
  return (
    <Root {...props}>
      <TabsWrapper>
        <StyledTabs maxWidth={maxWidth} className="tabs-container">
          {tabs.map((tab, index) => {
            const isActive = activeTab === tab.id;
            return (
              <StyledTab
                key={index}
                active={isActive && showBottomBorder}
                onClick={() => onTabClick(tab.id)}
                className="tab"
              >
                {tab.title}
              </StyledTab>
            );
          })}
        </StyledTabs>
        <TabsLine />
      </TabsWrapper>
      <Content>{tab[0].content}</Content>
    </Root>
  );
};

export default Tabs;
