import {
  IApiListResponse,
  IApiResponse,
  IQueryParams,
  IUserProfile,
} from "../common/commonTypes";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { createSequalizeUrl } from "../helpers/requestHelper";
import { getEnv } from "../helpers/env";

const NEXT_PUBLIC_API_URL = getEnv("NEXT_PUBLIC_API_URL");

export const usersService = {
  fetchUsers: async (
    params: IQueryParams
  ): Promise<IApiListResponse<IUserProfile>> =>
    fetchWrapper.get(createSequalizeUrl("user", { ...params })),

  fetchArtists: async (params: IQueryParams = {}) =>
    usersService.fetchUsers({
      ...params,
      where: {
        ...params.where,
        // only include approved artists
        approved_artist: true,
      },
    }),

  searchArtists: async (searchPhrase: string, params: IQueryParams = {}) =>
    usersService.fetchArtists({
      ...params,
      where: {
        username: {
          $iLike: `%${searchPhrase}%`,
        },
      },
    }),

  fetchUserByUrl: async (uri: string): Promise<IUserProfile> => {
    const response: IApiResponse = await fetchWrapper.get(
      `${NEXT_PUBLIC_API_URL}/user/${uri}/uri`
    );

    return response.data;
  },
};
