import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { IMusic, IPlaylistConfig } from "../common/commonTypes";
import {
  setNextTrackHandler,
  setPreviousTrackHandler,
  updateMediaSession,
} from "../helpers/mediaSessionHelper";
import { useAudioPlayer } from "../common/hooks/useAudioPlayer";
import {
  localStorageHelper,
  LocalStorageKeys,
} from "../helpers/localStorageHelper";
import { getPreviewSrc, preloadTrack } from "../helpers/musicHelper";

export interface IGlobalPlayerValues {
  isOpen: boolean;
  closePlayer: () => void;

  isBuffering: boolean;

  track: IMusic | null;
  triggerTrack: (track: IMusic, playlist?: IMusic[]) => void;

  duration: number;

  isPlaying: boolean;
  play: () => void;
  pause: () => void;
  togglePlay: () => void;

  playProgress: number;
  onProgressChange: (progress: number) => void;

  volume: number;
  onVolumeChange: (volume: number) => void;
  toggleMute: () => void;

  previousTrack?: IMusic;
  previous: () => void;
  previousDisabled: boolean;
  nextTrack?: IMusic;
  next: () => void;
  nextDisabled: boolean;

  repeatMode: boolean;
  toggleRepeatMode: () => void;
}

export const GlobalPayerContext = createContext<IGlobalPlayerValues>({
  isOpen: false,
  closePlayer: () => {},
  isBuffering: false,
  track: null,
  triggerTrack: () => {},
  duration: 0,
  isPlaying: false,
  play: () => {},
  pause: () => {},
  togglePlay: () => {},
  playProgress: 0,
  onProgressChange: () => {},
  volume: 1,
  onVolumeChange: () => {},
  toggleMute: () => {},
  previous: () => {},
  previousDisabled: true,
  next: () => {},
  nextDisabled: true,
  repeatMode: false,
  toggleRepeatMode: () => {},
});

interface IGlobalPlayerProviderProps {
  children: ReactNode;
}

export const GlobalPlayerProvider = ({
  children,
}: IGlobalPlayerProviderProps) => {
  const audioPlayer = useRef<HTMLAudioElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tracks, setTracks] = useState<IMusic[]>([]);
  const [index, setIndex] = useState(0);
  const [repeatMode, setRepeatMode] = useState(false);
  const currentTrack = tracks[index];
  const nextTrack = tracks[index + 1];
  const previousTrack = tracks[index - 1];
  const previousDisabled = index === 0;
  const nextDisabled = index === tracks.length - 1;
  const preventAutoplay = useRef(false);

  const {
    volume,
    duration,
    playProgress,
    setPlayProgress,
    isPlaying,
    play,
    pause,
    togglePlay,
    onTimeUpdate,
    onProgressChange,
    onVolumeChange,
    toggleMute,
    onEnded: onTrackEnded,
    onDurationChange,
    onWaiting,
    onPlaying,
    stopPlayer,
    isBuffering,
  } = useAudioPlayer(audioPlayer);

  const triggerTrack = (newTrack: IMusic, playlist?: IMusic[]) => {
    if (currentTrack && currentTrack.id === newTrack.id) {
      togglePlay();
      return;
    }

    if (!playlist) {
      setTracks([newTrack]);
      setIndex(0);
      return;
    }

    setTracks(playlist);
    setIndex(playlist.findIndex(({ id }) => id === newTrack.id) || 0);
  };

  const previous = useCallback(() => {
    setIndex((value) => Math.max(value - 1, 0));
  }, []);

  const next = useCallback(() => {
    setIndex((value) => Math.min(value + 1, tracks.length - 1));
  }, [tracks]);

  const closePlayer = () => {
    setIsOpen(false);
    stopPlayer();
    localStorageHelper.remove(LocalStorageKeys.playlist);
  };

  const onEnded = () => {
    if (nextDisabled) {
      onTrackEnded();
    } else {
      next();
    }
  };

  const toggleRepeatMode = () => setRepeatMode((value) => !value);

  useEffect(() => {
    if (!currentTrack) {
      return;
    }

    if (preventAutoplay.current) {
      preventAutoplay.current = false;
      return;
    }

    setPlayProgress(0);
    play();
  }, [currentTrack]);

  useEffect(() => {
    if (isPlaying) {
      setIsOpen(true);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (nextTrack) {
      preloadTrack(nextTrack);
    }
  }, [nextTrack?.id]);

  // Local storage handlers
  useEffect(() => {
    const playlistConfig = localStorageHelper.get<IPlaylistConfig>(
      LocalStorageKeys.playlist
    );

    if (playlistConfig) {
      try {
        const { playlist, trackId } = playlistConfig;
        preventAutoplay.current = true;
        setTracks(playlistConfig.playlist);
        setIndex(playlist.findIndex(({ id }) => id === trackId) || 0);
        setIsOpen(true);
      } catch (error) {
        localStorageHelper.remove(LocalStorageKeys.playlist);
      }
    }
  }, []);

  useEffect(() => {
    if (tracks && currentTrack?.id) {
      localStorageHelper.set<IPlaylistConfig>(LocalStorageKeys.playlist, {
        playlist: tracks,
        trackId: currentTrack.id,
      });
    }
  }, [tracks, currentTrack?.id]);

  // Media Session handlers
  useEffect(() => {
    if (currentTrack && isPlaying) {
      updateMediaSession(currentTrack, {
        play,
        pause,
        stop: closePlayer,
        seekTo: (details) => {
          if (typeof details.seekTime === "number") {
            onProgressChange(details.seekTime);
          }
        },
      });
    }
  }, [currentTrack, isPlaying]);

  useEffect(() => {
    if (currentTrack && isPlaying) {
      setPreviousTrackHandler(previousDisabled ? null : previous);
    }
  }, [currentTrack, isPlaying, previousDisabled, previous]);

  useEffect(() => {
    if (currentTrack && isPlaying) {
      setNextTrackHandler(nextDisabled ? null : next);
    }
  }, [currentTrack, isPlaying, nextDisabled, next]);

  return (
    <GlobalPayerContext.Provider
      value={{
        isOpen,
        closePlayer,
        isBuffering,
        track: currentTrack,
        triggerTrack,
        duration,
        isPlaying,
        play,
        pause,
        togglePlay,
        playProgress,
        onProgressChange,
        volume,
        onVolumeChange,
        toggleMute,
        previousTrack,
        previous,
        previousDisabled,
        nextTrack,
        next,
        nextDisabled,
        repeatMode,
        toggleRepeatMode,
      }}
    >
      {children}
      {currentTrack && (
        <audio
          style={{ display: "none" }}
          ref={audioPlayer}
          src={getPreviewSrc(currentTrack)}
          onTimeUpdate={onTimeUpdate}
          onEnded={onEnded}
          onDurationChange={onDurationChange}
          onWaiting={onWaiting}
          onPlaying={onPlaying}
          loop={repeatMode}
        />
      )}
    </GlobalPayerContext.Provider>
  );
};

export const useGlobalPlayer = () => useContext(GlobalPayerContext);
