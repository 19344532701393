import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useThrottledCallback } from "use-debounce";

export interface IWindowSize {
  width?: number;
  height?: number;
}

export const WindowSizeContext = createContext<IWindowSize>({
  width: undefined,
  height: undefined,
});

interface IProps {
  children: ReactNode;
}

const getWindowSize = (): IWindowSize => ({
  width: window.innerWidth,
  height: window.innerHeight,
});

export const WindowSizeProvider = ({ children }: IProps) => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: undefined,
    height: undefined,
  });

  const onResize = useThrottledCallback(
    () => setWindowSize(getWindowSize()),
    100
  );

  useEffect(() => {
    window.addEventListener("resize", onResize);

    setWindowSize(getWindowSize());

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <WindowSizeContext.Provider value={windowSize}>
      {children}
    </WindowSizeContext.Provider>
  );
};

export const useWindowSize = () => {
  return useContext(WindowSizeContext);
};
