import React, { ReactNode } from "react";
import Link, { LinkProps } from "next/link";
import { UrlObject } from "url";
import styled, { css } from "styled-components";
import Icon from "../Icon/Icon";
import { getParagraph14BoldTypography } from "../../Typography";
import { IconKeys, IStyleableProps } from "../../commonTypes";
import LoadingSpinner from "../LoadingSpinner";
import { getAbsoluteFill, getFlexCenter } from "../../styles";

interface IStyledButtonProps {
  $height?: string;
  $width?: string;
  $background?: string;
  $backgroundHover?: string;
  $fullWidth?: boolean;
  $borderColor?: string;
  $isLoading?: boolean;
}

interface IStyledLabelProps {
  $color?: string;
}

const StyledButton = styled.button<IStyledButtonProps>`
  background: ${({ theme, $background }) => $background || theme.accent.normal};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  height: ${({ $height }) => $height || "40px"};
  ${getFlexCenter};
  cursor: pointer;
  text-decoration: none;
  padding: 0 16px;
  width: ${({ $width, $fullWidth }) =>
    $fullWidth ? "100%" : $width || "auto"};
  transition: background-color 200ms linear;
  position: relative;
  border: ${(props) =>
    props.$borderColor ? `1px solid ${props.$borderColor}` : "none"};

  &:hover:not(:disabled) {
    background: ${({ theme, $backgroundHover }) =>
      $backgroundHover || theme.accent.dark};
  }

  &:disabled {
    cursor: not-allowed;

    ${({ $isLoading, theme }) =>
      !$isLoading &&
      css`
        background-color: ${theme.greyColor};
      `};
  }
`;

const StyledButtonContent = styled.div<{ $isLoading: boolean }>`
  width: 100%;
  ${getFlexCenter};
  opacity: ${({ $isLoading }) => ($isLoading ? 0 : 1)};
`;

const SpinnerWrapper = styled.div`
  z-index: 1;
  ${getAbsoluteFill}
  ${getFlexCenter}
`;

export const Label = styled.p<IStyledLabelProps>`
  ${getParagraph14BoldTypography};
  color: ${({ theme, $color }) => $color || theme.primaryColor};
  white-space: nowrap;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const StyledIcon = styled(Icon)`
  margin-left: 14px;
`;

export interface IButtonProps {
  text?: string;
  label?: ReactNode;
  iconName?: IconKeys;
  iconSize?: string;
  disabled?: boolean;
  height?: string;
  width?: string;
  fullWidth?: boolean;
  onClick?: (e?: any) => void;
  background?: string;
  backgroundHover?: string;
  labelColor?: string;
  borderColor?: string;
  children?: ReactNode;
  isLoading?: boolean;
  href?: string | UrlObject;
  linkProps?: LinkProps;
  [prop: string]: any;
}

const Button = ({
  text,
  label,
  iconName,
  iconSize,
  disabled,
  onClick,
  height,
  width,
  fullWidth,
  background,
  backgroundHover,
  children,
  isLoading,
  labelColor,
  borderColor,
  href,
  linkProps,
  ...props
}: IButtonProps & IStyleableProps) => {
  const renderButton = () => {
    const disabledProps = href ? {} : { disabled: disabled || isLoading };

    return (
      <StyledButton
        as={href ? "a" : "button"}
        onClick={onClick}
        $background={background}
        $fullWidth={fullWidth}
        $backgroundHover={backgroundHover}
        $height={height}
        $width={width}
        $borderColor={borderColor}
        $isLoading={!!isLoading}
        {...disabledProps}
        {...props}
      >
        <StyledButtonContent $isLoading={!!isLoading}>
          {children || (
            <>
              {label || <Label $color={labelColor}>{text}</Label>}
              {iconName && (
                <StyledIcon name={iconName} size={iconSize || "14px"} />
              )}
            </>
          )}
        </StyledButtonContent>
        {isLoading && (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        )}
      </StyledButton>
    );
  };

  if (href) {
    return (
      <Link {...linkProps} passHref href={href}>
        {renderButton()}
      </Link>
    );
  }

  return renderButton();
};

export default Button;
