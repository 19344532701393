import { useEffect, useRef } from "react";

export const useUpdateEffect = (
  callback: () => void,
  dependencies: unknown[]
) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      callback();
    } else {
      didMount.current = true;
    }
  }, dependencies);
};
