import { Contract } from "@ethersproject/contracts/lib/index.js";
import { getAddress } from "@ethersproject/address/lib/index.js";
import {
  IApiListResponse,
  IApiResponse,
  INft,
  IQueryParams,
} from "../common/commonTypes";
import { fetchWrapper } from "../helpers/fetchWrapper";
import { createSequalizeUrl } from "../helpers/requestHelper";
import { fetchWrapperProtected } from "../helpers/fetchWrapperProtected";
import { MusicFileTypeEnum } from "../common/commonEnums";

import { getEnv } from "../helpers/env";

const NEXT_PUBLIC_API_URL = getEnv("NEXT_PUBLIC_API_URL");

export const nftService = {
  fetchNfts: async (params: IQueryParams): Promise<IApiListResponse<INft>> =>
    fetchWrapper.get(createSequalizeUrl("nft", params)),

  fetchNft: async (id: string) => {
    const response: IApiResponse = await fetchWrapper.get(
      `${NEXT_PUBLIC_API_URL}/nft/${id}`
    );
    return response;
  },

  fetchNftForListing: async (listingId: string): Promise<INft> => {
    const nftResponse = await nftService.fetchNfts({
      limit: 1,
      where: {
        listing_id: listingId,
      },
    });
    return nftResponse.data.items[0];
  },

  downloadNft: async (
    id: string,
    type: MusicFileTypeEnum,
    address: string,
    signature: string
  ): Promise<string> => {
    const response: IApiResponse = await fetchWrapperProtected.post(
      `${NEXT_PUBLIC_API_URL}/nft/${id}/download`,
      {
        type,
        address,
        signature,
      }
    );

    return response.data;
  },

  getOnChainNfts: async (
    nftContract: Contract,
    nfts: INft[]
  ): Promise<INft[]> => {
    const onChainNfts: (INft | undefined)[] = await Promise.all(
      nfts.map(async (nft) => {
        try {
          const owner = await nftContract?.ownerOf(nft.metadata.id);

          if (
            getAddress(owner) === getAddress(nft.listing.buyer_address || "")
          ) {
            return nft;
          }
        } catch (error) {
          return undefined;
        }
      })
    );

    return onChainNfts.filter((nft) => !!nft) as INft[];
  },
};
