import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { IconKeys, IMusic } from "../../commonTypes";

interface INftItemConfig {
  id: string;
  icon: IconKeys;
  text: string;
  alwaysIncluded?: boolean;
}

interface IProps {
  music: IMusic;
  withRights?: boolean;
  renderItem: (item: INftItemConfig) => ReactNode;
}

const NftIncludedItems = ({
  music,
  renderItem,
  withRights = false,
}: IProps) => {
  const { t } = useTranslation();

  const musicItems: INftItemConfig[] = [
    {
      id: "master_id",
      icon: "masterTrack",
      alwaysIncluded: true,
      text: t("nft-item-full"),
    },
    {
      id: "backing_id",
      icon: "instrumental",
      text: t("nft-item-instrumental"),
    },
    {
      id: "vocal_id",
      icon: "vocal",
      text: t("nft-item-vocal"),
    },
    {
      id: "cover_id",
      icon: "cover",
      alwaysIncluded: true,
      text: t("nft-item-cover"),
    },
    {
      id: "poster_id",
      icon: "cover",
      text: t("nft-item-poster"),
    },
    {
      id: "video_id",
      icon: "video",
      text: t("nft-item-video"),
    },
  ];

  const withRightsItem: INftItemConfig = {
    id: "with_rights",
    icon: "ownershipRights",
    text: t("nft-item-ownership"),
  };

  const includedItems = musicItems.filter(
    // @ts-ignore
    (item) => item.alwaysIncluded || !!music[item.id]
  );

  if (withRights) {
    includedItems.push(withRightsItem);
  }

  return <>{includedItems.map(renderItem)}</>;
};

export default NftIncludedItems;
