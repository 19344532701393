import React from "react";
import styled from "styled-components";
import { INavLink } from "../../commonTypes";
import NavItem from "./NavItem";
import { getHorizontalGap } from "../../styles";

export const NavList = styled.div`
  display: flex;
  flex-direction: row;
  ${getHorizontalGap("30px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.laptopL}) {
    display: none;
  }
`;

interface INavProps {
  items: INavLink[];
}

const Nav = ({ items }: INavProps) => {
  return (
    <NavList>
      {items.map((item: INavLink, index: number) => {
        return <NavItem key={index} text={item.name} path={item.path} />;
      })}
    </NavList>
  );
};

export default Nav;
