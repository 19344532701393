import { useWindowSize } from "../../context/WindowSizeContext";

// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
export const useIsClientRender = () => {
  // TODO: create separate provider/zustand field with info about current environment. For now we can make use of
  // useWindowSize hook which does what we need - if width is defined then we are ready on client after rehydration
  const { width } = useWindowSize();

  return width !== undefined;
};
