import styled, { useTheme } from "styled-components";
import { Heading6Bold } from "../../../Typography/Heading6";
import Expand from "../../Expand/Expand";
import LockedItem from "./LockedItem";
import { Paragraph14RegularSecondary } from "../../../Typography/Paragraph14";
import { IListing } from "../../../commonTypes";
import { useTranslation } from "react-i18next";
import {
  useStakedQuery,
  useStakedQueryForChain,
} from "../../../../queries/staking.query";
import { useWeb3 } from "@chainsafe/web3-context";
import useChain from "../../../hooks/useChain";
import LoadingSpinner from "../../LoadingSpinner";

const ClosedLockedWrapper = styled.div`
  div {
    display: flex;
    align-items: center;

    div {
      margin-right: 8px;
    }
  }
`;

const EthBalance = styled(Heading6Bold)`
  opacity: 0.5;
`;

const Empty = styled.div`
  padding: 12px;
  text-align: center;
`;

const Results = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-top: -10px;
`;

const ClosedLocked = () => {
  const theme = useTheme();
  const { data } = useStakedQuery();
  const { t } = useTranslation();
  return (
    <ClosedLockedWrapper>
      <Paragraph14RegularSecondary>
        {/* {t("stake-total-locked")} */}
        Locked deposits
      </Paragraph14RegularSecondary>
    </ClosedLockedWrapper>
  );
};

const Locked = () => {
  const { chain } = useChain();
  const { address } = useWeb3();
  const { data, isLoading } = useStakedQueryForChain(chain?.id, address);
  const { t } = useTranslation();

  let lockedItems =
    data &&
    Object.values(data)
      ?.filter((stakedData) => stakedData.lockedItems.length > 0)
      .map((stakedData) => {
        return stakedData.lockedItems.map((item: IListing) => {
          return <LockedItem key={item.id} listing={item} />;
        });
      });

  const isEmpty = lockedItems?.length == 0;

  return (
    <div>
      <Expand closedContent={<ClosedLocked />}>
        {isLoading && <LoadingSpinner />}

        <Empty>
          {isEmpty && (
            <Paragraph14RegularSecondary>
              {t("stake-locked-no-bids")}
            </Paragraph14RegularSecondary>
          )}
        </Empty>
        <Results>
          {data &&
            Object.values(data)?.map((stakedData) => {
              return stakedData.lockedItems.map((item: IListing) => {
                return <LockedItem key={item.id} listing={item} />;
              });
            })}
        </Results>
      </Expand>
    </div>
  );
};

export default Locked;
