import Tooltip from "rc-tooltip";
import { TooltipProps } from "rc-tooltip/es/Tooltip";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import { zIndex as zIndexConfig } from "../../../constants/zIndex";

export interface IOverlayProps extends TooltipProps {
  zIndex?: number;
}

const Overlay = ({
  zIndex = zIndexConfig.overlay,
  placement = "bottomLeft",
  children,
  ...props
}: IOverlayProps) => {
  const { isTablet } = useMobileResponsive();
  const trigger = isTablet ? "click" : "hover";

  return (
    <>
      <Tooltip
        overlayStyle={{ zIndex }}
        trigger={trigger}
        mouseEnterDelay={0.3}
        placement={placement}
        destroyTooltipOnHide
        {...props}
      >
        {/* child must be wrapped in native dom element (like div) or it should forwardRef to native element (see Icon.tsx) */}
        {/* https://github.com/react-component/tooltip#important-note */}
        {children}
      </Tooltip>
    </>
  );
};

export default Overlay;
