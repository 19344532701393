import { ethers } from "ethers";
import { IAsset } from "../common/commonTypes";
import ERC20 from "../contracts/ERC20Token.sol/ERC20Token.json";
const ERC20Abi = ERC20.abi;

export function getTokenContract(
  token: IAsset,
  provider: ethers.providers.Provider
): ethers.Contract {
  return new ethers.Contract(token.address, ERC20Abi, provider);
}

export function tokenToUsdPriceLocaleString(
  amount: string | number,
  exchangeRate: number
): string {
  return (Number(amount) * (exchangeRate ?? 0)).toLocaleString();
}
