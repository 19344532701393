import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getHeading4PoppinsBoldTypography } from "../../../Typography/Heading4";
import { getHeading6PoppinsBoldTypography } from "../../../Typography/Heading6";
import { Paragraph16Regular } from "../../../Typography/Paragraph16";

const Root = styled.div`
  padding: 24px 36px 36px 36px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding: 0;
  }
`;

const Title = styled.h5`
  ${getHeading4PoppinsBoldTypography};
  line-height: 35px;
  margin-bottom: 36px;
  color: ${({ theme }) => theme.primaryColor};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${getHeading6PoppinsBoldTypography};
  }
`;

const SectionsWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  grid-gap: 24px;
`;

const Section = styled(Paragraph16Regular)`
  line-height: 24px;
  opacity: 0.9;
  white-space: pre-wrap;
`;

const About = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Title>{t("about-modal-title")}</Title>
      <SectionsWrapper>
        <Section>{t("about-modal-section-1")}</Section>
        <Section>{t("about-modal-section-2")}</Section>
        <Section>{t("about-modal-section-3")}</Section>
        <Section>{t("about-modal-section-4")}</Section>
      </SectionsWrapper>
    </Root>
  );
};

export default About;
