import React from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { ITab } from "../../../commonTypes";
import Account from "./Account";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";
import { Dot, TabTitle } from "../../styled";
import Tabs, { TabsLine } from "../../Tabs/Tabs";
import Menu from "../Menu";
import Stake from "./Stake";
import { useWeb3 } from "@chainsafe/web3-context";

const TabContent = styled.div`
  padding-top: 32px;
`;

const StyledTabTitle = styled.div`
  display: flex;
  align-items: flex-start;

  div {
    margin-left: 9px;
    margin-top: 3px;
    width: 12px;
    height: 12px;
  }
`;

const Wrapper = styled.div`
  width: 396px;

  ${TabsLine} {
    margin: 0 -32px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 100%;
  }
`;

const WalletConnectedIndicator = () => {
  const theme = useTheme();
  const { address } = useWeb3();

  const color = address ? theme.greenColor : theme.darkBorderColor;

  return <Dot color={color} />;
};

const AccountMenu = () => {
  const { isOpen, handleClose, activeTab, setActiveTab } = useAccountDropdown();
  const { t } = useTranslation();

  const tabs: ITab[] = [
    {
      id: 0,
      title: <TabTitle active={activeTab === 0}>{t("account")}</TabTitle>,
      content: (
        <TabContent>
          <Account key="account" />
        </TabContent>
      ),
    },
    {
      id: 1,
      title: (
        <StyledTabTitle>
          <TabTitle active={activeTab === 1}>{t("wallet-wallet")}</TabTitle>
          <WalletConnectedIndicator />
        </StyledTabTitle>
      ),
      content: (
        <TabContent>
          <Stake />
        </TabContent>
      ),
    },
  ];

  return (
    <Menu isOpen={isOpen} onClose={handleClose}>
      <Wrapper>
        <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
      </Wrapper>
    </Menu>
  );
};

export default AccountMenu;
