import { useQuery } from "react-query";
import { IAsset, IChain, IQueryParams } from "../common/commonTypes";
import { assetService } from "../api/assetService";
import { ZERO_ADDRESS } from "../constants/assets";

export const useTokenQuery = (chain?: IChain) => {
  return useQuery<{ tokens: IAsset[]; nativeToken: IAsset }>(
    [chain?.id],
    async () => {
      const options: IQueryParams = {};

      if (chain?.id) {
        options.where = {
          chain_id: chain!.id,
        };
      }

      const tokens = await assetService.fetchAssets(options);

      const defaultAsset: IAsset = {
        address: ZERO_ADDRESS,
        symbol: "eth",
        chain: chain!,
        coingecko_id: "ethereum",
        decimals: "18",
        id: "eth",
        logoURI: "",
        name: "ether",
      };

      const nativeToken = tokens.data.items.find(
        (token: IAsset) => token.address == ZERO_ADDRESS
      );

      return {
        tokens: tokens.data.items,
        nativeToken: nativeToken ?? defaultAsset,
      };
    },
    {
      enabled: true,
    }
  );
};
