import { IDeployment } from "../common/commonTypes";
import { useQuery } from "react-query";
import { QueryKeys } from "../common/queryKeys";
import { deploymentService } from "../api/deploymentService";

const fetchDeployments = async (): Promise<IDeployment[]> => {
  const result = await deploymentService.fetchDeployments({
    // TODO: figure out why this doesnt work???
    // order: [["version", "DESC"]],
  });
  return result.data.items;
};

export const useWeb3Query = () => {
  return useQuery<IDeployment[]>(QueryKeys.web3, fetchDeployments, {
    staleTime: Infinity,
  });
};
