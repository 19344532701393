import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import { QueryKeys } from "../common/queryKeys";
import { accountService } from "../api/accountService";
import { useSession } from "./authentication.query";
import { nextPageParamGetter } from "../helpers/paginationHelper";

const FOLLOWERS_PAGE_SIZE = 10;

export const useFollowersCountQuery = (userId: string, enabled = true) => {
  return useQuery(
    [QueryKeys.followersCount, userId],
    () => accountService.getFollowerCount(userId),
    { enabled }
  );
};

export const useFollowingCountQuery = (userId: string, enabled = true) => {
  return useQuery(
    [QueryKeys.followingCount, userId],
    () => accountService.getFollowingCount(userId),
    { enabled }
  );
};

export const useIsFollowedByMeQuery = (userId: string) => {
  const { user: loggedUser } = useSession();

  const queryResponse = useQuery(
    [QueryKeys.isFollowedByMe, loggedUser?.id, userId],
    async () => {
      if (!loggedUser?.id) {
        return false;
      }

      return accountService.isFollowing(loggedUser.id, userId);
    },
    { enabled: !!loggedUser?.id }
  );

  return {
    isFollowedByMe: queryResponse.data || false,
    ...queryResponse,
  };
};

export const useFollowersListQuery = (userId: string) => {
  return useInfiniteQuery(
    [QueryKeys.followersList, userId],
    async ({ pageParam }) =>
      accountService.getFollowers(userId, {
        limit: FOLLOWERS_PAGE_SIZE,
        offset: pageParam || 0,
      }),
    {
      getNextPageParam: nextPageParamGetter(FOLLOWERS_PAGE_SIZE),
    }
  );
};

export const useFollowingListQuery = (userId: string) => {
  return useInfiniteQuery(
    [QueryKeys.followingList, userId],
    async ({ pageParam }) =>
      accountService.getFollowing(userId, {
        limit: FOLLOWERS_PAGE_SIZE,
        offset: pageParam || 0,
      }),
    {
      getNextPageParam: nextPageParamGetter(FOLLOWERS_PAGE_SIZE),
    }
  );
};

const useOnFollowChangeSuccess = () => {
  const queryClient = useQueryClient();
  const { user: loggedUser } = useSession();

  return (data: unknown, userId: string) =>
    Promise.all([
      queryClient.invalidateQueries([
        QueryKeys.isFollowedByMe,
        loggedUser?.id,
        userId,
      ]),
      queryClient.invalidateQueries([QueryKeys.followersCount, userId]),
      queryClient.invalidateQueries([QueryKeys.followingCount, loggedUser?.id]),
      queryClient.invalidateQueries([QueryKeys.followersList, userId]),
      queryClient.invalidateQueries([QueryKeys.followingList, loggedUser?.id]),
    ]);
};

export const useFollowMutation = () => {
  const onSuccess = useOnFollowChangeSuccess();

  return useMutation(accountService.follow, {
    onSuccess,
  });
};

export const useUnfollowMutation = () => {
  const onSuccess = useOnFollowChangeSuccess();

  return useMutation(accountService.unfollow, {
    onSuccess,
  });
};
