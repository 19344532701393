import { ReactEventHandler } from "react";
import styled from "styled-components";
import FadeImage from "./FadeImage/FadeImage";
import Icon from "./Icon/Icon";
import { IUserProfile } from "../commonTypes";
import { IMAGE_SIZES } from "../../constants/imageSizes";

const Wrapper = styled.div<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  position: relative;
`;

const StyledAvatar = styled(FadeImage)`
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ApproveIcon = styled(Icon)`
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translate(30%, 30%);
`;

const StyledIcon = styled(Icon)`
  border-radius: 5px;
`;

export interface IAvatarProps {
  user?: IUserProfile;
  size?: string;
  style?: any;
  className?: string;
  imageResizeWidth?: number;
  onClick?: ReactEventHandler;
  onMouseEnter?: ReactEventHandler;
}

const Avatar = ({
  user,
  size = "40px",
  imageResizeWidth = IMAGE_SIZES.userProfilePicture.small,
  ...props
}: IAvatarProps) => {
  const avatarImage = user?.profile?.image_profile;
  const avatarUrl = avatarImage?.url;
  const avatarColor = user?.color || "#fff";

  return (
    <Wrapper size={size} {...props}>
      {avatarUrl ? (
        <StyledAvatar
          src={avatarUrl}
          mimeType={avatarImage?.mime}
          resizeWidth={imageResizeWidth}
        />
      ) : (
        <StyledIcon
          name="avatar"
          color={avatarColor}
          height="100%"
          width="100%"
        />
      )}
      {user?.approved_artist && (
        <ApproveIcon size="14px" name="approvedArtist" />
      )}
    </Wrapper>
  );
};

export default Avatar;
