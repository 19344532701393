import { ethers } from "ethers";
import { IAsset } from "../common/commonTypes";
import { ZERO_ADDRESS } from "../constants/assets";

export const getCurrencyLabel = (asset: IAsset) => {
  return asset?.symbol;
};

export function getFormattedAmount(amount: string, asset?: IAsset) {
  const decimals = asset?.decimals ?? 18;
  const value = amount ?? "0";
  return truncateAmount(ethers.utils.formatUnits(value, decimals), 3);
}

export function getFormattedAmountWithLabel(
  amount: string = "0",
  asset?: IAsset
) {
  return `${getFormattedAmount(amount, asset)} ${asset?.symbol}`;
}

export function truncateAmount(
  amount: number | string | undefined,
  decimals: number = 3
): string {
  if (!amount) {
    return "0";
  }

  return Number(amount).toLocaleString("default", {
    maximumFractionDigits: decimals,
  });
}

export function stripTrailingSlash(url: string): string {
  if (url.charAt(url.length - 1) === "/") {
    return url.substring(0, url.length - 1);
  }

  return url;
}

export function isEther(asset: IAsset): boolean {
  return asset.address === ZERO_ADDRESS;
}

export function isERC20(asset: IAsset): boolean {
  return !isEther(asset);
}
