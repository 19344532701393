import styled, { css } from "styled-components";
import { Paragraph14Bold } from "../../../Typography/Paragraph14";

export const LinksSection = styled.div``;

export const LinkSectionTitleContainer = styled.div<{
  isActive: boolean;
  isTablet: boolean;
}>`
  cursor: ${(props) => (props.isTablet ? "pointer" : "auto")};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(props) =>
    props.isTablet
      ? props.isActive
        ? "30px"
        : "0px"
      : props.isActive
      ? "0px"
      : "15px"};
`;

export const LinkSectionTitle = styled(Paragraph14Bold)`
  text-transform: uppercase;
  opacity: 0.7;
`;

export const LinksContainer = styled.div<{
  isTablet: boolean;
}>`
  display: grid;
  row-gap: ${(props) => (props.isTablet ? "24px" : "15px")};
`;

export interface AccordionBodyProps {
  active: boolean;
  bodyHeight: number | null;
}

export const LinkBody = styled.div<AccordionBodyProps>`
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s;

  ${({ active, bodyHeight }: AccordionBodyProps) =>
    active &&
    css`
      height: ${bodyHeight}px;
    `}
`;

export const LinksContent = styled.div`
  height: auto;
`;

export const StyledLink = styled(Paragraph14Bold)`
  cursor: pointer;
  white-space: nowrap;
`;

export const Divider = styled.hr`
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: block;
    border-color: ${({ theme }) => theme.borderColor};
    border-style: solid;
    border-width: 1px;
    border-top-width: 0;
    margin-top: 38px;
    margin-bottom: 38px;
  }
`;
