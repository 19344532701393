import { ReactNode, useEffect, useRef } from "react";
import ReactModal from "react-modal";
import styled, { useTheme } from "styled-components";
import { zIndex } from "../../constants/zIndex";
import { navbarHeight } from "../styles";
import { isEventOutside } from "../../helpers/domHelper";

type Props = {
  isOpen: boolean;
  children?: ReactNode;
  onClose: () => void;
};

const StyledDropdown = styled(ReactModal)`
  &:focus-visible {
    border: none;
    outline: none;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  margin: 0 20px;
  background: none !important;
  background-color: ${(props) => props.theme.backgroundColor};
  pointer-events: none;
`;

const Content = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.md};
  width: fit-content;
  min-width: 396px;
  max-width: 100%;
  padding: 32px;
  position: absolute;
  top: ${navbarHeight.desktop}px;
  right: 0;
  pointer-events: all;
  padding-top: 16px;
  overflow: hidden;
  max-height: calc(100vh - 84px);
  overflow-y: auto;
`;

const Dropdown = ({ isOpen, children, onClose }: Props) => {
  const theme = useTheme();
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      //close dropdown on click outside (including navbar)
      const handleWindowClick = (event: MouseEvent) => {
        if (isEventOutside(event, dropdownRef.current)) {
          onClose();
        }
      };

      window.addEventListener("click", handleWindowClick, { capture: true });

      return () => {
        window.removeEventListener("click", handleWindowClick);
      };
    }
  }, [isOpen]);

  return (
    <StyledDropdown
      onRequestClose={onClose}
      style={{
        overlay: {
          background: theme.modalOverlayColor,
          zIndex: zIndex.dropdown,
        },
      }}
      isOpen={isOpen}
    >
      <Wrapper>
        <Content ref={dropdownRef}>{children}</Content>
      </Wrapper>
    </StyledDropdown>
  );
};

export default Dropdown;
