export const zIndex = {
  searchResults: 1010,
  dropdown: 1020,
  globalPlayer: 1030,
  navbar: 1040,
  modal: 1050,
  overlay: 1060,
  walletSelect: 1070,
  routeLoader: 1080,
};
