import React from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { getHeading4BoldTypography } from "../../../Typography";
import { useWeb3 } from "@chainsafe/web3-context";
import { useRouter } from "next/router";
import { routes } from "../../../../routes";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";
import Icon from "../../Icon/Icon";
import {
  Paragraph14Bold,
  Paragraph14RegularSecondary,
} from "../../../Typography/Paragraph14";
import { Paragraph16Bold } from "../../../Typography/Paragraph16";
import { Dot, Truncate } from "../../styled";
import { ConnectWallet } from "../Wallet/Wallet";
import { useStaking } from "../../../hooks/useStaking";
import LoadingSpinner from "../../LoadingSpinner";
import WalletIcon from "../Wallet/WalletIcon";
import {
  useLogout,
  useSession,
} from "../../../../queries/authentication.query";
import { useTokenQuery } from "../../../../queries/token.query";
import useChain from "../../../hooks/useChain";

const Name = styled.h4`
  ${getHeading4BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  margin-bottom: 32px;
`;
const Actions = styled.ul`
  text-decoration: none;
  list-style-type: none;
  margin-top: 26px;
`;
const Action = styled.li`
  text-decoration: none;
  display: flex;
  padding: 14px 0;
  cursor: pointer;

  p {
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    color: ${(props) => props.theme.primaryColor};
    opacity: 0.7;
  }
`;

export const Accounts = styled.div<{ inline?: boolean }>`
  text-align: left;

  ${(props) =>
    props.inline &&
    `
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 24px;
  `}
`;

export const StyledAccount = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccountBadge = styled.div`
  background: ${(props) => props.theme.lightBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;

  &.overview {
    justify-content: space-between;
    max-height: 48px;
    cursor: pointer;
    padding-right: 0;

    .icon {
      margin-right: 10px;
    }

    div {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  div {
    margin-right: 12px;
  }
`;

export const BalanceLabel = styled(Paragraph14RegularSecondary)`
  cursor: pointer;
`;

export const AccountBadgeLeft = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.borderColor};
  padding: 12px 0;
  border-radius: ${(props) => props.theme.borderRadius.md};
`;

const StakingOverview = () => {
  const theme = useTheme();
  const { wallet, ethBalance, address } = useWeb3();
  const { openWalletMenu, openStakingSection } = useAccountDropdown();
  const { isLoading } = useStaking();
  const { t } = useTranslation();
  const { chain } = useChain();
  const { data } = useTokenQuery(chain);

  const handleConnectWalletClicked = () => {
    openWalletMenu();
  };

  if (!address) {
    return <ConnectWallet onClick={handleConnectWalletClicked} />;
  }

  if (isLoading) {
    return (
      <LoadingWrapper>
        <LoadingSpinner />
      </LoadingWrapper>
    );
  }

  // if (Number(staked.total) <= 0) {
  //   return <StakeEmptyState />;
  // }

  return (
    <Accounts inline>
      <StyledAccount>
        <Paragraph14RegularSecondary>
          {t("stake-available")}:
        </Paragraph14RegularSecondary>
        <AccountBadge className="overview" onClick={openWalletMenu}>
          <div>
            <WalletIcon size="30px" name={wallet?.name} />
            <Paragraph16Bold>
              {ethBalance?.toLocaleString()} {data?.nativeToken?.symbol}
            </Paragraph16Bold>
          </div>
          <Icon name="chevronRight" width="18px" className="icon" />
        </AccountBadge>
      </StyledAccount>
      <StyledAccount>
        <Paragraph14RegularSecondary>
          {t("stake-staked")}:
        </Paragraph14RegularSecondary>
        <AccountBadge className="overview" onClick={openStakingSection}>
          <div>
            <Dot color={theme.accent.normal} />
            <Paragraph16Bold>view deposits</Paragraph16Bold>
          </div>
          <Icon name="chevronRight" width="18px" className="icon" />
        </AccountBadge>
      </StyledAccount>
    </Accounts>
  );
};

const Account = () => {
  const { t } = useTranslation();
  const { mutate: logout } = useLogout();
  const router = useRouter();
  const { toggleDropdown } = useAccountDropdown();
  const { user } = useSession();

  const handleLogoutClick = () => {
    logout();
  };

  const handleProfileClick = () => {
    if (user) {
      router.push(routes.user.url(user.uri));
      toggleDropdown();
    }
  };

  return (
    <div key="account_content">
      <Truncate>
        <Name>{user?.username}</Name>
      </Truncate>

      <StakingOverview />
      {/* profle section */}
      <Actions>
        <Action onClick={handleProfileClick}>
          <Icon name="user" />
          <Paragraph14Bold>{t("my-profile")}</Paragraph14Bold>
        </Action>
        <Action onClick={handleLogoutClick}>
          <Icon name="logout" />
          <Paragraph14Bold>{t("navbar-log-out")}</Paragraph14Bold>
        </Action>
      </Actions>
    </div>
  );
};

export default Account;
