import { QueryClient } from "react-query";

interface ReactQueryService {
  queryClient: QueryClient | null;
  setQueryClient: (client: QueryClient) => void;
}

export const reactQueryService: ReactQueryService = {
  queryClient: null,
  setQueryClient: (client) => (reactQueryService.queryClient = client),
};
