import styled from "styled-components";
import Icon from "./Icon/Icon";
import React from "react";
import { captureException } from "@sentry/nextjs";

type Props = {
  onCopy?: () => void;
  payload: string;
};

const CopyIcon = styled(Icon)`
  cursor: pointer;
  margin-left: 5px;
  opacity: 0.7;
`;

const Copy = (props: Props) => {
  const onCopyClicked = (event: React.MouseEvent<SVGSVGElement>) => {
    // prevent parent click handler from firing
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(props.payload).then(
      () => {
        if (props.onCopy) {
          props.onCopy();
        }
      },
      (error) => {
        captureException(error);
      }
    );
  };

  return <CopyIcon name="copy" onClick={onCopyClicked} />;
};

export default Copy;
