import React, { ForwardedRef } from "react";
import styled from "styled-components";
import { getAbsoluteFill } from "../../styles";

interface IProgressProps {
  $progress: number;
}

const ProgressPointer = styled.div.attrs<IProgressProps>(({ $progress }) => ({
  style: {
    left: `${$progress}%`,
  },
}))<IProgressProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primaryColor};
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  visibility: hidden;
  pointer-events: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    visibility: visible;
  }
`;

const ProgressWrapper = styled.div`
  position: relative;
  width: 100%;

  &:hover {
    ${ProgressPointer} {
      visibility: visible;
    }
  }
`;

const Progress = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 2px;
  height: 4px;
  width: 100%;
`;

const ProgressBackground = styled.div`
  ${getAbsoluteFill};
  z-index: 0;
  background-color: ${({ theme }) => theme.primaryColor};
  opacity: 0.5;
`;

const ProgressContent = styled.div.attrs<IProgressProps>(({ $progress }) => ({
  style: {
    transform: `translateX(${-100 + $progress}%)`,
  },
}))<IProgressProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.accent.normal};
  z-index: 1;
`;

const InputRange = styled.input`
  width: 100%;
  position: absolute;
  height: 30px;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
`;

interface IProps {
  progress: number;
  onProgressChange: (progress: number) => void;
  onProgressChangeEnd?: () => void;
  min?: number;
  max?: number;
  step?: number;
  className?: string;
}

const RangeProgressBar = React.forwardRef(
  (
    {
      progress,
      onProgressChange,
      onProgressChangeEnd = () => {},
      min = 0,
      max = 0,
      step = 0.1,
      className,
    }: IProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const percentageProgress = max ? (progress * 100) / max : 0;

    return (
      <ProgressWrapper className={className}>
        <Progress>
          <ProgressBackground />
          <ProgressContent $progress={percentageProgress} />
        </Progress>
        <ProgressPointer $progress={percentageProgress} />
        <InputRange
          ref={ref}
          type="range"
          value={progress}
          min={min}
          max={max}
          step={step}
          onChange={(event) => onProgressChange(parseFloat(event.target.value))}
          onMouseUp={onProgressChangeEnd}
          onKeyUp={onProgressChangeEnd}
        />
      </ProgressWrapper>
    );
  }
);

RangeProgressBar.displayName = "RangeProgressBar";

export default RangeProgressBar;
