import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { GlobalStyle } from "../../theme/globalStyle";
import AppNavbar from "./AppNavbar/AppNavbar";
import { useSearch } from "../../context/SearchContext";
import SearchResults from "../../modules/SearchResults/SearchResults";
import Footer from "./Footer/Footer";
import MobileWarningModal from "./Modal/MobileWarningModal/MobileWarningModal";
import { AccountDropDownProvider } from "../../context/AccountDropdownContext";
import { navbarHeight } from "../styles";
import AboutModal from "./Modal/About/AboutModal";
import { useRouter } from "next/router";
import RouteLoader from "./RouteLoader/RouteLoader";
import GlobalPlayer from "./GlobalPlayer/GlobalPlayer";
import { zIndex } from "../../constants/zIndex";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.div`
  position: relative;
  flex: 1;
`;

const Wrapper = styled.div<{ hidden?: boolean }>`
  padding-top: ${navbarHeight.desktop}px;
  display: ${({ hidden }) => (hidden ? "none" : "block")};
  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding-top: ${navbarHeight.mobile}px;
  }
`;

const SearchOverlay = styled(Wrapper)`
  min-height: 100vh;
  z-index: ${zIndex.searchResults};
  background-color: ${({ theme }) => theme.secondaryColor};
`;

interface ILayoutProps {
  children: ReactNode;
}

const Layout = ({ children }: ILayoutProps) => {
  const router = useRouter();
  const [isRouteLoading, setIsRouteLoading] = useState(false);
  const { showSearchResults } = useSearch();
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const toggleAboutModal = () => setIsAboutModalOpen((value) => !value);

  useEffect(() => {
    const onRouteChangeStart = () => setIsRouteLoading(true);
    const onRouteChangeEnd = () => setIsRouteLoading(false);

    router.events.on("routeChangeStart", onRouteChangeStart);
    router.events.on("routeChangeComplete", onRouteChangeEnd);

    return () => {
      router.events.off("routeChangeStart", onRouteChangeStart);
      router.events.off("routeChangeComplete", onRouteChangeEnd);
    };
  }, []);

  return (
    <>
      <GlobalStyle />
      <AccountDropDownProvider>
        <AppNavbar />
      </AccountDropDownProvider>
      <Root>
        <Main>
          <Wrapper hidden={showSearchResults}>{children}</Wrapper>
          {showSearchResults && (
            <SearchOverlay>
              <SearchResults />
            </SearchOverlay>
          )}
        </Main>
        <Footer onAboutModalOpen={toggleAboutModal} />
      </Root>
      <GlobalPlayer />
      <MobileWarningModal />
      <AboutModal isOpen={isAboutModalOpen} onClose={toggleAboutModal} />
      {isRouteLoading && <RouteLoader />}
    </>
  );
};

export default Layout;
