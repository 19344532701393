import { IApiPage } from "../common/commonTypes";

export const getPagesItems = <ItemType>(pages: IApiPage<ItemType>[] = []) =>
  pages.reduce<ItemType[]>((result, page) => [...result, ...page.items], []);

export const nextPageParamGetter =
  (pageSize: number) =>
  (
    lastPage: IApiPage<unknown>,
    pages: IApiPage<unknown>[]
  ): number | undefined => {
    if (lastPage.items.length === pageSize) {
      return pages.length * pageSize;
    }
  };
