import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {
  localStorageHelper,
  LocalStorageKeys,
} from "../../../../helpers/localStorageHelper";
import useMobileResponsive from "../../../hooks/useMobileResponsive";
import Modal, { ModalContent } from "../Modal";
import Typography from "../../Typography/Typography";
import Button from "../../Buttons/Button";

const StyledModal = styled(Modal)`
  ${ModalContent} {
    flex: none;
    border-radius: ${({ theme }) => theme.borderRadius.md}
      ${({ theme }) => theme.borderRadius.md} 0 0;
  }
`;

const Info = styled(Typography)`
  margin-top: 12px;
  margin-bottom: 32px;
`;

const MobileWarningModal = () => {
  const { t } = useTranslation();
  const [isAccepted, setIsAccepted] = useState<boolean>(true);
  const { isTablet } = useMobileResponsive();

  useEffect(() => {
    setIsAccepted(
      !!localStorageHelper.get(LocalStorageKeys.modalWarningAccepted)
    );
  }, []);

  const onConfirm = () => {
    setIsAccepted(true);
    localStorageHelper.set(LocalStorageKeys.modalWarningAccepted, true);
  };

  return (
    <StyledModal isOpen={isTablet && !isAccepted} hideTopBar>
      <Info id="mobile-warning-desc" size="md" align="center" secondary />
      <Button text={t("confirm")} onClick={onConfirm} fullWidth height="34px" />
    </StyledModal>
  );
};

export default MobileWarningModal;
