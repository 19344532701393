import styled, { useTheme } from "styled-components";
import { Heading6Bold } from "../../../Typography/Heading6";
import { Paragraph14RegularSecondary } from "../../../Typography/Paragraph14";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";
import Icon from "../../Icon/Icon";
import { Dot } from "../../styled";
import { useWeb3 } from "@chainsafe/web3-context";
import { Paragraph16Bold } from "../../../Typography/Paragraph16";
import React from "react";
import { useTranslation } from "react-i18next";
import WalletIcon from "./WalletIcon";
import { useTokenQuery } from "../../../../queries/token.query";
import useChain from "../../../hooks/useChain";

const Wrapper = styled.div`
  background: ${(props) => props.theme.lightBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: ${(props) => props.theme.borderRadius.sm};
  cursor: pointer;
`;

const Left = styled.div`
  display: flex;
`;

const Details = styled.div`
  display: flex;
  align-items: center;

  h6 {
    margin-right: 8px;
  }
`;

const ConnectWalletWrapper = styled.div`
  div {
    display: flex;

    svg {
      margin-right: 19px;
    }
  }
`;

export const ConnectWallet = (props: {
  onClick: React.MouseEventHandler<HTMLElement>;
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper onClick={props.onClick}>
      <ConnectWalletWrapper>
        <div>
          <Icon name="walletLine" />
          <Paragraph16Bold>{t("connect-your-wallet")}</Paragraph16Bold>
        </div>
      </ConnectWalletWrapper>
      <Icon name="chevronRight" />
    </Wrapper>
  );
};

const Wallet = () => {
  const theme = useTheme();
  const { openWalletMenu } = useAccountDropdown();
  const { ethBalance, address, wallet } = useWeb3();
  const { t } = useTranslation();
  const { chain } = useChain();
  const { data: tokenData } = useTokenQuery(chain);

  const handleWalletClicked = () => {
    openWalletMenu();
  };

  if (!address || !wallet) {
    return <ConnectWallet onClick={handleWalletClicked} />;
  }

  return (
    <Wrapper onClick={handleWalletClicked}>
      <Left>
        <WalletIcon name={wallet.name} />
        <div>
          <>
            <Paragraph14RegularSecondary>
              {t("available-in-wallet")}
            </Paragraph14RegularSecondary>
            <Details>
              <Heading6Bold>
                {/* TODO: change */}
                {Number(ethBalance?.toFixed(2)).toLocaleString()}{" "}
                {tokenData?.nativeToken?.symbol}
              </Heading6Bold>
              <Dot color={theme.greenColor} />
            </Details>
          </>
        </div>
      </Left>
      <Icon name="chevronRight" />
    </Wrapper>
  );
};

export default Wallet;
