import { DefaultTheme } from "styled-components";
import chroma from "chroma-js";
import { ICustomTheme } from "../common/commonTypes";
import { CustomThemeColor } from "../common/commonEnums";

// it brightens very dark colors and darkens very bright to make their brighter/darker versions visible
const excludeEdgeColor = (color: string) => {
  if (chroma.distance("#fff", color, "rgb") < 40) {
    return chroma(color).darken(0.15).hex();
  }
  if (chroma.distance("#000", color, "rgb") < 40) {
    return chroma(color).brighten(0.15).hex();
  }

  return color;
};

export const generateTheme = ({
  font,
  background,
  action,
}: ICustomTheme): Partial<DefaultTheme> => {
  background = excludeEdgeColor(background);
  action = excludeEdgeColor(action);

  return {
    primaryColor: font,
    secondaryColor: chroma(background).darken(0.5).hex(),
    accent: {
      dark: chroma(action).darken(0.5).hex(),
      normal: action,
      light: chroma(action).brighten(0.5).hex(),
    },
    backgroundColor: background,
    lightBackgroundColor: chroma(background).brighten(0.5).hex(),
    lightBackgroundColorHover: chroma(background).brighten(2).hex(),
    darkBackgroundColor: chroma(background).darken(1.5).hex(),
    navbarBackgroundColor: chroma(background).alpha(0.6).hex(),
    timerBackgroundColor: chroma(background).alpha(0.4).hex(),
    cardBackgroundTransparent: chroma(background).alpha(0.6).hex(),
    semiTransparent: chroma(background).alpha(0.1).hex(),
    borderColor: chroma(font).alpha(0.1).hex(),
    lightBorderColor: chroma(font).alpha(0.5).hex(),
    darkBorderColor: chroma(font).alpha(0.1).hex(),
    inputBackgroundColor: chroma(font).alpha(0.05).hex(),
    inputBorderColor: chroma(font).alpha(0.3).hex(),
  };
};

export const areThemesEqual = (theme1: ICustomTheme, theme2: ICustomTheme) =>
  theme1[CustomThemeColor.background] === theme2[CustomThemeColor.background] &&
  theme1[CustomThemeColor.font] === theme2[CustomThemeColor.font] &&
  theme1[CustomThemeColor.action] === theme2[CustomThemeColor.action];

export const mergeThemes = (
  baseTheme: DefaultTheme,
  customTheme?: ICustomTheme
) => {
  if (!customTheme) {
    return baseTheme;
  }

  return {
    ...baseTheme,
    ...generateTheme(customTheme),
  };
};
