import styled, { css } from "styled-components";
import {
  getBasePoppinsTypography,
  getBaseTypography,
  getBoldTypography,
  getRegularTypography,
} from "./helpers";

export const getHeading6BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.xs};
  line-height: 22px;
`;

export const getHeading6PoppinsBoldTypography = css`
  ${getBasePoppinsTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.xs};
  line-height: 22px;
`;

export const getHeading6RegularTypography = css`
  ${getBaseTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.heading.xs};
  line-height: 26px;
`;

export const Heading6Bold = styled.h6`
  ${getHeading6BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Heading6PoppinsBold = styled.h6`
  ${getHeading6PoppinsBoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Heading6BoldSecondary = styled.h6`
  ${getHeading6BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.7;
`;
