import { useQuery } from "react-query";
import { IAsset, IDeployment, IListing } from "../common/commonTypes";
import { ethers } from "ethers";
import { assetService } from "../api/assetService";
import { listingService } from "../api/listingService";
import useChain from "../common/hooks/useChain";
import { StakingClient } from "../helpers/stakingClient";
import { useWeb3 } from "@chainsafe/web3-context";

const fetchLocked = async (
  deployment: IDeployment,
  user: string,
  asset: IAsset
): Promise<IListing[]> => {
  const res = await listingService.fetchLockedListingsForOwner(
    user,
    asset,
    deployment
  );
  return res.data.items;
};

interface IStakedInfo {
  // amount of this asset locked in the staking contract
  stake: ethers.BigNumber;
  // current spend allowance for the contract
  allowance: ethers.BigNumber;
  // current token balance in user wallet
  balance: ethers.BigNumber;
  // list of listings locking the stake
  locked: IListing[];
}

export const useStakedQuery = (
  contract?: ethers.Contract,
  user?: string,
  asset?: IAsset,
  deployment?: IDeployment
) => {
  const { provider } = useWeb3();
  const signer = provider?.getSigner();

  const enabled = Boolean(
    user &&
      user?.length > 0 &&
      contract &&
      contract.address.length > 0 &&
      deployment &&
      deployment?.name?.length > 0 &&
      signer?._isSigner &&
      asset &&
      asset.address.length > 0
  );

  return useQuery<IStakedInfo>(
    [contract?.address, user, asset?.id, deployment?.name],
    async () => {
      const client = new StakingClient(deployment!, signer!);

      const [stake, allowance, balance, locked] = await Promise.all([
        client.getStake(user!, asset!),
        client.getTokenAllowance(user!, asset!),
        client.getBalance(user!, asset!),
        fetchLocked(deployment!, user!, asset!),
      ]);

      return {
        stake: stake,
        allowance: allowance,
        balance: balance,
        locked,
      };
    },
    {
      enabled,
      staleTime: 5000,
      refetchInterval: 5000,
    }
  );
};

export interface ICurrentStake {
  asset: IAsset;
  balance: ethers.BigNumber;
  staked: ethers.BigNumber;
  lockedItems: IListing[];
  available: ethers.BigNumber;
  locked: ethers.BigNumber;
}

interface IChainStakedInfo {
  [tokenAddress: string]: ICurrentStake;
}

export const useStakedQueryForChain = (chainId?: number, user?: string) => {
  // TODO: this is probably buggy when there are multiple deployments per chain.
  // require deployment as a prop?
  const { deployment, stakingContract } = useChain();
  const { provider } = useWeb3();
  const signer = provider?.getSigner();
  const enabled = Boolean(
    chainId &&
      chainId > 0 &&
      user &&
      user.length > 0 &&
      stakingContract &&
      stakingContract.address.length > 0 &&
      deployment &&
      deployment.name.length > 0 &&
      signer &&
      signer._isSigner
  );

  return useQuery<IChainStakedInfo>(
    [chainId, user, stakingContract?.address],
    async (): Promise<IChainStakedInfo> => {
      const client = new StakingClient(deployment!, signer!);

      const assets = (
        await assetService.fetchAssets({
          where: {
            chain_id: deployment?.chain.id,
          },
        })
      ).data.items;

      // query each asset
      const results = await Promise.all(
        assets.map(async (asset: IAsset) => {
          const [balance, staked, lockedItems] = await Promise.all([
            client.getBalance(user!, asset),
            client.getStake(user!, asset),
            fetchLocked(deployment!, user!, asset),
          ]);

          const totalLocked = lockedItems.reduce(
            (sum: ethers.BigNumber, item: IListing) => {
              return sum.add(item.amount);
            },
            ethers.BigNumber.from("0")
          );

          const available = staked.sub(totalLocked);

          return {
            asset,
            staked: staked,
            balance: balance,
            locked: totalLocked,
            lockedItems,
            available: available,
          };
        })
      );

      const result: IChainStakedInfo = {};

      results.forEach((res: any) => {
        result[res.asset.address] = res;
      });

      return result;
    },
    {
      enabled,
    }
  );
};
