import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getHorizontalGap } from "../../styles";
import { Paragraph12Regular } from "../../Typography/Paragraph12";
import Logo from "../Logo/Logo";
import FooterLinks from "./FooterLinks";

const Root = styled.div`
  display: flex;
  flex-direction: row;
  ${getHorizontalGap("57px")};
  padding-bottom: 44px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    ${getHorizontalGap("0px")};
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  row-gap: 20px;
  max-width: 400px;
  margin-right: 130px;
`;

const LogoWrapper = styled.div`
  display: flex;
`;

const NoizdAbout = styled(Paragraph12Regular)`
  line-height: 18px;
`;

type Props = {
  onAboutModalOpen: () => void;
};

const FooterTop = ({ onAboutModalOpen }: Props) => {
  const { t } = useTranslation();
  return (
    <Root>
      <Container>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>

        <NoizdAbout>{t("footer-noizd-about")}</NoizdAbout>
      </Container>
      <FooterLinks onAboutModalOpen={onAboutModalOpen} />
    </Root>
  );
};

export default FooterTop;
