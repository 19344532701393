import React from "react";
import styled from "styled-components";
import { isVideo } from "../../helpers/fileHelper";
import {
  getResizedVideoUrl,
  getSources,
  getPosterUrl,
} from "../../helpers/videoHelper";
import { MimeEnum } from "../commonEnums";
import { IStyleableProps } from "../commonTypes";
import RatioImage from "./RatioImage/RatioImage";
import { settings } from "../../settings";
import { getAbsoluteFill } from "../styles";
import { useIsClientRender } from "../hooks/useIsClientRender";

const CoverImage = styled(RatioImage)`
  z-index: 0;
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
  border-radius: inherit;
`;

const CoverVideo = styled.video`
  ${getAbsoluteFill};
  height: 100%;
  width: 100%;
  border-radius: inherit;
  object-fit: cover;
`;

interface ICoverProps {
  url?: string;
  size?: number;
  mime?: MimeEnum;
  autoplay?: boolean;
  forceImage?: boolean;
}

const Cover = ({
  url,
  size,
  mime,
  autoplay = true,
  forceImage,
  ...styleProps
}: ICoverProps & IStyleableProps) => {
  const isClientRender = useIsClientRender();
  const isMediaVideo = isVideo(mime);

  if (isMediaVideo) {
    const resizedVideoUrl = getResizedVideoUrl(url, size);
    const sources = getSources(
      resizedVideoUrl,
      settings.VIDEO_FORMAT_PREFERENCE
    );

    if (forceImage) {
      return (
        <CoverImage
          src={getPosterUrl(resizedVideoUrl)}
          mimeType={MimeEnum.jpeg}
          resizeWidth={size}
          {...styleProps}
        />
      );
    }

    return (
      <VideoWrapper {...styleProps} key={resizedVideoUrl}>
        {isClientRender && (
          <CoverVideo
            autoPlay={autoplay}
            playsInline
            muted
            controls={false}
            loop={autoplay}
            preload="metadata"
            poster={getPosterUrl(resizedVideoUrl)}
          >
            {sources.map((source) => {
              return (
                <source
                  key={source.source}
                  src={source.source}
                  type={source.type}
                />
              );
            })}
          </CoverVideo>
        )}
      </VideoWrapper>
    );
  }

  return (
    <CoverImage src={url!} mimeType={mime} resizeWidth={size} {...styleProps} />
  );
};

export default Cover;
