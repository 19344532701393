import { ListingStatusEnum } from "../common/commonEnums";
import { IAsset, IListing, IMusic, IUserProfile } from "../common/commonTypes";
import { BigNumber } from "@ethersproject/bignumber/lib/bignumber.js";
import { ethers } from "ethers";

// This returns the latest owner according to the DB,
// but doesn't take token transfers into account
export const getCurrentOwner = (
  music: IMusic,
  listing?: IListing
): IUserProfile | undefined => {
  let owner: IUserProfile | undefined = music.artist;

  if (listing) {
    owner = listing.seller;

    if (isListingSold(listing)) {
      owner = {
        ...listing.buyer,
        address: listing.buyer_address,
      } as IUserProfile;
    }
  }
  return owner;
};

export const isListingMinting = (listing: IListing) => {
  if (!listing) {
    return false;
  }

  return [ListingStatusEnum.minting, ListingStatusEnum.sold].includes(
    listing.status
  );
};

export const isListingSold = (listing: IListing) => {
  if (!listing) {
    return false;
  }

  return [
    ListingStatusEnum.sold,
    ListingStatusEnum.minting,
    ListingStatusEnum.minted,
  ].includes(listing.status);
};

export const shouldShowTimer = (endTime?: string): boolean => {
  if (!endTime) {
    return false;
  }

  const endTimeMs = new Date(endTime).getTime();
  const currentTimeMs = Date.now();
  const durationInDays = Math.round(
    (endTimeMs - currentTimeMs) / (1000 * 60 * 60 * 24)
  );

  return durationInDays <= 30;
};

export const getInitialBidAmount = (
  amount: string,
  minBidIncreaseWei: string,
  asset: IAsset
): string => {
  const minBidWei = BigNumber.from(amount.toString()).add(minBidIncreaseWei);

  return ethers.utils
    .formatUnits(minBidWei.toString(), asset.decimals)
    .toString();
};
