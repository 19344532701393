import { useWeb3 } from "@chainsafe/web3-context";
import { captureException } from "@sentry/nextjs";
import { useState, useEffect } from "react";
import { bidService } from "../../../../api/bidService";
import { useDeploymentsForChain } from "../../../../context/Contracts";
import { StakingClient } from "../../../../helpers/stakingClient";
import { getTransactions } from "../../../../helpers/transactions";
import {
  ITransactionEvent,
  TransactionStatusEnum,
  TransactionActionEnum,
  IBid,
} from "../../../commonTypes";
import useChain from "../../../hooks/useChain";

const useTransactionList = (isTransactionListMenuOpen: boolean) => {
  const { address, provider } = useWeb3();
  const { chain } = useChain();
  const { deployments } = useDeploymentsForChain(chain?.id);

  const [txEvents, setTxEvents] = useState<ITransactionEvent[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchStakeEvents(): Promise<ITransactionEvent[]> {
    try {
      if (!deployments) {
        return [];
      }

      const events = await Promise.all(
        deployments.map(async (deployment) => {
          const client = new StakingClient(deployment, provider!.getSigner());
          const stakeEvents = await client.getStakeEvents(address!);
          return stakeEvents;
        })
      );
      const result: ITransactionEvent[] = [];
      return result.concat(...events);
    } catch (e) {
      return [];
    }
  }

  async function fetchWithdrawEvents(): Promise<ITransactionEvent[]> {
    try {
      if (!deployments) {
        return [];
      }

      const events = await Promise.all(
        deployments.map(async (deployment) => {
          const client = new StakingClient(deployment, provider!.getSigner());
          const stakeEvents = await client.getWithdrawEvents(address!);
          return stakeEvents;
        })
      );
      const result: ITransactionEvent[] = [];
      return result.concat(...events);
    } catch (e) {
      return [];
    }
  }

  // fetch all the NFT transfer events from null to the user address
  // to get a list of purchases performed by the currently connected wallet
  async function fetchPurchaseEvents(): Promise<ITransactionEvent[]> {
    try {
      if (!deployments) {
        return [];
      }

      const events = await Promise.all(
        deployments.map(async (deployment) => {
          const client = new StakingClient(deployment, provider!.getSigner());
          const stakeEvents = await client.getPurchaseEvents(address!);
          return stakeEvents;
        })
      );
      const result: ITransactionEvent[] = [];
      return result.concat(...events);
    } catch (e) {
      return [];
    }
  }

  // fetch all the staking transfer events from null to the user address
  // to get a list of purchases
  async function fetchSaleEvents(): Promise<ITransactionEvent[]> {
    try {
      if (!deployments) {
        return [];
      }

      const events = await Promise.all(
        deployments.map(async (deployment) => {
          const client = new StakingClient(deployment, provider!.getSigner());
          const stakeEvents = await client.getSaleEvents(address!);
          return stakeEvents;
        })
      );
      const result: ITransactionEvent[] = [];
      return result.concat(...events);
    } catch (e) {
      return [];
    }
  }

  async function fetchBids(): Promise<ITransactionEvent[]> {
    try {
      const res = await bidService.fetchBids(address!, chain?.id);
      const bids = res.data.items;

      return bids
        .filter((bid: IBid) => bid.listing.deployment.chain.id == chain?.id)
        .map((bid: IBid): ITransactionEvent => {
          return {
            timestamp: new Date(bid.created),
            amount: bid.amount,
            status: TransactionStatusEnum.pending,
            action: TransactionActionEnum.bid,
            hash: "",
            listingId: bid.listing_id,
            contractAddress: bid.listing.deployment.staking_address,
            senderAddress: address!,
            tokenAddress: bid.listing.asset.address,
            chain: bid.listing.asset.chain,
          };
        });
    } catch (error) {
      captureException(error);
      return [];
    }
  }

  function fetchPendingTransactions(): ITransactionEvent[] {
    return getTransactions().filter(
      (transaction) => transaction.chain?.id == chain?.id
    );
  }

  useEffect(() => {
    async function fetchEvents() {
      try {
        if (!deployments) {
          return;
        }

        setIsLoading(true);

        const [
          stakeEvents,
          purchaseEvents,
          saleEvents,
          bidEvents,
          withdrawEvents,
        ] = await Promise.all([
          fetchStakeEvents(),
          fetchPurchaseEvents(),
          fetchSaleEvents(),
          fetchBids(),
          fetchWithdrawEvents(),
        ]);

        let pendingTransactions = fetchPendingTransactions();

        let events = [
          ...stakeEvents,
          ...purchaseEvents,
          ...saleEvents,
          ...bidEvents,
          ...withdrawEvents,
        ];

        // pending txs are txs that are stored in local storage
        // but not yet finalised on chain
        pendingTransactions = pendingTransactions
          .filter((pendingTx) => pendingTx.senderAddress === address)
          .filter((pendingTx) => {
            return !events.find((tx) => tx.hash === pendingTx.hash);
          });

        events = events.concat(pendingTransactions);

        // active contracts are any staking / lazyAuctionCompleter contract
        // from the deployments response
        const activeContractAddresses = deployments
          ?.map((deployment) => deployment.staking_address)
          .concat(
            deployments?.map(
              (deployment) => deployment.instant_withdraw_address
            )
          )
          .concat(
            deployments?.map(
              (deployment) => deployment.lazy_auction_completer_address
            )
          );

        // sort the events by timestamp desc
        const sortedEvents = await Promise.all(
          events
            // only include events from our current active contracts
            // this excludes txs from an old contract that have been stored in local storage
            // always include bids as they don't have an address
            .filter((event) => {
              return (
                event.action === TransactionActionEnum.bid ||
                activeContractAddresses.includes(event.contractAddress)
              );
            })
            .sort(
              (eventA, eventB) =>
                new Date(eventB.timestamp).getTime() -
                new Date(eventA.timestamp).getTime()
            )
            .map(async (event) => {
              // skip off-chain events (bids), they don't have a chain / hash
              if (!event.hash || !event.chain) {
                return event;
              }

              // events are only loaded for the currently selected chain
              // so we can just use the provider from the connected wallet
              const result = await provider?.getTransactionReceipt(event.hash);

              if (!result) {
                // transaction still pending
                return event;
              }

              const status =
                result?.status === 1
                  ? TransactionStatusEnum.complete
                  : TransactionStatusEnum.error;

              return {
                ...event,
                status,
              };
            })
        );

        // limit to 5 transactions for now
        setTxEvents(sortedEvents.splice(0, 5));
        setIsLoading(false);
      } catch (error) {
        captureException(error);
      } finally {
        setIsLoading(false);
      }
    }

    if (address && isTransactionListMenuOpen) {
      fetchEvents();
    }
  }, [address, isTransactionListMenuOpen, chain?.id]);

  // clear events on chain change
  useEffect(() => {
    setTxEvents([]);
  }, [chain?.id]);

  return {
    txEvents,
    isLoading,
  };
};

export default useTransactionList;
