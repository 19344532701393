import { useTranslation } from "react-i18next";
import NextImage from "next/image";
import styled from "styled-components";
import { Heading4Bold } from "../../Typography/Heading4";
import { Paragraph14Regular } from "../../Typography/Paragraph14";
import noResultsImg from "../../../../public/images/marketplace-no-result.png";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 108px;
  padding-bottom: 64px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    padding-top: 0;
  }
`;

const NoResultsTitle = styled.div`
  margin-top: 36px;
  ${Heading4Bold};
  line-height: 28px;
  padding-bottom: 3px;
  color: ${({ theme }) => theme.primaryColor};
`;

const NoResultsSubTitle = styled.div`
  text-align: center;
  ${Paragraph14Regular};
  color: ${({ theme }) => theme.primaryColor};
  opacity: 0.7;
`;

interface Props {
  title?: string;
  subtitle?: string;
  className?: string;
}

const NoResults = ({ title, subtitle, className }: Props) => {
  const { t } = useTranslation();

  return (
    <Root className={className}>
      <NextImage src={noResultsImg} height="200px" width="200px" />
      <NoResultsTitle>{title || t("no-results-title")}</NoResultsTitle>
      <NoResultsSubTitle>
        {subtitle || t("no-results-subtitle")}
      </NoResultsSubTitle>
    </Root>
  );
};

export default NoResults;
