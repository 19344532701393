import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import Icon from "../../Icon/Icon";
import Typography from "../../Typography/Typography";
import { Wrapper, Content, ActionButton } from "./InProgress";
import FadeImage from "../../FadeImage/FadeImage";
import anguishEmoji from "../../../../../public/images/anguish.png";
import satisfactionEmoji from "../../../../../public/images/satisfaction.png";

const EmojiWrapper = styled.div`
  position: relative;
`;

const Message = styled.div<{ color: string }>`
  background: ${(props) => props.color}1a;
  border: 1px solid ${(props) => props.color}80;
  border-radius: ${(props) => props.theme.borderRadius.xs};

  color: ${(props) => props.color};
  padding: 10px 16px;
  display: flex;
  align-items: center;
  max-width: 356px;
  margin-top: 24px;
  margin-bottom: 12px;

  svg {
    color: ${(props) => props.color};
    margin-right: 25px;
  }
`;

interface Props {
  message: string;
  success: boolean;
  onCloseClick: () => void;
  redirectUrl?: string;
  hideButtons?: boolean;
}

const TransactionResult = ({
  message,
  success,
  onCloseClick,
  redirectUrl,
  hideButtons,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const emoji = success ? satisfactionEmoji : anguishEmoji;
  const icon = success ? "check" : "cancel";
  const color = success ? theme.greenColor : theme.red;

  return (
    <Wrapper>
      <Content>
        <EmojiWrapper>
          <FadeImage src={emoji} width={50} height={50} layout="intrinsic" />
        </EmojiWrapper>

        <Message color={color}>
          <Icon name={icon} />
          {message}
        </Message>

        {redirectUrl && (
          <a href={redirectUrl} target="_blank" rel="noreferrer">
            <Typography align="center" size="lg" weight="bold">
              {t("view-transaction")}
            </Typography>
          </a>
        )}
      </Content>

      <ActionButton
        text={hideButtons ? t("go-back") : t("back-to-transactions")}
        fullWidth
        onClick={onCloseClick}
      />
    </Wrapper>
  );
};

export default TransactionResult;
