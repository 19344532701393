import { countries, ICountry } from "../common/countries";

export const getCountryNameByCode = (code: string = ""): string | undefined => {
  const country = getCountryByCode(code);
  return country ? country.name : undefined;
};

export const getCountryByCode = (code: string = ""): ICountry | undefined => {
  const result = countries.find(
    (country) => country.code.toLocaleLowerCase() === code.toLocaleLowerCase()
  );
  return result;
};
