import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useWeb3 } from "@chainsafe/web3-context";
import { useAccountDropdown } from "../../../../context/AccountDropdownContext";

import Button from "../../Buttons/Button";
import Expand from "../../Expand/Expand";
import { useStakedQueryForChain } from "../../../../queries/staking.query";
import {
  Paragraph14Bold,
  Paragraph14RegularSecondary,
} from "../../../Typography/Paragraph14";

import StakedAsset from "./StakedAsset";
import LoadingSpinner from "../../LoadingSpinner";

const ClosedStakeWrapper = styled.div``;

const CloseStakeContent = styled.div`
  display: flex;
  align-items: center;

  h6 {
    margin-left: 8px;
  }
`;

const ClosedStake = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ClosedStakeWrapper>
      <Paragraph14RegularSecondary>
        {t("stake-staked-available")}
      </Paragraph14RegularSecondary>
      <CloseStakeContent></CloseStakeContent>
    </ClosedStakeWrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 16px;
`;

export const Buttons = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid ${(props) => props.theme.darkBorderColor};
  display: flex;
  justify-content: space-between;

  button:first-of-type {
    margin-right: 16px;
  }
`;

const Staked = ({ showControls }: { showControls?: boolean }) => {
  const theme = useTheme();
  const { openDepositStakeMenu, openWithdrawStakeMenu, isStakingOpen } =
    useAccountDropdown();
  const { t } = useTranslation();
  const { network, address } = useWeb3();
  const { data: stakedForChain, isLoading } = useStakedQueryForChain(
    network!,
    address!
  );
  const controlsDisabled = !address;

  const onDepositClick = () => {
    if (!controlsDisabled) {
      openDepositStakeMenu();
    }
  };

  const onWithdrawClick = () => {
    if (!controlsDisabled) {
      openWithdrawStakeMenu();
    }
  };

  return (
    <Wrapper>
      <Expand closedContent={<ClosedStake />} isOpen={isStakingOpen}>
        {isLoading && <LoadingSpinner />}
        {stakedForChain &&
          Object.values(stakedForChain).map((value, i) => {
            return (
              <StakedAsset
                key={i}
                asset={value?.asset}
                available={value?.available?.toString()}
                locked={value?.locked?.toString()}
                total={value?.staked?.toString()}
              />
            );
          })}
        {showControls && (
          <Buttons>
            <Button
              background={theme.darkBorderColor}
              label={<Paragraph14Bold>{t("deposit")}</Paragraph14Bold>}
              fullWidth
              height="34px"
              onClick={onDepositClick}
              disabled={controlsDisabled}
            />
            <Button
              background={theme.darkBorderColor}
              label={<Paragraph14Bold>{t("withdraw")}</Paragraph14Bold>}
              fullWidth
              height="34px"
              onClick={onWithdrawClick}
              disabled={controlsDisabled}
            />
          </Buttons>
        )}
      </Expand>
    </Wrapper>
  );
};

export default Staked;
