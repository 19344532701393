import React from "react";
import styled from "styled-components";
import { getHeading6BoldTypography } from "../Typography";
import { getParagraph16RegularTypography } from "../Typography/Paragraph16";

const Container = styled.div`
  border: 2px solid ${(props) => props.theme.darkBorderColor};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  color: ${(props) => props.theme.primaryColor};
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
`;

const Input = styled.input`
  background: ${(props) => props.theme.lightBackgroundColor};
  border: none;
  color: ${(props) => props.theme.primaryColor};
  ${getHeading6BoldTypography}
  appearance: textfield;
  outline: none;
  max-width: 80%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 70%;
  }
`;

const Label = styled.p`
  ${getParagraph16RegularTypography};
  white-space: nowrap;
`;

type Props = {
  amount?: string;
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  min?: number;
  max?: number;
  step?: number;
};

const AmountInput = ({
  amount,
  label,
  onChange,
  min,
  max,
  step = 1,
}: Props) => {
  return (
    <Container>
      <Input
        type="number"
        value={amount ?? min}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
      />
      <Label>{label}</Label>
    </Container>
  );
};

export default AmountInput;
