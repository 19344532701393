import { ethers } from "ethers";
import { IChain, IDeployment } from "../common/commonTypes";

export const switchToNetwork = async (
  chain: IChain,
  provider: ethers.providers.Web3Provider
) => {
  // try switch
  try {
    if (provider.provider.request) {
      await provider!.provider!.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: `0x${parseInt(chain.id.toString(), 10).toString(16)}`,
          },
        ],
      });
    }

    // switching failed, add the chain, then try again
  } catch (e) {
    if (e.code === 4902) {
      try {
        await addNetwork(chain, provider);
        await switchToNetwork(chain, provider);
      } catch (err) {
        throw "Failed switching network";
      }
    }
  }
};

const addNetwork = (chain: IChain, provider: ethers.providers.Web3Provider) => {
  if (!provider.provider) {
    return;
  }

  try {
    if (!provider.provider.request) {
      return;
    }

    return provider!.provider!.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: `0x${parseInt(chain.id.toString(), 10).toString(16)}`,
          chainName: chain.name,
          rpcUrls: [chain.public_rpc_url],
        },
      ],
    });
  } catch (e) {}
};

export const getContractFromDeployment = (
  contracts: { [deploymentId: string]: ethers.Contract },
  deployment?: IDeployment
): ethers.Contract | undefined => {
  if (Object.keys(contracts).length <= 0 || !deployment) {
    return;
  }

  return contracts[deployment.id];
};

export const getChainNames = (deployments?: IDeployment[]): string[] => {
  return (
    deployments?.map((deployment) => {
      return deployment.chain.name;
    }) ?? []
  );
};
