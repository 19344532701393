export const routes = {
  user: {
    url: (url: string) => `/u/${url}`,
    pathname: "/u/[userUrl]",
  },
  address: (address: string) => `/address/${address}`,
  auth: {
    root: "/auth",
    login: "/auth/login",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    join: {
      root: "/auth/join",
      signupConfirmation: "/auth/join/signup-confirmation",
      signupUser: "/auth/join/signup-user",
      signupArtist: "/auth/join/signup-artist",
    },
  },
  verify: "/verify",
  editProfile: "/edit-profile",
  faq: "/faq",
  home: "/",
  marketplace: {
    root: "/assets",
    id: (music_id: string, listing_id?: string) => {
      let params = new URLSearchParams();
      if (listing_id) {
        params.append("listing_id", listing_id);
      }

      return `/assets/${music_id}?${params.toString()}`;
    },
    pathname: "/assets/[musicId]",
  },
  // remix: "/remix",
  uploadMusic: "/upload-music",
  artists: "/artists",
  page404: "/404",
};
