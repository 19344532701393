import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../common/queryKeys";
import { accountService } from "../api/accountService";
import {
  ICustomTheme,
  ISession,
  IUserProfile,
  IUserProfilePartial,
} from "../common/commonTypes";
import { ILoginRequestDto } from "../common/dtos";
import { sessionService } from "../services/sessionService";
import { useWalletHelpers } from "../common/hooks/useWalletHelpers";

export const useSession = () => {
  const queryResponse = useQuery<ISession | undefined>(
    QueryKeys.session,
    async () => {
      const sessionId = sessionService.getSessionId();

      if (sessionId) {
        try {
          const session = await accountService.validateSession();
          sessionService.saveSession(session);
          return session;
        } catch (error) {
          // session is properly cleared in fetchWrapperProtected interceptor
        }
      }
    },
    {
      staleTime: Infinity,
      retry: 0,
      refetchOnWindowFocus: "always",
    }
  );

  return {
    isLoggedIn: !!queryResponse.data,
    user: queryResponse.data?.user,
    ...queryResponse,
  };
};

export const useSiweMutation = () => {
  const queryClient = useQueryClient();

  const { connectWallet, getSiweMessageSignature, getSiweMessageParams } =
    useWalletHelpers();

  return useMutation(
    async () => {
      await connectWallet();

      const params = await getSiweMessageParams();

      const signature = await getSiweMessageSignature(params);

      const session = await accountService.createSiweSession({
        ...params,
        signature,
      });

      return session.data;
    },
    {
      onSuccess: (session) => {
        sessionService.saveSession(session);
        queryClient.setQueryData<ISession | undefined>(
          QueryKeys.session,
          () => session
        );
      },
    }
  );
};

export const useLoginMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((dto: ILoginRequestDto) => accountService.login(dto), {
    onSuccess: (session) => {
      sessionService.saveSession(session);
      queryClient.setQueryData<ISession | undefined>(
        QueryKeys.session,
        () => session
      );
    },
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      if (sessionService.getSessionId()) {
        await accountService.deleteSession();
      }
    },
    {
      onSettled: () => {
        sessionService.removeSession();
        queryClient.setQueryData<ISession | undefined>(
          QueryKeys.session,
          () => undefined
        );
      },
    }
  );
};

export const useUpdateProfileMutation = () => {
  const { refetch } = useSession();
  return useMutation(
    ({
      userId,
      profileId,
      payload,
    }: {
      userId: string;
      profileId: string;
      payload: IUserProfilePartial;
    }) => accountService.updateUserProfileBulk(userId, profileId, payload),
    {
      onSuccess: () => refetch({ throwOnError: true }),
    }
  );
};

export const useUpdateThemeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ user, theme }: { user: IUserProfile; theme: ICustomTheme | null }) =>
      accountService.updateUserTheme(user.profile_id, theme),
    {
      onSuccess: (profile, { user }) => {
        queryClient.setQueryData<ISession | undefined>(
          QueryKeys.session,
          (session) => {
            if (session) {
              return {
                ...session,
                user: {
                  ...session?.user,
                  profile,
                },
              };
            }
          }
        );
        queryClient.setQueryData<IUserProfile | undefined>(
          [QueryKeys.userProfile, user.uri],
          (updatedUser) => {
            if (updatedUser) {
              return {
                ...user,
                profile,
              };
            }
          }
        );
      },
    }
  );
};

export const useConvertProfileMutation = () => {
  const { user, refetch } = useSession();

  return useMutation(() => accountService.signArtistTermsConditions(user!.id), {
    onSuccess: () => refetch({ throwOnError: true }),
  });
};

export const useResendEmailMutation = () => {
  const { user } = useSession();
  return useMutation(() => accountService.resendVerificationEmail(user!.id));
};
