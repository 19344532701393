import React from "react";
import { IMusic } from "../../commonTypes";
import NftIncludedItems from "../NftIncludedItems/NftIncludedItems";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../Card/Card";
import Typography from "../Typography/Typography";
import InfoIcon from "../InfoIcon/InfoIcon";

interface IProps {
  music: IMusic;
  withRights?: boolean;
}

const NftItemsCard = styled(Card)`
  width: auto;
  max-width: 270px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    max-width: 170px;
  }
`;

const NftItemsTitle = styled(Typography)`
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
`;

const NftItemsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -8px;
`;

const Item = styled.div`
  padding: 8px;
  flex-grow: 1;
  flex-basis: 50%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileL}) {
    flex-basis: 100%;
  }
`;

const NftItemsPopup = ({ music, withRights }: IProps) => {
  const { t } = useTranslation();

  return (
    <NftItemsCard>
      <NftItemsTitle size="xs" secondary weight="400">
        {t("nft-content")}
      </NftItemsTitle>
      <NftItemsGrid>
        <NftIncludedItems
          music={music}
          withRights={withRights}
          renderItem={(item) => (
            <Item key={item.id}>
              <InfoIcon
                icon={item.icon}
                text={item.text}
                iconSize="12px"
                iconWrapperSize="22px"
                textSize="xs"
                spacing="8px"
                secondary
              />
            </Item>
          )}
        />
      </NftItemsGrid>
    </NftItemsCard>
  );
};

export default NftItemsPopup;
