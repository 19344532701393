import { ReactNode } from "react";
import useMobileResponsive from "../../hooks/useMobileResponsive";
import Dropdown from "../Dropdown";
import Modal from "../Modal/Modal";

export interface IMenuProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
}

// The intended use of this component is to wrap menu content.
// Menu will wrap the children in a Modal on Tablets and smaller and
// a Dropdown on any device bigger than a tablet
const Menu = (props: IMenuProps) => {
  const { isTablet } = useMobileResponsive();

  if (isTablet) {
    return <Modal {...props} />;
  }

  return <Dropdown {...props} />;
};

export default Menu;
