export const isEventOutside = (
  event: MouseEvent | TouchEvent,
  element?: HTMLElement | null
) => {
  return (
    element &&
    event.target instanceof Element &&
    !element.contains(event.target)
  );
};
