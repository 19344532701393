import styled, { css } from "styled-components";
import {
  getBaseTypography,
  getBoldTypography,
  getRegularTypography,
} from "./helpers";

export const getHeading5BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.heading.sm};
  line-height: 28px;
`;

export const getHeadingRegularTypography = css`
  ${getBaseTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.heading.sm};
  line-height: 28px;
`;

export const Heading5Bold = styled.h5`
  ${getHeading5BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Heading5Regular = styled.h5`
  ${getHeadingRegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;
