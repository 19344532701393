import { css } from "styled-components";

export const getBaseTypography = css`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  letter-spacing: 0;
`;

export const getBasePoppinsTypography = css`
  font-family: "Poppins", sans-serif;
  font-style: normal;
  letter-spacing: 0;
`;

/* because Figma doesn't display correct fonts, 700 is now 500 */
export const getBoldTypography = css`
  font-weight: 500;
`;

/* because Figma doesn't display correct fonts, 400 is now 300 */
export const getRegularTypography = css`
  font-weight: 300;
`;

/* because Figma doesn't display correct fonts, 500 is now 400 */
export const getMediumTypography = css`
  font-weight: 400;
`;
