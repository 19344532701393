import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDebounce } from "use-debounce";
import { IListing, IUserProfile } from "../common/commonTypes";
import { useRouter } from "next/router";
import {
  useSearchArtistsQuery,
  useSearchListingsQuery,
} from "../queries/search.query";
import { getPagesItems } from "../helpers/paginationHelper";
import { useQueryClient } from "react-query";
import { QueryKeys } from "../common/queryKeys";

export interface SearchValues {
  searchPhrase: string;
  searchValue: string;
  setSearchPhrase: (value: string) => void;
  submitSearchValue: () => void;
  closeSearch: () => void;
  isSearchFocused: boolean;
  setIsSearchFocused: (value: boolean) => void;
  isSearchExpanded: boolean;
  showSearchResults: boolean;
  listings: IListing[];
  haveListingsNextPage: boolean;
  areListingsLoading: boolean;
  fetchMoreListings: () => void;
  artists: IUserProfile[];
  haveArtistsNextPage: boolean;
  areArtistsLoading: boolean;
  fetchMoreArtists: () => void;
  isSearching: boolean;
}

export const SearchContext = createContext<SearchValues>({
  searchPhrase: "",
  searchValue: "",
  setSearchPhrase: () => {},
  submitSearchValue: () => {},
  closeSearch: () => {},
  isSearchFocused: false,
  setIsSearchFocused: () => {},
  isSearchExpanded: false,
  showSearchResults: false,
  listings: [],
  haveListingsNextPage: true,
  areListingsLoading: false,
  fetchMoreListings: () => {},
  artists: [],
  haveArtistsNextPage: true,
  areArtistsLoading: false,
  fetchMoreArtists: () => {},
  isSearching: false,
});

interface SearchProviderProps {
  children: ReactNode;
}

const SEARCH_DEBOUNCE_MS = 500;

export const SearchProvider = ({ children }: SearchProviderProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const [searchValue, { flush: submitSearchValue }] = useDebounce(
    searchPhrase,
    SEARCH_DEBOUNCE_MS
  );
  const [isSearchFocused, setIsSearchFocused] = useState<boolean>(false);
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const isSearchExpanded =
    isSearchFocused || !!searchPhrase || showSearchResults;

  const {
    data: listingPages,
    isLoading: areListingsSearching,
    isFetchingNextPage: areListingsLoading,
    hasNextPage: haveListingsNextPage = false,
    fetchNextPage: fetchMoreListings,
  } = useSearchListingsQuery(searchValue);
  const listings = getPagesItems(listingPages?.pages);

  const {
    data: artistsPages,
    isLoading: areArtistsSearching,
    isFetchingNextPage: areArtistsLoading,
    hasNextPage: haveArtistsNextPage = false,
    fetchNextPage: fetchMoreArtists,
  } = useSearchArtistsQuery(searchValue);
  const artists = getPagesItems(artistsPages?.pages);

  const isSearching = areListingsSearching || areArtistsSearching;

  const closeSearch = () => {
    setSearchPhrase("");
    setIsSearchFocused(false);
    setShowSearchResults(false);
  };

  useEffect(() => {
    if (!showSearchResults) {
      queryClient.removeQueries(QueryKeys.searchListings);
      queryClient.removeQueries(QueryKeys.searchArtists);
    }
  }, [showSearchResults]);

  useEffect(() => {
    if (!isSearchFocused && !searchPhrase) {
      submitSearchValue();
    }
  }, [searchPhrase, isSearchFocused]);

  useEffect(() => {
    if (searchValue) {
      setShowSearchResults(true);
    }
  }, [searchValue]);

  useEffect(() => {
    closeSearch();
  }, [router]);

  return (
    <SearchContext.Provider
      value={{
        searchPhrase,
        setSearchPhrase,
        searchValue,
        submitSearchValue,
        closeSearch,
        isSearchFocused,
        setIsSearchFocused,
        isSearchExpanded,
        showSearchResults,
        listings,
        haveListingsNextPage,
        areListingsLoading,
        fetchMoreListings,
        artists,
        haveArtistsNextPage,
        areArtistsLoading,
        fetchMoreArtists,
        isSearching,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => {
  return useContext(SearchContext);
};
