import { BigNumber, ethers } from "ethers";
import {
  IApiResponse,
  IAsset,
  IDeployment,
  IListing,
  ITransactionEvent,
  TransactionActionEnum,
  TransactionStatusEnum,
} from "../../common/commonTypes";
import {
  IBidMessage,
  IWithdrawal,
  TransactionEvent,
} from "../../common/hooks/useStaking";
import NOIZDBase from "./NOIZDBase";
import { TransactionResponse } from "@ethersproject/abstract-provider";
import { bidService } from "../../api/bidService";
import { TypedDataField } from "@ethersproject/abstract-signer";
import moment from "moment";
import { ZERO_ADDRESS } from "../../constants/assets";

export default class NOIZDv1 extends NOIZDBase {
  bidType: Array<TypedDataField>;

  constructor(deployment: IDeployment, signer: ethers.providers.JsonRpcSigner) {
    super(deployment, signer);

    this.stakingDomain = {
      name: "NOIZDStaking",
      version: "NDS",
      chainId: deployment.chain.id,
      verifyingContract: this.stakingContract.address,
    };

    this.bidType = [
      {
        name: "listing",
        type: "uint256",
      },
      {
        name: "staker",
        type: "address",
      },
      {
        name: "target",
        type: "address",
      },
      {
        name: "amount",
        type: "uint256",
      },
    ];
  }

  async getNonce(user: string): Promise<number> {
    const nonce = await this.stakingContract.nonces(user);

    return nonce.toNumber();
  }

  async getWithdrawalData(
    amount: string,
    token: IAsset,
    user: string
  ): Promise<IWithdrawal> {
    const deadline = moment().add(15, "minutes").unix();
    const nonce = await this.getNonce(user);

    return {
      amount,
      deadline: deadline.toString(),
      nonce,
      token,
      user,
    };
  }

  async signWithdrawal(
    withdrawal: IWithdrawal
  ): Promise<{ signature: string }> {
    // v1 just uses a simple EIP-191 signature for the withdrawal
    const signature = await this.signer.signMessage(
      `I own this address: ${withdrawal.user}`
    );
    return { signature };
  }

  stake(amount: string, asset: IAsset): Promise<TransactionResponse> {
    return this.stakingContract.connect(this.signer).stake({
      value: amount,
    });
  }

  async placeBid(
    listing: IListing,
    amount: string,
    address: string
  ): Promise<IApiResponse> {
    const bidSignature = await this.signBid({
      amount,
      listing,
      staker: address,
      target: listing.seller_address,
    });

    const data = {
      bid_signature: bidSignature,
    };

    return bidService.placeBid(listing, amount, address, data);
  }

  async signBid(bid: IBidMessage): Promise<string> {
    const message = {
      listing: bid.listing.id,
      staker: bid.staker,
      target: bid.target,
      amount: bid.amount,
    };

    const signature = await this.signer._signTypedData(
      this.stakingDomain,
      { Bid: this.bidType },
      message
    );

    return signature;
  }

  async getStake(user: string, asset: IAsset): Promise<BigNumber> {
    return this.stakingContract.stakes(user);
  }

  async getBalance(user: string, asset: IAsset): Promise<BigNumber> {
    // v1 only supports ether - ignore asset and return ether balance
    return this.provider.getBalance(user);
  }

  getTokenAllowance(user: string, asset: IAsset): Promise<BigNumber> {
    // v1 only supports ether - ignore asset and return ether balance
    return this.getBalance(user, asset);
  }

  withdrawInstant(
    withdrawal: IWithdrawal,
    userSignature: string,
    ownerSignature: string
  ): Promise<ethers.providers.TransactionResponse> {
    return this.stakingContract
      .connect(this.signer)
      .withdrawInstant(
        [
          withdrawal.amount,
          withdrawal.deadline,
          withdrawal.nonce,
          withdrawal.user,
        ],
        ownerSignature
      );
  }

  onStakeEvent(
    address: string,
    asset: string,
    callback: (event: TransactionEvent) => void
  ): void {
    const filter = this.stakingContract.filters.Stake(address);

    this.stakingContract.once(filter, (sender, amount, event) => {
      callback(event);
    });
  }

  onWithdrawEvent(
    address: string,
    asset: string,
    nonce: number,
    callback: (event: TransactionEvent) => void
  ): void {
    const filter = this.stakingContract.filters.Withdraw(address, nonce);

    this.stakingContract.once(filter, (staker, nonce, amount, event) => {
      callback(event);
    });
  }

  async getStakeEvents(address: string): Promise<ITransactionEvent[]> {
    const filter = this.stakingContract.filters.Stake(address);

    const events = await this.stakingContract.queryFilter(filter);

    const transactionEvents = await events.map(async (event) => {
      const decoded = (event as any).decode(event.data, event.topics);
      const block = await event.getBlock();

      return {
        action: TransactionActionEnum.deposit,
        amount: decoded.amount.toString(),
        contractAddress: this.stakingContract.address,
        hash: event.transactionHash,
        senderAddress: address,
        status: TransactionStatusEnum.complete,
        timestamp: new Date(block.timestamp * 1000),
        blockNumber: block.number,
        chain: this.deployment.chain,
        tokenAddress: ZERO_ADDRESS, // v1 only supports ether,
      };
    });

    const results = await Promise.all(transactionEvents);

    return ([] as ITransactionEvent[]).concat(...results);
  }

  async getWithdrawEvents(address: string): Promise<ITransactionEvent[]> {
    const filter = this.stakingContract.filters.Withdraw(address);

    const events = await this.stakingContract.queryFilter(filter);

    const transactionEvents = await events.map(async (event) => {
      const decoded = (event as any).decode(event.data, event.topics);
      const block = await event.getBlock();

      return {
        action: TransactionActionEnum.withdraw,
        amount: decoded.amount.toString(),
        contractAddress: this.stakingContract.address,
        hash: event.transactionHash,
        senderAddress: address,
        status: TransactionStatusEnum.complete,
        timestamp: new Date(block.timestamp * 1000),
        blockNumber: block.number,
        chain: this.deployment.chain,
        tokenAddress: ZERO_ADDRESS, // v1 only supports ether,
      };
    });

    const results = await Promise.all(transactionEvents);

    return ([] as ITransactionEvent[]).concat(...results);
  }

  async getPurchaseEvents(address: string): Promise<ITransactionEvent[]> {
    const filter = this.stakingContract.filters.Transfer(address, null);

    const events = await this.stakingContract.queryFilter(filter);

    const transactionEvents = await events.map(
      async (event: any): Promise<ITransactionEvent> => {
        const decoded = await event.decode(event.data, event.topics);
        const block = await event.getBlock();

        return {
          timestamp: new Date(block.timestamp * 1000),
          action: TransactionActionEnum.buy,
          status: TransactionStatusEnum.complete,
          amount: decoded.amount.toString(),
          hash: event.transactionHash,
          contractAddress: this.stakingContract.address,
          blockNumber: block.number,
          senderAddress: address,
          tokenAddress: ZERO_ADDRESS, //v1 only supports ether
          chain: this.deployment.chain,
        };
      }
    );

    const results = await Promise.all(transactionEvents);

    return ([] as ITransactionEvent[]).concat(...results);
  }

  async getSaleEvents(address: string): Promise<ITransactionEvent[]> {
    const incomingTransferFilter = this.stakingContract!.filters.Transfer(
      null,
      address
    );

    const incomingTransferEvents = await this.stakingContract!.queryFilter(
      incomingTransferFilter
    );

    const mappedIncomingTransferEvents = await Promise.all(
      incomingTransferEvents.map(
        async (event: any): Promise<ITransactionEvent> => {
          const status = TransactionStatusEnum.complete;
          const action = TransactionActionEnum.sell;

          const decoded = (event as any).decode(event.data, event.topics);
          const amount = decoded[3];

          const block = await event.getBlock();
          const timestamp = block.timestamp;

          return {
            timestamp: new Date(timestamp * 1000),
            amount: amount.toString(),
            status,
            action,
            hash: event.transactionHash,
            blockNumber: block.number,
            contractAddress: this.stakingContract.address,
            senderAddress: address!,
            chain: this.deployment.chain,
            tokenAddress: ZERO_ADDRESS, // v1 only supports ether
          };
        }
      )
    );
    const result: ITransactionEvent[] = [];
    const results = await Promise.all(mappedIncomingTransferEvents);
    return result.concat(...results);
  }
}
