import ReactModal from "react-modal";
import styled, { useTheme } from "styled-components";
import {
  getHeading4BoldTypography,
  getHeading6BoldTypography,
} from "../../Typography";
import { IStyleableProps } from "../../commonTypes";
import { useEffect } from "react";
import IconButton from "../Buttons/IconButton";
import Icon from "../Icon/Icon";
import { zIndex } from "../../../constants/zIndex";
import { lockScroll, unlockScroll } from "../../../helpers/scroll";

export interface IModalProps {
  children?: any;
  isOpen: boolean;
  title?: string;
  onClose?: () => void;
  maxWidth?: string;
  hideTopBar?: boolean;
  padding?: string;
  backgroundColor?: string;
  [prop: string]: any;
}

const StyledModal = styled(ReactModal)<{
  maxWidth?: string;
  padding?: string;
  backgroundColor?: string;
}>`
  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.backgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.lg};
  outline: none;
  padding: ${(props) => (props.padding ? props.padding : "32px")};
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "448px")};
  margin: 0 auto;
  max-height: 100vh;
  overflow-y: auto;
  position: relative;

  /* change layout for tablets and smaller */
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background: none;
    padding: 0;
    align-self: flex-start;
    max-width: 100%;
    min-height: 100%;
    max-height: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 0;
  }
`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;

  /* change layout for tablets and smaller */
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column-reverse;
    align-items: center;
    flex: none;
  }
`;

const Title = styled.h4<{ isCloseHidden?: boolean }>`
  ${getHeading4BoldTypography};
  color: ${(props) => props.theme.primaryColor};
  margin-bottom: ${(props) => (props.isCloseHidden ? "32px" : "0px")};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    ${getHeading6BoldTypography};
    padding-top: 40px;
    background: ${(props) => props.theme.backgroundColor};
    width: 100%;
    text-align: center;
    border-radius: ${(props) => props.theme.borderRadius.md}
      ${(props) => props.theme.borderRadius.md} 0 0;
  }
`;

const CloseButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  border: 2px solid ${(props) => props.theme.lightBackgroundColor};
  border-radius: ${(props) => props.theme.borderRadius.sm};
  margin-bottom: 32px;
  cursor: pointer;
  margin-left: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 20px;
    margin-right: auto;
  }
`;

export const ModalContent = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    background: ${(props) => props.theme.backgroundColor};
    width: 100%;
    text-align: center;
    border-radius: 0;
    padding: 20px;
    box-sizing: border-box;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
`;

const Modal = ({
  children,
  isOpen,
  title,
  onClose,
  maxWidth,
  hideTopBar = false,
  backgroundColor,
  padding,
  ...props
}: IModalProps & IStyleableProps) => {
  const theme = useTheme();

  useEffect(() => {
    ReactModal.setAppElement("body");
  }, []);

  useEffect(() => {
    if (isOpen) {
      lockScroll();

      return () => unlockScroll();
    }
  }, [isOpen]);

  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onClose}
      maxWidth={maxWidth}
      backgroundColor={backgroundColor}
      padding={padding}
      style={{
        overlay: {
          background: theme.modalOverlayColor,
          zIndex: zIndex.modal,
          backdropFilter: "blur(14px)",
          display: "flex",
          alignItems: "center",
          overflow: "auto",
        },
      }}
      {...props}
    >
      {!hideTopBar && (
        <TopBar>
          {title && <Title isCloseHidden={!onClose}>{title}</Title>}
          {onClose && (
            <CloseButton onClick={onClose}>
              <Icon name="close" size="16px" />
            </CloseButton>
          )}
        </TopBar>
      )}
      <ModalContent>{children}</ModalContent>
    </StyledModal>
  );
};

export default Modal;
