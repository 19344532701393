import { ReactNode } from "react";
import {
  BidTypeEnum,
  CustomThemeColor,
  ListingStatusEnum,
  MimeEnum,
  MusicEnum,
  TwitterCardType,
  UserTypeEnum,
  WebsocketMessageType,
} from "./commonEnums";
import icons from "./components/Icon";

export interface IListingIssueDetails {
  totalIssues: number;
  issueNumber: number;
}

export interface IAudioInfo {
  audio: any;
  cover?: any;
}

export interface ISignupArtist {
  email: string;
  username: string;
  password: string;
  confirm_password: string;
  confirm_no_copyright: boolean;
  confirm_ownership: boolean;
  confirm_listening_rights: boolean;
}

export interface ISignupUser {
  name_first: string;
  name_last: string;
  email: string;
  username: string;
  password: string;
  confirm_password: string;
  confirm_terms: boolean;
}

export interface INavLink {
  name: string;
  path: string;
}

export interface ICreateProfile {
  image: string;
  bio: string;
  country: string;
}

export interface IRouterStep {
  id: number;
  label: string;
  description?: string;
}

export interface IAccordionData {
  title: string;
  checkboxName?: string;
  paragraphs: any;
}

export interface IApiResponse {
  data?: any;
  status?: number;
  errorMsg?: any;
  message?: string;
}

export interface IApiListResponse<ListItem> {
  data: IApiPage<ListItem>;
  status?: number;
  errorMsg?: any;
  message?: string;
}

export interface IQueryParams {
  where?: any;
  attributes?: any;
  order?: any;
  limit?: number;
  offset?: number;
}

export interface ISocialInfo {
  url?: string;
}

export interface ISession {
  id: string;
  user_id: string;
  user: ILoggedUser;
}

export interface IUser {
  id: string;
  profile_id: string;
  username: string;
  uri: string;
  type: UserTypeEnum;
  uploaded_audition_track: boolean;
  signed_artist_tc: boolean;
  approved_artist: boolean;
  kyc: boolean;
  address?: string;
  color?: string;
  siwe_address?: string;
  email?: string;
  password?: string;
}

export interface IProfile {
  id?: string;
  name_first?: string;
  name_last?: string;
  created: string;
  image_profile?: IFile;
  image_profile_id?: string | null;
  image_cover?: IFile;
  image_cover_id?: string | null;
  image_background?: IFile;
  image_background_id?: string | null;
  biography?: string | null;
  country?: string | null;
  facebook?: ISocialInfo;
  twitter?: ISocialInfo;
  instagram?: ISocialInfo;
  tiktok?: ISocialInfo;
  linktree?: ISocialInfo;
  soundcloud?: ISocialInfo;
  spotify?: ISocialInfo;
  theme?: ICustomTheme | null;
  views?: number;
}

export interface IUserProfile extends IUser {
  profile: IProfile;
}

export interface IUserProfilePartial extends Partial<IUser> {
  profile: Partial<IProfile>;
}

export interface ILoggedUser extends IUserProfile {
  active?: boolean;
  email?: string;
  verified?: boolean;
}

export interface INft {
  id: string;
  metadata: {
    id: string;
    [key: string]: any;
  };
  music: IMusic;
  music_id: string;
  listing: IListing;
  listing_id: string;
  user: IUserProfile;
  user_id: string;
}

export interface IMusic {
  id: string;
  title: string;
  genres: string[];
  languages: string[];
  countries: string[];
  description: string;
  cover: IFile;
  cover_id: string;
  poster?: IFile;
  poster_id?: string;
  master: IFile;
  master_id: string;
  master_length: number;
  full: IFile;
  full_id: string;
  full_length: number;
  vocal: IFile;
  vocal_id: string;
  vocal_length?: number;
  backing: IFile;
  backing_id: string;
  backing_length?: number;
  video_id?: string;
  pdf: IFile;
  pdf_id: string;
  artist: IUserProfile;
  artist_id: string;
  created: string;
  type: MusicEnum;
  hidden: boolean;
}

export interface IFile {
  id: string;
  name: string;
  mime: MimeEnum;
  signed: string;
  url: string;
  key: string;
  user_id: string;
}

export interface IExtendedFile {
  file: File;
  meta: {
    width?: number;
    height?: number;
    duration?: number;
  };
}

export interface IListing {
  id: string;
  featured: boolean;
  status: ListingStatusEnum;
  ending: string;
  duration: number;
  amount: string;
  royalty: number;
  type: BidTypeEnum;
  views: number;
  with_rights: boolean;

  artist: IUserProfile;
  artist_id: string;

  seller?: IUserProfile;
  seller_id: string;

  buyer?: IUserProfile;
  buyer_id: string;

  music: IMusic;
  music_id: string;

  seller_address: string;
  buyer_address?: string;
  created: string;

  deployment: IDeployment;
  asset: IAsset;
}
export interface IBid {
  created: string;
  amount: string;
  user: IUserProfile;
  id: string;
  listing_id: string;
  listing: IListing;
}

export interface IBadge {
  user: IUserProfile;
  title: string;
  redirectUrl: string;
}

export type IconKeys = keyof typeof icons;

export interface IStyleableProps {
  style?: any;
  className?: string;
}
export interface IUserFollow {
  id: string;
  followee_id: string;
  follower_id: string;
  follower: IUserProfile;
  followee: IUserProfile;
}
export interface ITab {
  id: number;
  title: ReactNode;
  content: ReactNode;
}

export interface ISelectOption<ValueType = any> {
  label: string;
  value: ValueType;
}

export type MarketCategoryFilterType = "all" | "drops" | "hot";

export type MarketSortByType =
  | "highestPrice"
  | "lowestPrice"
  | "endingSoon"
  | "oldest";

export interface IMarketFilters {
  category?: MarketCategoryFilterType;
  sortBy?: MarketSortByType;
  lang?: string;
  genre?: string;
  sale?: string;
}

export interface IRemixPackage {
  id: string;
  image: any;
  title: string;
  description: string;
}

export interface IRemixStep {
  id: string;
  icon: IconKeys;
  title: string;
  description: string;
}

export interface IDistinctParams {
  table: string;
  attributes: string[];
  where?: any;
}

export interface IStake {
  id: string;
  userId: string;
  transactions: string[];
  currency: "ETH";
  total: string;
  withdrawn: string;
  spent: string;
  locked: string;
  available: string;
  withdrawal?: string;
}
export interface IWallet {
  address: string;
}

export interface ISocialIcon {
  name: IconKeys;
  url: string;
}

export enum TransactionStatusEnum {
  pending = "pending",
  complete = "complete",
  error = "error",
  outbid = "outbid",
}

export enum TransactionActionEnum {
  deposit = "deposit",
  withdraw = "withdraw",
  buy = "buy",
  sell = "sell",
  bid = "bid",
}

export interface ITransactionEvent {
  timestamp: Date;
  amount: string;
  status: TransactionStatusEnum;
  action: TransactionActionEnum;
  hash: string;
  listingId?: string;
  blockNumber?: number;
  contractAddress: string;
  chain?: IChain;
  senderAddress: string;
  tokenAddress: string;
}

export interface ITypographySize {
  xxl: string;
  xl: string;
  lg: string;
  md: string;
  sm: string;
  xs: string;
}

export interface IMusicListing extends IMusic {
  listing?: IListing;
}
export interface IRelatedSectionItem {
  key: string;
  image?: IFile;
  text: string;
  subtext: string;
  redirectUrl: string;
}

export interface IHomeData {
  hots: IListing[];
  drops: IListing[];
  featured: IListing[];
}

export interface IApiPage<ItemType> {
  count: number;
  items: ItemType[];
  pageCount?: number;
}

export interface ICoingeckoPrices {
  [coingeckoId: string]: {
    usd: number;
  };
}

export interface IVideoSource {
  source: string;
  type: string;
}

export interface IShareLinkMetadata {
  previewTitle?: string;
  previewDescription?: string;
  previewTwitterImage?: string;
  previewOgImage?: string;
  twitterCardType?: TwitterCardType;
}

export interface ISiweMessageParams {
  domain: string;
  address: string;
  chainId: string;
  uri: string;
  version: string;
  issuedAt: string;
  nonce: string;
}

export interface ISiweMessage extends ISiweMessageParams {
  signature: string;
}
export interface IWebsocketData<PayloadType = any> {
  type: WebsocketMessageType;
  payload: PayloadType;
}

export interface IChain {
  id: number;
  name: string;
  public_rpc_url: string;
  block_explorer_url: string;
}

export interface IDeployment {
  id: string;
  chain: IChain;
  staking_address: string;
  nft_address: string;
  instant_withdraw_address: string;
  instant_withdraw_abi: string;
  lazy_auction_completer_address: string;
  lazy_auction_completer_abi: string;
  name: string;
  staking_abi: string;
  min_listing_price: string;
  version: number;
}

export interface IAsset {
  id: string;
  address: string;
  chain: IChain;
  decimals: string;
  name: string;
  symbol: string;
  logoURI: string;
  coingecko_id: string;
}
export interface IPlaylistConfig {
  playlist: IMusic[];
  trackId: string;
}

export interface ICustomTheme {
  [CustomThemeColor.background]: string;
  [CustomThemeColor.font]: string;
  [CustomThemeColor.action]: string;
}
