import Modal, { IModalProps } from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import Typography from "../Typography/Typography";
import { useWeb3Query } from "../../../queries/web3.query";
import { IChain } from "../../commonTypes";
import { getChainNames } from "../../../helpers/networkHelper";

interface IProps extends IModalProps {
  isOpen: boolean;
  onClose: () => void;
  targetChain?: IChain;
}

const WrongNetworkModal = ({ isOpen, onClose, targetChain }: IProps) => {
  const { t } = useTranslation();
  const { data: deployments } = useWeb3Query();

  const networkNames = targetChain
    ? targetChain.name
    : getChainNames(deployments).join(", ");

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t("wrong-network")}>
      <Typography align="center" size="lg">
        {t("wrong-network-instruction", {
          network: networkNames,
        })}
      </Typography>
    </Modal>
  );
};

export default WrongNetworkModal;
