import { IApiResponse, IAsset, IDeployment } from "../common/commonTypes";
import { fetchWrapperProtected } from "../helpers/fetchWrapperProtected";
import { BigNumber } from "@ethersproject/bignumber/lib/bignumber.js";
import { Contract } from "@ethersproject/contracts/lib/index.js";
import { getEnv } from "../helpers/env";

const NEXT_PUBLIC_API_URL = getEnv("NEXT_PUBLIC_API_URL");

export const stakingService = {
  withdraw: async (
    token: IAsset,
    deployment: IDeployment,
    amount: string,
    wallet: string,
    signature: string,
    deadline: number,
    nonce: string
  ) => {
    const response: IApiResponse = await fetchWrapperProtected.post(
      `${NEXT_PUBLIC_API_URL}/withdrawal`,
      {
        address: wallet,
        amount,
        request_signature: signature,
        asset_id: token.id,
        deployment_id: deployment.id,
        deadline,
        nonce,
      }
    );
    return response;
  },

  get: async (
    stakingContract: Contract,
    ethAddress: string
  ): Promise<BigNumber> => stakingContract.stakes(ethAddress),
};
