import React from "react";
import styled, { useTheme } from "styled-components";

const Root = styled.div`
  width: 100%;
`;

const ProgressInput = styled.input<{
  hideThumb: boolean;
}>`
  height: 8px;
  -webkit-appearance: none;
  width: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  transition: background 0.2s ease;
  cursor: pointer;

  ${(props) =>
    props.hideThumb &&
    `
  &::-webkit-slider-thumb {
    display: none;
  }
  &::-moz-range-thumb {
    display: none;
  }
  &::slider-thumb {
    display: none;
  }
  `}
`;

interface ProgressBarProps {
  progress: number;
  duration: number;
  onScrub?: (value: any) => void;
  onScrubEnd?: () => void;
  step?: number;
}

const ProgressBar = ({
  progress,
  duration,
  onScrub,
  onScrubEnd,
  step = 0.1,
}: ProgressBarProps) => {
  const theme = useTheme();
  const currentPercentage = duration ? `${(progress / duration) * 100}%` : "0%";

  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, ${theme.accent.normal}),
    color-stop(${currentPercentage}, ${theme.lightBorderColor})
  `;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onScrub && onScrub(e.target.value);
  };

  const handlePointerMove = (e: React.PointerEvent<HTMLInputElement>) => {
    e.stopPropagation();
  };

  return (
    <Root>
      <ProgressInput
        type="range"
        value={progress}
        step={step}
        min="0"
        max={duration ? duration : `${duration}`}
        className="progress"
        onChange={handleChange}
        onMouseUp={onScrubEnd}
        onKeyUp={onScrubEnd}
        style={{ background: trackStyling }}
        hideThumb={!onScrub}
        onPointerMove={handlePointerMove}
      />
    </Root>
  );
};

export default ProgressBar;
