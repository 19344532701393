import styled, { css } from "styled-components";
import {
  getBaseTypography,
  getBoldTypography,
  getRegularTypography,
} from "./helpers";

export const getParagraph12BoldTypography = css`
  ${getBaseTypography};
  ${getBoldTypography};
  font-size: ${(props) => props.theme.paragraph.sm};
  line-height: 16px;
`;

export const getParagraph12RegularTypography = css`
  ${getBaseTypography};
  ${getRegularTypography};
  font-size: ${(props) => props.theme.paragraph.sm};
  line-height: 16px;
`;

export const Paragraph12Regular = styled.p`
  ${getParagraph12RegularTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph12Bold = styled.p`
  ${getParagraph12BoldTypography};
  color: ${(props) => props.theme.primaryColor};
`;

export const Paragraph12RegularSecondary = styled.p`
  ${getParagraph12RegularTypography};
  color: ${(props) => props.theme.primaryColor};
  opacity: 0.7;
`;
