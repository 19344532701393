import React, { ForwardedRef, SVGProps } from "react";
import { useTheme } from "styled-components";
import icons from "./index";
import { IconKeys } from "../../commonTypes";

interface IIconProps {
  width?: string;
  height?: string;
  size?: string;
  color?: string;
  name?: IconKeys;
  className?: string;
}

const defaultSize = "24px";

const Icon = React.forwardRef(
  (
    {
      width,
      height,
      size,
      color,
      name,
      ...props
    }: IIconProps & SVGProps<SVGSVGElement>,
    ref: ForwardedRef<SVGSVGElement>
  ) => {
    const theme = useTheme();
    const IconComponent = name && icons[name];

    if (!IconComponent || typeof IconComponent === "string") {
      return null;
    }

    return (
      <IconComponent
        width={width || size || defaultSize}
        height={height || size || defaultSize}
        color={color || theme.primaryColor}
        ref={ref}
        {...props}
      />
    );
  }
);

Icon.displayName = "Icon";

export default Icon;
